<template>
    <svg
        width="98"
        height="133"
        viewBox="0 0 98 133"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_333_3937)">
            <path
                d="M66.6706 131.525L1.50298 93.902C1.1576 93.7037 0.870659 93.4178 0.671117 93.0731C0.471576 92.7284 0.366501 92.3372 0.366501 91.939C0.366501 91.5407 0.471576 91.1495 0.671117 90.8048C0.870659 90.4601 1.1576 90.1742 1.50298 89.9759L24.0353 76.9706C25.0675 76.374 26.2386 76.0598 27.4308 76.0598C28.623 76.0598 29.7941 76.374 30.8263 76.9706L96.0001 114.594C96.3439 114.794 96.6293 115.08 96.8276 115.424C97.026 115.769 97.1304 116.159 97.1304 116.557C97.1304 116.955 97.026 117.345 96.8276 117.69C96.6293 118.034 96.3439 118.321 96.0001 118.52L73.4678 131.525C72.4345 132.122 71.2623 132.436 70.0692 132.436C68.8761 132.436 67.7039 132.122 66.6706 131.525V131.525Z"
                fill="#EBEBF2" />
            <path
                d="M24.1703 2.06733V62.45C24.1703 63.1461 24.3534 63.8299 24.7012 64.4329C25.049 65.0359 25.5492 65.5368 26.1517 65.8854L94.5768 105.386C94.8344 105.536 95.127 105.616 95.4251 105.616C95.7232 105.617 96.0162 105.539 96.2745 105.39C96.5329 105.242 96.7473 105.027 96.8962 104.769C97.0452 104.511 97.1233 104.218 97.1227 103.92V43.5555C97.1233 42.8593 96.9404 42.1752 96.5926 41.5721C96.2447 40.969 95.7442 40.4682 95.1412 40.1201L26.7161 0.613437C26.4595 0.463825 26.168 0.384367 25.8709 0.383043C25.5739 0.381719 25.2817 0.458574 25.0237 0.605892C24.7658 0.753209 24.5511 0.965804 24.4013 1.22233C24.2515 1.47885 24.1718 1.77027 24.1703 2.06733V2.06733Z"
                fill="#D9DAE0"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M46.1382 67.5479L38.6294 63.2168V30.7956L46.1382 35.1327V67.5479Z"
                fill="white"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M56.2418 73.388L48.7392 69.0508V32.7034L56.2418 37.0406V73.388Z"
                fill="white"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M66.3516 79.222L58.849 74.8848V32.9488L66.3516 37.286V79.222Z"
                fill="white"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M76.4615 85.0559L68.9527 80.7249V45.7517L76.4615 50.0888V85.0559Z"
                fill="white"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M86.5712 90.8899L79.0625 86.5589V71.2838L86.5712 75.621V90.8899Z"
                fill="white"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M89.6876 92.6935L35.5132 61.4133"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M0.368119 15.8149V76.1915C0.367529 76.8877 0.550361 77.5718 0.898212 78.1749C1.24606 78.778 1.74665 79.2788 2.34959 79.6269L70.7747 119.134C71.033 119.282 71.3258 119.36 71.6237 119.359C71.9216 119.359 72.2141 119.28 72.472 119.131C72.7299 118.982 72.944 118.768 73.0929 118.51C73.2419 118.252 73.3204 117.959 73.3206 117.661V57.2786C73.3205 56.5825 73.1374 55.8987 72.7897 55.2957C72.4419 54.6927 71.9416 54.1918 71.3391 53.8432L2.91397 14.3426C2.65598 14.1922 2.36281 14.1128 2.06419 14.1122C1.76556 14.1117 1.47211 14.1902 1.21361 14.3397C0.955097 14.4892 0.740727 14.7044 0.59224 14.9635C0.443753 15.2226 0.366431 15.5163 0.368119 15.8149V15.8149Z"
                fill="#D9DAE0"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M12.2692 38.9914C20.7104 43.8623 25.4954 63.3948 33.3108 67.9098L44.6107 74.437C52.2053 78.8171 53.8126 72.4801 61.4133 76.8908V94.7179L12.2692 66.3271V38.9914Z"
                fill="#ABC74A"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-miterlimit="10" />
            <path
                d="M38.4025 58.1681C24.4709 50.1256 24.4709 67.9466 12.2692 60.9102V66.3271L61.4072 94.6934V83.3199C49.1565 76.2405 49.7822 64.7382 38.4025 58.1681Z"
                fill="#2F99A3"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-miterlimit="10" />
            <path
                d="M12.2753 33.961V68.9834"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-miterlimit="10" />
            <path
                d="M61.4133 62.3273V97.3497"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-miterlimit="10" />
            <path
                d="M28.6546 43.4205V78.4367"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-miterlimit="10" />
            <path
                d="M45.034 52.8739V87.8963"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-miterlimit="10" />
            <path
                d="M13.4961 75.4185L11.0423 73.9953C10.6032 73.7322 10.2318 73.3699 9.95805 72.9373C9.68425 72.5048 9.51564 72.0141 9.46572 71.5047V71.2286C9.46572 70.3575 10.1712 70.0569 11.0423 70.5599L13.4961 71.977C13.936 72.2407 14.3079 72.604 14.5817 73.0377C14.8555 73.4714 15.0237 73.9633 15.0727 74.4738V74.7499C15.085 75.621 14.3795 75.9216 13.4961 75.4185Z"
                fill="#2F99A3"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-miterlimit="10" />
            <path
                d="M29.8815 84.8719L27.4277 83.4548C26.9893 83.1932 26.618 82.833 26.3433 82.4028C26.0685 81.9726 25.8979 81.4842 25.845 80.9764V80.7004C25.845 79.8293 26.5566 79.5287 27.4277 80.0317L29.8815 81.4488C30.3219 81.7125 30.6945 82.0757 30.9693 82.5093C31.2442 82.9429 31.4137 83.4347 31.4643 83.9456V84.2216C31.4643 85.0743 30.7588 85.3749 29.8815 84.8719Z"
                fill="#ABC74A"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-miterlimit="10" />
            <path
                d="M46.2609 94.3253L43.8071 92.9082C43.3662 92.6466 42.9931 92.2847 42.7181 91.852C42.4432 91.4193 42.274 90.9279 42.2244 90.4176V90.1415C42.2244 89.2643 42.9298 88.9698 43.8071 89.4729L46.2609 90.8899C46.702 91.152 47.0755 91.5138 47.3514 91.9464C47.6274 92.3789 47.798 92.8701 47.8498 93.3806V93.6566C47.8498 94.5339 47.132 94.8283 46.2609 94.3253Z"
                fill="#2F99A3"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-miterlimit="10" />
            <path
                d="M62.6402 103.785L60.1864 102.368C59.7475 102.103 59.3764 101.739 59.1027 101.306C58.829 100.872 58.6602 100.381 58.6098 99.871V99.5949C58.6098 98.7238 59.3153 98.4232 60.1864 98.9262L62.6402 100.343C63.0801 100.607 63.452 100.97 63.7258 101.404C63.9997 101.838 64.1678 102.33 64.2168 102.84V103.116C64.2168 103.987 63.5114 104.288 62.6402 103.785Z"
                fill="#ABC74A"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-miterlimit="10" />
        </g>
        <defs>
            <clipPath id="clip0_333_3937">
                <rect
                    width="97.4908"
                    height="132.439"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
