<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 24 18">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.82302 18.7026H3.31312C1.91267 18.7026 0.773438 17.7437 0.773438 16.5649V2.97069C0.773438 1.79191 1.91267 0.833008 3.31312 0.833008H19.5671C20.9675 0.833008 22.1068 1.79191 22.1068 2.97069V16.5649C22.1068 17.7437 20.9675 18.7026 19.5671 18.7026H14.0306H8.82302ZM3.10675 1.68815C2.26744 1.68815 1.58294 2.2643 1.58294 2.97076V16.565C1.58294 17.2714 2.26744 17.8476 3.10675 17.8476H9.24147H13.6073H19.7496C20.5889 17.8476 21.2735 17.2714 21.2735 16.565V2.97076C21.2735 2.2643 20.5889 1.68815 19.7496 1.68815H3.10675ZM7.77344 6.66634C7.77344 6.94253 7.54573 7.16634 7.47041 7.16634H4.74313C4.66781 7.16634 4.4401 6.94253 4.4401 6.66634C4.4401 6.39015 4.66781 6.16634 4.74313 6.16634H7.47041C7.54573 6.16634 7.77344 6.39015 7.77344 6.66634ZM18.0159 7.16634C18.2502 7.16634 18.4401 6.94253 18.4401 6.66634C18.4401 6.39015 18.2502 6.16634 18.0159 6.16634H9.53101C9.29667 6.16634 9.10677 6.39015 9.10677 6.66634C9.10677 6.94253 9.29667 7.16634 9.53101 7.16634H18.0159ZM7.77344 9.66634C7.77344 9.94253 7.54573 10.1663 7.47041 10.1663H4.74313C4.66781 10.1663 4.4401 9.94253 4.4401 9.66634C4.4401 9.39015 4.66781 9.16634 4.74313 9.16634H7.47041C7.54573 9.16634 7.77344 9.39015 7.77344 9.66634ZM18.0159 10.1663C18.2502 10.1663 18.4401 9.94253 18.4401 9.66634C18.4401 9.39015 18.2502 9.16634 18.0159 9.16634H9.53101C9.29667 9.16634 9.10677 9.39015 9.10677 9.66634C9.10677 9.94253 9.29667 10.1663 9.53101 10.1663H18.0159ZM7.77344 12.6663C7.77344 12.9425 7.54573 13.1663 7.47041 13.1663H4.74313C4.66781 13.1663 4.4401 12.9425 4.4401 12.6663C4.4401 12.3902 4.66781 12.1663 4.74313 12.1663H7.47041C7.54573 12.1663 7.77344 12.3902 7.77344 12.6663ZM18.0159 13.1663C18.2502 13.1663 18.4401 12.9425 18.4401 12.6663C18.4401 12.3902 18.2502 12.1663 18.0159 12.1663H9.53101C9.29667 12.1663 9.10677 12.3902 9.10677 12.6663C9.10677 12.9425 9.29667 13.1663 9.53101 13.1663H18.0159Z"
            fill="#4998c8"
            stroke="#4998c8"
            stroke-width="0.3" />
    </svg>
</template>

<script>
    export default {}
</script>
