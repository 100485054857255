<style lang="scss" scoped>
.std--dropdown.v-select {
    width: 240px !important;
    position: absolute;
    margin-top: -17px;

    .std--dropdown-list {
        top: 1px !important;
        position: relative;
    }
}

:deep(.v-text-field input::placeholder) {
    color: inherit !important;
}

:deep(.v-expansion-panel-header) {
    padding: 8px 16px;
    border: thin solid rgba(0, 0, 0, 0.12);
    min-height: 0;
}

:deep(.v-expansion-panel-content__wrap) {
    padding: 0 !important;
}

:deep(.empty-table .v-data-table__empty-wrapper) {
    display: none;
}

:deep(.v-data-table__wrapper){
    overflow-x: inherit;
    overflow-y: inherit;
}

</style>
<template>
    <div>
        <v-form
            ref="configForm"
            v-model="configFormValidity">
            <!-- Using v-if-else causing errors to load data-table row elements, hence used 'd-none' class approach -->
            <div :class="[{ 'd-none': !showExpansionPanels }]">
                <v-data-table
                    :headers="headers"
                    :loading="configsLoading"
                    class="data-table-full empty-table"
                    :disable-pagination="true"
                    :hide-default-footer="true" />
                <v-expansion-panels
                    v-model="panel"
                    multiple>
                    <v-expansion-panel
                        v-for="(item, i) in expansionPanels"
                        :key="i">
                        <v-expansion-panel-header
                            hide-actions
                            class="text-subtitle-2">
                            <template #default="{ open }">
                                <div>
                                    <v-icon>
                                        <template v-if="open">
                                            mdi-chevron-down
                                        </template>
                                        <template v-else>
                                            mdi-chevron-up
                                        </template>
                                    </v-icon>
                                    {{ item.title }}
                                </div>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-data-table
                                :items="item.data"
                                :loading="configsLoading"
                                :sort-by="defaultSort"
                                class="data-table-full"
                                :disable-pagination="true"
                                :hide-default-footer="true"
                                :no-data-text="noDataText">
                                <template #item="{ item: config, }">
                                    <tr
                                        :key="config.index"
                                        class="font-weight-medium">
                                        <td
                                            :style="{ maxWidth: headers[0].width, width: headers[0].width }">
                                            <config-name
                                                :name="config.name || config.key"
                                                :config-url="config.documentation_url" />
                                        </td>
                                        <td
                                            class="pa-0"
                                            :style="{ width: headers[1].width }">
                                            <restart-tooltip v-if="config.requires_restart" />
                                        </td>
                                        <td :style="{ width: headers[2].width }">
                                            <config-value
                                                :in-edit-mode="inEditMode"
                                                :config="config"
                                                @on-value-change="onValueChange" />
                                        </td>
                                        <td :style="{ width: headers[3].width }">
                                            {{ getKeywords(config) }}
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div :class="[{ 'd-none': showExpansionPanels }]">
                <v-data-table
                    :headers="headers"
                    :items="sortedConfigs()"
                    :loading="configsLoading"
                    :sort-by="defaultSort"
                    class="data-table-full"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    :no-data-text="noDataText">
                    <template #item="{ item: config, }">
                        <tr
                            :key="config.index"
                            class="font-weight-medium">
                            <td>
                                <config-name
                                    :name="config.name || config.key"
                                    :config-url="config.documentation_url" />
                            </td>
                            <td class="pa-0">
                                <restart-tooltip v-if="config.requires_restart" />
                            </td>
                            <td class="align-center">
                                <config-value
                                    :in-edit-mode="inEditMode"
                                    :config="config"
                                    @on-value-change="onValueChange" />
                            </td>
                            <td>
                                {{ getKeywords(config) }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-form>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import { isPrimaryReplica } from 'utils/service'
    import ConfigValue from 'components/configurationManager/ConfigValue.vue'
    import ConfigName from 'components/configurationManager/ConfigName.vue'
    import RestartTooltip from 'components/configurationManager/RestartTooltip.vue'
    export default {
        name: 'ConfigDataTable',
        components: {
            ConfigName,
            ConfigValue,
            RestartTooltip,
        },
        props: {
            configurations: {
                type: Array,
                required: true,
                default: () => [],
            },
            inEditMode: {
                type: Boolean,
                default: true,
            },
            topologyName: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                noDataText: '$vuetify.noDataText',
                defaultSort: ['tags'],
                panel: [0, 1],
                expansionPanels: [],
                configFormValidity: true,
            }
        },
        computed: {
            ...mapGetters(['configsLoading']),
            showExpansionPanels() {
                return isPrimaryReplica(this.topologyName) && !!this.configurations.length
            },
            headers() {
                return [
                    { text: this.$t('name'), value: 'name', width: '324px', sortable: !this.showExpansionPanels, },
                    { text: '', value: 'icon', width: '2px', sortable: false, },
                    { text: this.$t('value'), value: 'value', width: '260px', sortable: false, },
                    { text: this.$t('keywords'), value: 'tags', width: '300px', sortable: !this.showExpansionPanels, }
                ]
            },
        },
        watch: {
            configurations: {
                deep: true,
                handler() {
                    this.updateExpansions()
                },
            },
            configFormValidity(value) {
                this.$emit('config-form-validity', value)
            },
        },
        methods: {
            getKeywords(config) {
                return config.tags ? config.tags.join(', ') : ''
            },
            isRangeConfig(flags) {
                const { RANGE, } = this.$config.CONFIG_MANAGER_FLAGS
                return this.$help.hasFlags([RANGE], flags)
            },
            onValueChange(passedConfig, value) {
                let foundConfig = this.configurations.find(config => config.id === passedConfig.id)
                foundConfig['selected_values'] = this.$typy(value).isUndefined ? passedConfig['selected_values'] : [value]
                this.updateExpansions()
            },
            sortedConfigs() {
                let configs = this.$lodash.cloneDeep(this.configurations)
                configs.forEach(config => {
                    if (!config.name) config.name = config.key
                    // Handling sorting for `innodb_log_file_size` (DBAAS-11483)
                    if (config.name.includes('_size')) {
                        config.allowed_values.sort((value1, value2) => {
                            let index1, index2
                            this.$config.SIZE_UNITS.forEach((unit, index) => {
                                if (value1.includes(unit)) index1 = index
                                if (value2.includes(unit)) index2 = index
                            })
                            if (index1 < index2) return -1
                            else if (index1 > index2) return 1
                            else return Number(value1.slice(0, -2)) - Number(value2.slice(0, -2))
                        })
                    }
                    else config.allowed_values.sort((a, b) => a - b)

                    // Cases matching now in sheet for allowed_values and default_values
                    // so removed forcibly lowercasing them: DBAAS-14223

                    // Forcibly Add default_value to allowed_values' array if not present already (part of ticket DBAAS-11406)
                    config.default_value.forEach(value => {
                        let foundMatch = config.allowed_values.some(allowedValue => value === allowedValue)
                        if (!foundMatch) {
                            config.allowed_values.unshift(value)
                        }
                    })
                    // Add validation rules
                    if (this.isRangeConfig(config.flags)) {
                        const { minimum_value: min, maximum_value: max, default_value: defaultVal, } = config
                        config['rules'] = [
                            // Modifying rules in case where default value is undefined
                            value => (this.isOperatorGenerated(config) && !!value) || this.$typy(defaultVal[0]).isUndefined || !!value || this.$t('errors.required'),
                            value => (this.$typy(defaultVal[0]).isUndefined && !value) || (this.isOperatorGenerated(config) && !!value && value === this.$t('sizeDefault')) || ((Number(value) >= min && Number(value) <= max) || this.$t('errors.enterValueBetween', { min: min, max: max, }))
                        ]
                    }
                })
                return configs
            },
            updateExpansions() {
                if (this.showExpansionPanels) {
                    const { SERVER, MAXSCALE, } = this.$config.CONFIG_COMPONENT
                    let configs = this.sortedConfigs()
                    this.expansionPanels = [
                        { title: this.$t('configManager.serverVariables'), data: configs.filter(config => config.component === SERVER), },
                        { title: this.$t('configManager.maxscaleVariables'), data: configs.filter(config => config.component === MAXSCALE), }
                    ]
                }
            },
            isOperatorGenerated(config) {
                return config['default_value'] == this.$config.OPERATOR_GENERATED
            },
        },
        created() {
            this.updateExpansions()
            this.$emit('config-form-validity', this.configFormValidity)
        },
    }
</script>
