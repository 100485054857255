<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="doConfirm"
        :on-cancel="doCancel"
        :on-close="doCancel"
        :min-width="width"
        :max-width="width">
        <template #title>
            <div
                v-if="title && title.length"
                class="font-weight-light">
                {{ $t(title) }}
            </div>
        </template>
        <template #body>
            <v-col cols="12">
                <div v-if="message && message.length">
                    {{ $t(message) }}
                </div>
                <slot name="content"></slot>
            </v-col>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                depressed
                rounded
                outlined
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                depressed
                rounded
                @click="save">
                <span class="text-none px-4"> {{ $t(confirmLabel) }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>
<script>

    export default {
        name: 'ConfirmationModal',
        props: {
            title: {
                type: String,
                default: 'confirmation',
            },
            message: {
                type: String,
                default: () => '',
            },
            confirmLabel: {
                type: String,
                default: 'confirm',
            },
            onConfirm: {
                type: Function,
                required: true,
            },
            onCancel: {
                type: Function,
                default: () => {},
            },
            width: {
                type: Number,
                default: 600,
            },
        },
        data() {
            return {
                isDialogOpen: false,
            }
        },
        methods: {
            open() {
                this.isDialogOpen = true
            },
            doCancel() {
                this.onCancel()
            },
            doConfirm() {
                this.onConfirm()
            },
        },
    }
</script>
