<style>
    .vcron-select-container {
        margin-top: 1em !important;
        font-family: inherit !important;
    }
    .vcron-select-input {
        cursor: pointer;
    }
    .vcron-select-list {
        box-shadow: none !important;
        border: 1px solid #c5c0c0 !important;
        background: #ffff !important;
        min-width: 7em !important;
        width: 180px !important;
        overflow-y: scroll !important;
        height: 150px !important;
    }
    .vcron-select-list-item {
        color: #000 !important;
        font-family: inherit !important;
        cursor: pointer !important;
    }
    .vcron-select-list-item:hover {
        background-color: rgba(0, 0, 0, 0.05) !important;
        color: #000 !important;
    }
    .vcron-select-list-item-selected {
        color: rgb(99, 83, 239) !important;
        background-color: rgb(99, 83, 239, 0.16) !important;
    }
</style>
<style scoped>
 .control-label {
        font-size: 0.675rem;
    }
</style>

<template>
    <div class="pt-3">
        <div class="d-flex mb-3 justify-space-between">
            <div class="d-flex">
                <div>
                    <label class="d-block color text-small-text control-label">
                        {{ $tc('service') }}
                    </label>
                    <div>
                        <MultipleSelect
                            :items="serviceItems"
                            @toggleItem="toggleItem"
                            @toggleAll="(state) => toggleAllItems(serviceItems, state)" />
                    </div>
                </div>
                <div
                    class="ml-2"
                    style="width: 200px">
                    <label class="d-block color text-small-text control-label">
                        {{ $t('backups.type') }}
                    </label>
                    <div>
                        <MultipleSelect
                            :items="backupTypes"
                            @toggleItem="toggleItem"
                            @toggleAll="(state) => toggleAllItems(backupTypes, state)" />
                    </div>
                </div>
            </div>
            <div class="d-flex align-end">
                <v-btn
                    class="text-none"
                    color="primary"
                    rounded
                    depressed
                    @click="isCreateDialogOpen = true">
                    {{ $t('newSchedule') }}
                </v-btn>
            </div>
        </div>
        <v-data-table
            class="data-table-full logs-table"
            :headers="headers"
            :items="schedulesList"
            :options.sync="options"
            :server-items-length="schedulesCount"
            :loading="isFetchingSchedules"
            :footer-props="footerOptions"
            :no-data-text="$t('info.noBackups', ['schedules'])"
            fixed-header>
            <template #item="{ item: schedule }">
                <tr>
                    <td>{{ schedule.serviceName }}</td>
                    <td>{{ schedule.type }}</td>
                    <td>
                        <span
                            v-if="schedule.storage"
                            class="text-capitalize">
                            {{ schedule.storage }}
                            <span
                                v-if="schedule.storage === 'external' && schedule.provider"
                                class="text-uppercase">
                                ({{ schedule.provider }})
                            </span>
                        </span>
                        <span v-else>n/a</span>
                    </td>
                    <td>
                        <span
                            v-if="schedule.schedule === 'once'"
                            class="text-capitalize">
                            {{ schedule.schedule }}
                        </span>
                        <v-tooltip
                            v-else
                            :max-width="400"
                            open-delay="400"
                            top>
                            <template #activator="{ on }">
                                <span v-on="on">
                                    {{ getCronString(schedule.schedule) }}
                                </span>
                            </template>
                            <div class="text-center">
                                {{ schedule.schedule }}
                            </div>
                        </v-tooltip>
                    </td>
                    <td>{{ schedule.isSystem ? 'System' : 'User' }}</td>
                    <td
                        :class="{
                            'red--text': schedule.status === 'Failed',
                            'green--text': schedule.status === 'Succeeded'
                        }">
                        {{ schedule.status }}
                        <v-tooltip
                            v-if="schedule.status === 'Failed'"
                            open-delay="400"
                            max-width="280"
                            top>
                            <template #activator="{ on }">
                                <v-icon
                                    size="20"
                                    v-on="on">
                                    $vuetify.icons.questionCircle
                                </v-icon>
                            </template>
                            <span>{{ schedule.message }}</span>
                        </v-tooltip>
                    </td>
                    <td class="text-right">
                        <v-tooltip
                            v-if="!schedule.isSystem && schedule.schedule !== 'once'"
                            top>
                            <template #activator="{ on }">
                                <v-btn
                                    icon
                                    color="primary"
                                    class="mr-2"
                                    v-on="on"
                                    @click="editCronSchedule(schedule.id)">
                                    <v-icon>mdi-timer-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('backups.editCronSchedule') }}</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="!schedule.isSystem"
                            top>
                            <template #activator="{ on }">
                                <v-btn
                                    icon
                                    color="error"
                                    v-on="on"
                                    @click="confirmScheduleDelete(schedule.id)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('delete') }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <BackupScheduleModal
            :show-dialog="isCreateDialogOpen"
            :is-loading="isUpdating"
            @on-close="isCreateDialogOpen = false"
            @on-schedule-submit="createSchedule" />
        <EditScheduleModal
            :show-dialog="isEditDialogOpen"
            :schedule="selectedSchedule || {}"
            :is-loading="isUpdating"
            @cancel="isEditDialogOpen = false"
            @update="updateCronSchedule" />
        <v-row justify="center">
            <v-dialog
                v-model="isDeleteDialogOpen"
                persistent
                max-width="600px"
                scrollable>
                <v-card>
                    <v-card-title class="pt-9 pb-5 px-9">
                        <span class="text-h4 font-weight-medium">
                            {{ $t('confirmation') }}
                        </span>
                    </v-card-title>
                    <v-card-text class="pb-5 px-9">
                        <span class="text-body-1">
                            {{ $t('confirmations.deleteSchedule') }}
                        </span>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions class="py-4 px-9">
                        <v-spacer />
                        <v-btn
                            class="text-none"
                            color="primary"
                            rounded
                            outlined
                            :ripple="false"
                            @click="isDeleteDialogOpen = false">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-btn
                            class="text-none"
                            color="error"
                            style="min-width: 100px;"
                            rounded
                            depressed
                            :ripple="false"
                            :loading="isUpdating"
                            @click="deleteSchedule">
                            {{ $t('delete') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import to from 'await-to-js'
    import cronstrue from 'cronstrue'
    import { createNewSchedule, updateSchedule, deleteSchedule } from 'services/backups.js'
    import MultipleSelect from 'components/common/MultipleSelect.vue'
    import BackupScheduleModal from 'components/backups/BackupScheduleModal.vue'
    import EditScheduleModal from 'components/backups/EditScheduleModal.vue'

    export default {
        name: 'ServiceSchedules',
        components: {
            MultipleSelect,
            BackupScheduleModal,
            EditScheduleModal,
        },
        data() {
            return {
                pollingInterval: null,
                serviceItems: [],
                backupTypes: [],
                schedulesList: [],
                headers: [
                    { text: this.$tc('service'), value: 'serviceName', sortable: false, },
                    { text: this.$t('type'), value: 'type', sortable: false, },
                    { text: this.$t('storage'), value: 'storage', sortable: false, },
                    { text: this.$t('schedule'), value: 'schedule', sortable: false, },
                    { text: this.$t('backups.scheduleType'), value: 'ty', sortable: false, },
                    { text: this.$t('status'), value: 'status', sortable: false, },
                    { text: this.$t('actions'), value: 'actions', sortable: false, }
                ],
                footerOptions: {
                    'items-per-page-options': [ 10, 20, 50, 100],
                },
                options: {},
                isCreateDialogOpen: false,
                isEditDialogOpen: false,
                isDeleteDialogOpen: false,
                isUpdating: false,
                selectedScheduleId: null,
            }
        },
        computed: {
            ...mapGetters(['services', 'schedulesData', 'isFetchingSchedules']),
            selectedSchedule() {
                return this.schedulesList.find(schedule => schedule.id === this.selectedScheduleId)
            },
            schedulesCount() {
                return this.schedulesData?.schedulesCount ?? 0
            },
            selectedServices() {
                return this.serviceItems.filter(service => service.selected)
            },
            selectedBackupTypes() {
                return this.backupTypes.filter(service => service.selected)
            },
        },
        watch: {
            options: {
                handler () {
                    this.fetchSchedulesData()
                },
                deep: true,
            },
            serviceItems: {
                handler (newVal, oldVal) {
                    if (!oldVal.length) {
                        return
                    }
                    this.fetchSchedulesData()
                },
                deep: true,
            },
            backupTypes: {
                handler (newVal, oldVal) {
                    if (!oldVal.length) {
                        return
                    }
                    this.fetchSchedulesData()
                },
                deep: true,
            },
            selectedDatabaseFilter: {
                handler () {
                    this.fetchSchedulesData()
                },
            },
            selectedBackupTypeFilter: {
                handler () {
                    this.fetchSchedulesData()
                },
            },
            schedulesData() {
                this.schedulesList = this.schedulesData?.schedules?.map(obj => {
                    return {
                        serviceName: obj?.serviceName ?? '',
                        id: obj?.id ?? '',
                        type: obj?.type?.charAt(0)?.toUpperCase() +  obj?.type?.slice(1),
                        schedule: obj?.schedule,
                        provider: obj?.cloudProvider,
                        storage: this.$typy(obj, 'labels.skysqlExternalStorage').isTrue ? 'external' : 'managed',
                        isSystem: this.$typy(obj, 'labels.skysqlManagedBy').isTrue,
                        status: obj?.status ?? 'Unknown',
                        message: obj?.message ?? 'Unknown',
                    }
                }) ?? []
            },
        },
        methods : {
            ...mapActions(['fetchServices', 'fetchSchedules']),
            startPollingBackups (interval) {
                this.pollingInterval = setInterval(() => {
                    this.fetchSchedulesData()
                }, interval)
            },
            stopPollingBackups () {
                clearInterval(this.pollingInterval)
            },
            mapServices (services = []) {
                return services.map(service => {
                    return {
                        txt: service.name,
                        val: service.id,
                        selected: true,
                    }
                })
            },
            mapBackupTypes (types = []) {
                return types.map(type => {
                    return {
                        txt: this.$help.capitalize(type),
                        val: type,
                        selected: true,
                    }
                })
            },
            toggleItem (item) {
                item.selected = !item.selected
            },
            toggleAllItems (items, state) {
                items.forEach(type => (type.selected = state))
            },
            getCronString (cronValue) {
                return cronstrue.toString(cronValue, { verbose: true, })
            },
            async fetchSchedulesData() {
                const { sortDesc, page, itemsPerPage, } = this.options
                await this.fetchSchedules({
                    ...(this.selectedServices.length && { serviceId: this.selectedServices.map(type => type.val).join(','), }),
                    ...(this.selectedBackupTypes.length && { type: this.selectedBackupTypes.map(type => type.val).join(','), }),
                    pageSize: itemsPerPage,
                    page,
                    order: sortDesc[0] ? 'desc' : 'asc',
                })
            },
            async createSchedule(options) {
                try {
                    this.isUpdating = true
                    const [error, response] = await to(createNewSchedule(options))
                    if (error) {
                        this.$store.commit('showMessage', { text: this.$t('errors.createScheduleError'), type: this.$config.messageType.ERROR, })
                    } else {
                        this.$store.commit('showMessage', { text: response?.message ?? this.$t('success.createScheduleSuccess'), type: this.$config.messageType.SUCCESS, })
                        this.fetchSchedulesData()
                        this.isCreateDialogOpen = false
                    }
                } catch {
                    this.$store.commit('showMessage', { text: this.$t('errors.createScheduleError'), type: this.$config.messageType.ERROR, })
                } finally {
                    this.isUpdating = false
                }
            },
            async updateCronSchedule (options) {
                try {
                    this.isUpdating = true
                    const [error, response] = await to(updateSchedule(this.selectedScheduleId, options))
                    if (error) {
                        this.$store.commit('showMessage', { text: this.$t('errors.updateScheduleError'), type: this.$config.messageType.ERROR, })
                    } else {
                        this.$store.commit('showMessage', { text: response?.message ?? this.$t('success.updateScheduleSuccess'), type: this.$config.messageType.SUCCESS, })
                        this.fetchSchedulesData()
                        this.isEditDialogOpen = false
                    }
                } catch (err) {
                    this.$store.commit('showMessage', { text: this.$t('errors.updateScheduleError'), type: this.$config.messageType.ERROR, })
                } finally {
                    this.isUpdating = false
                }
            },
            confirmScheduleDelete(scheduleId) {
                this.selectedScheduleId = scheduleId
                this.isDeleteDialogOpen = true
            },
            async deleteSchedule() {
                try {
                    this.isUpdating = true
                    const [error, response] = await to(deleteSchedule(this.selectedScheduleId))
                    if (error) {
                        this.$store.commit('showMessage', { text: error?.response?.data?.errors[0]?.message ?? this.$t('errors.deleteScheduleError'), type: this.$config.messageType.ERROR, })
                    } else {
                        this.$store.commit('showMessage', { text: response?.message ?? this.$t('success.deleteScheduleSuccess'), type: this.$config.messageType.SUCCESS, })
                        this.fetchSchedulesData()
                        this.isDeleteDialogOpen = false
                    }
                } catch {
                    this.$store.commit('showMessage', { text: this.$t('errors.deleteScheduleError'), type: this.$config.messageType.ERROR, })
                } finally {
                    this.selectedScheduleId = null
                    this.isUpdating = false
                }
            },
            editCronSchedule(scheduleId) {
                this.selectedScheduleId = scheduleId
                this.isEditDialogOpen = true
            },
        },
        async created() {
            if (!this.services.length) {
                await this.fetchServices()
            }
            this.serviceItems = this.mapServices(this.services)
            this.backupTypes = this.mapBackupTypes(this.$config.BACKUP_TYPES)
            this.startPollingBackups(this.$config.backupsFetchInterval * 1000)
        },
        beforeDestroy () {
            this.stopPollingBackups()
        },
    }
</script>
