<style lang="scss" scoped>
div.month-sort ::v-deep.v-select__selection.v-select__selection--comma {
    font-size: 12px !important;
}

.deep-ocean-font {
    color: $deep-ocean;
}
</style>
<template>
    <div class="mt-6">
        <div v-if="!isFetchingUsage && !isFetchingInvoices">
            <template v-if="invoices?.length > 0">
                <v-row>
                    <v-col cols="3">
                        <span class="font-weight-bold deep-ocean-font">{{ $t('billings.invoiceMonth') }}</span>
                        <div class="month-sort">
                            <v-select
                                v-model="selectedMonth"
                                :items="sortedMonths"
                                item-text="text"
                                item-val="date"
                                height="40"
                                :dense="true"
                                class="mt-0 select-usage"
                                return-object
                                outlined />
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <BillingSortToggle
                            class="font-weight-bold deep-ocean-font"
                            :default-usage-sort="currentUsageSort"
                            @on-sort-toggle="updateUsageSort" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        class="text-h6 font-weight-medium"
                        cols="3">
                        {{ selectedMonth }} {{ $t('billings.usage').toLowerCase() }}
                    </v-col>
                    <v-col v-if="!isFetchingInvoices">
                        <a
                            rel="noopener noreferrer"
                            class="text-capitalize"
                            target="_blank"
                            :href="invoiceUrl">{{
                                $t('billings.invoice') }}</a>
                    </v-col>
                </v-row>
                <div class="mt-4">
                    <BillingUsageTable
                        :current-usage-sort="currentUsageSort"
                        :usage-data="usageData" />
                    <v-row>
                        <v-col cols="8" />
                        <BillingUsageTotal :total="total" />
                        <v-col cols="2" />
                    </v-row>
                </div>
            </template>
            <template v-else>
                <v-row
                    justify="center"
                    data-auto="billing-summary"
                    class="font-weight-bold text-subtitle-1 billing-summary pa-2">
                    {{ $t('errors.missingUsageData') }}
                </v-row>
            </template>
        </div>
        <v-container
            v-else
            class="fill-height mt-8"
            fluid>
            <v-row
                align="center"
                justify="center">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </v-row>
        </v-container>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import BillingSortToggle from 'components/billing/BillingSortToggle.vue'
    import BillingUsageTable from 'components/billing/BillingUsageTable.vue'
    import BillingUsageTotal from 'components/billing/BillingUsageTotal.vue'
    import { prepareUsageByEntities, getTopologyTitle } from 'utils/billing'
    import to from 'await-to-js'
    import { getInvoiceById } from 'services/billings'
    import i18n from 'plugins/i18n'

    export default {
        name: 'HistoricalUsage',
        components: { BillingSortToggle,BillingUsageTable, BillingUsageTotal, },
        data() {
            return {
                currentUsageSort: 'service',
                selectedMonth: '',
                usageDataByMonth: {},
                isFetchingUsage: false,
                currentInvoiceId: '',
            }
        },
        computed: {
            ...mapGetters([
                'isFetchingInvoices',
                'billingUsageDetails',
                'historicalUsage',
                'invoices'
            ]),
            sortedMonths() {
                if (!this.invoices?.length) {
                    return []
                }
                let monthsRange = []
                this.invoices.forEach(invoice => monthsRange.push(invoice?.period))
                return monthsRange
            },
            usageData() {
                if (this.$lodash.isEmpty(this.usageDataByMonth?.services)) return []
                return this.currentUsageSort === 'service' ? this.transformUsageByServices(this.usageDataByMonth?.services) : prepareUsageByEntities(this.historicalUsage)
            },
            total() {
                return this.usageDataByMonth?.invoiceAmount ?? 0
            },
            invoiceUrl() {
                const pdfUrl = this.invoices?.find(invoice => invoice?.invoiceId === this.currentInvoiceId)?.invoicePdfUrl ?? ''
                return `${process.env.VUE_APP_API_GATEWAY_BASE_URL}/${pdfUrl}&as=attachment`
            },
        },
        watch: {
            sortedMonths(values, prevValues) {
                if (prevValues.length !== values.length) {
                    this.selectedMonth = values.at(0)
                }
            },
            selectedMonth(value) {
                this.currentInvoiceId = this.invoices?.find(invoice => invoice?.period === value)?.invoiceId ?? ''
                this.getBillingUsage()
            },
        },
        methods: {
            ...mapActions(['fetchBillingUsage', 'fetchBillingRecord', 'fetchInvoices']),
            updateUsageSort(sortType) {
                this.currentUsageSort = sortType
            },
            async getBillingUsage() {
                this.isFetchingUsage = true
                const [error, response] = await to(getInvoiceById(this.currentInvoiceId))
                if (!error) {
                    this.usageDataByMonth = response
                } else {
                    this.usageDataByMonth = {}
                }
                this.isFetchingUsage = false
            },
            transformUsageByServices(usageData) {
                let usage = []
                usageData?.forEach(o => {
                    let obj = {}
                    obj.title = o?.service?.name ?? '',
                    obj.subTitle = i18n.t('billings.topologyOnProvider', { topology: getTopologyTitle(o?.service?.topology), provider: o?.service?.provider.toUpperCase(), }),
                    obj.cost = o?.cost
                    obj.children = [
                        {
                            title: 'Nodes',
                            cost: o?.nodeCost,
                            children: o?.nodes.map(node => ({
                                title: node?.size.charAt(0).toUpperCase() + node?.size.slice(1),
                                ...this.getAttributes(node),
                            })),
                        },
                        {
                            title: 'Storage',
                            cost: o?.storage?.totalCost,
                            children: [
                                {
                                    title: o?.storage?.type.toUpperCase(),
                                    ...this.getAttributes( o?.storage),
                                },
                                ...o?.storage?.iops ? [{
                                    title: 'IOPS',
                                    ...this.getAttributes( o?.storage?.iops),
                                }] : [],
                                ...o?.storage?.throughput ? [{
                                    title: 'Throughput',
                                    ...this.getAttributes( o?.storage?.throughput),
                                }] : []
                            ],
                        }
                    ]
                    usage.push(obj)
                })
                return usage
            },
            getAttributes(object) {
                return {
                    runtime: object?.quantity,
                    unit: object?.unit,
                    perMinCost: object?.rate,
                    cost: object?.cost,
                    rateUnit: object?.rateUnit,
                }
            },
        },
        created() {
            if (this.$lodash.isEmpty(this.invoices)) this.fetchInvoices()
        },
        mounted() {
            if (this.sortedMonths.length > 0) {
                this.selectedMonth = this.sortedMonths.at(0)
            }
        },
    }
</script>
