<style lang="scss" scoped>
.service-card {
    border: 1px solid $service-border;
    border-radius: 10px;
    width: 100%;
}

.service-card-header {
    position: relative;
    background: $service-bg;
}
</style>

<template>
    <div class="d-flex">
        <v-card
            class="service-card"
            outlined>
            <v-row
                class="service-card-header px-6 py-4"
                no-gutters>
                <v-col cols="8">
                    <span
                        v-if="isServiceProvisioningPendingOrFailed"
                        class="font-weight-bold">
                        {{ service.name }}
                    </span>
                    <a
                        v-else
                        class="font-weight-bold text-decoration-underline"
                        @click="navigateServiceDetails">{{
                            service.name
                        }}</a>
                    <ServiceStatusStrip
                        :service-id="service.id"
                        :current-tier="currentTier"
                        :autonomous-policies="autonomousPolicies" />
                </v-col>
                <v-col cols="4">
                    <div
                        class="d-flex align-center justify-end"
                        style="height: 100%">
                        <v-tooltip
                            :disabled="canConnect"
                            open-delay="400"
                            max-width="260"
                            top>
                            <template #activator="{ on }">
                                <div v-on="on">
                                    <v-btn
                                        color="primary"
                                        :disabled="!canConnect"
                                        :loading="isFetchingServiceCredentials"
                                        text
                                        rounded
                                        @click.stop="openServiceCredentials(service.id)">
                                        <v-icon>mdi-web</v-icon>
                                        <span class="pl-2 text-subtitle-1">{{ $t('connect') }}</span>
                                    </v-btn>
                                </div>
                            </template>
                            <div class="text-center">
                                {{ isServiceConnectable ? $t('info.serviceCredentialsGeneration') : $t('info.connectDisabled') }}
                            </div>
                        </v-tooltip>

                        <whitelist-modal ref="whitelistModal" />
                        <manage-menu
                            :service-id="service.id"
                            :service-status="currentStatus"
                            :is-disabled="!canManage"
                            :autonomous-policies="autonomousPolicies"
                            @openSecurityAccess="openSecurityDialog"
                            @onStoppedServer="onStoppedServer" />
                    </div>
                </v-col>
                <status-bar
                    :service-status="currentStatus"
                    :progress="progress" />
            </v-row>

            <SecurityAccessBanner
                v-if="showSecurityBanner"
                :service="service"
                :service-status="currentStatus"
                :is-private-link-enabled="isPrivateLinkOptedOnLaunch"
                @openSecurityAccessDialog="openSecurityDialog"
                @on-open-private-connect="onOpenPrivateConnectModal" />

            <v-row
                v-if="showProvisionStatus"
                dense
                no-gutters>
                <v-col>
                    <ProvisionStatus
                        :show-progress="showProgress"
                        :service-status="currentStatus"
                        :current-step="currentStep"
                        :progress="progress" />
                </v-col>
            </v-row>
            <ServiceListingWidgets
                v-else-if="showWidgets"
                :service="service"
                :spending="spending"
                :widgets="widgets" />
        </v-card>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import ManageMenu from 'components/service/ManageMenu.vue'
    import StatusBar from 'components/service/StatusBar.vue'
    import ProvisionStatus from 'components/service/ProvisionStatus.vue'
    import WhitelistModal from 'components/service/WhitelistModal.vue'
    import SecurityAccessBanner from 'components/service/SecurityAccessBanner.vue'
    import ServiceStatusStrip from 'components/service/ServiceStatusStrip.vue'
    import ServiceListingWidgets from 'components/service/ServiceListingWidgets.vue'
    import monitoringWidgets from 'data/monitoring/widgets/index.js'
    import i18n from 'plugins/i18n'
    import { isPrivateConnectOpted, isServerlessTopology } from 'utils/service'

    export default {
        name: 'ServiceCard',
        components: {
            ManageMenu,
            StatusBar,
            ProvisionStatus,
            WhitelistModal,
            SecurityAccessBanner,
            ServiceStatusStrip,
            ServiceListingWidgets,
        },
        props: {
            serviceId: { type: String, default: '', },
            stopperServices: { type: Array, default: new Array(), },
            autonomousPolicies: { type: Array, default: () => [], },
            serviceStatus: { type: String, required: true, },
            spending: { type: [String, Number], required: true, },
        },
        data() {
            return {
                progress: 0,
                currentStep: i18n.t('serviceProgressSteps.starting'),
                stopInterval: null,
                showProgress: false,
            }
        },
        computed: {
            ...mapGetters(['getServiceById', 'isFetchingServiceCredentials', 'currentTier', 'getServiceProgressById', 'serviceProgressUpdateCounter']),
            showProvisionStatus() {
                const { FAILED, PENDING_CREATE, } = this.$config.serviceStatus
                return [FAILED, PENDING_CREATE].includes(this.serviceStatus)
            },
            showWidgets() {
                return !this.isServiceProvisioningPendingOrFailed
            },
            canConnect() {
                return this.$config.serviceStatus.READY === this.service.status
            },
            showSecurityBanner() {
                const { status, } = this.service
                if (isServerlessTopology(this.service.topology)) {
                    return status !== this.$config.serviceStatus.FAILED && this.$unleash.isFeatureEnabled('enable-serverless-allowlist')
                }
                return status !== this.$config.serviceStatus.FAILED
            },
            service() {
                return this.getServiceById(this.serviceId)
            },
            currentStatus() {
                return this.stopperServices.includes(this.serviceId) ? this.$config.serviceStatus.STOPPED : this.service.status
            },
            canManage() {
                // Need to revert, this was asked by QA as per
                // https://jira.mariadb.org/browse/DBAAS-10965
                return true
            // const { status, } = this.service
            // const { READY, RETIRED, FAILED, STOPPED, } = this.$config.serviceStatus
            // return [READY, RETIRED, FAILED, STOPPED].includes(status)
            },
            widgets() {
                const { topology, } = this.service
                return monitoringWidgets
                    .find(({ topologies, }) => topologies.indexOf(topology) !== -1)
            },
            isServiceProvisioningPendingOrFailed() {
                const { status, } = this.service
                const { FAILED, PENDING_CREATE, } = this.$config.serviceStatus

                // TODO: Refactor after topology status integration
                return [FAILED, PENDING_CREATE].includes(status)
            },
            isPrivateLinkOptedOnLaunch(){
                return isPrivateConnectOpted(this.service.endpoints)
            },
            isServiceConnectable(){
                const { status, } = this.service
                const { STOPPED, PENDING_STOP, PENDING_START,} = this.$config.serviceStatus

                return ![STOPPED, PENDING_STOP, PENDING_START].includes(status)
            },
        },
        watch: {
            async serviceStatus(newStatus) {
                if (newStatus === this.$config.serviceStatus.FAILED) {
                    const data = await this.fetchProgressMessage(this.serviceId)
                    this.computeCurrentProgress(data.steps)
                    this.progress = 100
                }

                if (newStatus === this.$config.serviceStatus.READY) {
                    this.stopPoll()
                    this.progress = 100
                }
            },
            serviceProgressUpdateCounter() {
                if (this.$unleash.isFeatureEnabled('enable-portal-sse-for-service-progress')) {
                    this.setupProgressStates()
                }
            },
        },
        methods: {
            ...mapActions(['openServiceCredentials', 'openPrivateConnectModal', 'fetchServiceProgress']),
            openSecurityDialog() {
                this.$refs.whitelistModal.open(this.serviceId)
            },
            onStoppedServer(serviceId) {
                this.$emit('updateStoppedServerList', serviceId)
            },
            navigateServiceDetails() {
                this.$router.push(`/service/${this.serviceId}/monitoring/overview`)
            },
            stopPoll() {
                clearInterval(this.stopInterval)
            },
            startProgressPolling() {
                this.stopInterval = setInterval(async () => {
                    this.setupProgressStates()
                }, 10 * 1000)
            },
            fetchProgressMessage() {
                return this.fetchServiceProgress(this.serviceId)
            },
            async setupProgressStates() {
                if(!this.$unleash.isFeatureEnabled('enable-portal-sse-for-service-progress')){
                    await this.fetchProgressMessage()
                }
                const steps = this.getServiceProgressById(this.serviceId)
                if (steps && steps.length){
                    this.computeCurrentProgress(steps)
                }
            },
            computeCurrentProgress(steps) {
                const completedSteps = steps.filter(step => step.state === 'completed')
                const inProgress = steps.find(step => step.state === 'in-progress')
                let stepName = inProgress ? inProgress.name : completedSteps.at(-1).name
                this.currentStep = i18n.t(`serviceProgressSteps.${this.$lodash.camelCase(stepName)}`)
                if (!inProgress) {
                    this.currentStep += `: ${i18n.t('serviceProgressSteps.complete')}`
                }

                this.progress = Math.round((completedSteps.length / steps.length) * 100, 2)
                if (this.progress === 100) {
                    this.currentStep = i18n.t('serviceProgressSteps.finalizingSetup')
                    this.showProgress = false
                }
            },
            async performLaunchProgress() {
                if (this.serviceStatus === this.$config.serviceStatus.PENDING_CREATE) {
                    this.showProgress = true
                    await this.fetchProgressMessage()
                    return
                }
                this.progress = 100
            },
            onOpenPrivateConnectModal() {
                this.openPrivateConnectModal({ popupState: true, serviceId: this.service.id, })
            },
        },
        async created() {
            this.performLaunchProgress()
        },
        destroyed() {
            this.stopPoll()
        },
    }
</script>
