<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <v-row>
                <div class="text-h4 d-flex align-baseline">
                    <template v-if="!inEditNameMode">
                        <span>{{ user.name }}</span>
                        <v-icon
                            class="ml-2 cursor-pointer color"
                            @click="editUser">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <div
                        v-else
                        class="d-flex gap-15">
                        <v-text-field
                            v-model="firstName"
                            :label="$t('UserProfile.firstName')"
                            outlined
                            hide-details
                            :disabled="usersLoading"
                            dense />
                        <v-text-field
                            v-model="lastName"
                            :label="$t('UserProfile.lastName')"
                            class="ml-4"
                            outlined
                            hide-details
                            :disabled="usersLoading"
                            dense />
                        <div>
                            <v-progress-circular
                                v-if="usersLoading"
                                :size="25"
                                indeterminate
                                color="primary" />
                            <template v-else>
                                <v-icon
                                    class="ml-2 cursor-pointer red--text"
                                    @click="inEditNameMode = false">
                                    mdi-close-circle-outline
                                </v-icon>
                                <v-icon
                                    :class="['ml-2', 'cursor-pointer', {'opacity-50percent' : firstName?.length < 1}, {'no-click': firstName?.length < 1}]"
                                    color="primary"
                                    @click="update">
                                    mdi-content-save
                                </v-icon>
                            </template>
                        </div>
                    </div>
                </div>
            </v-row>
            <v-row class="mb-8">
                {{ user.email }}
            </v-row>
            <v-row>
                <v-expansion-panels
                    v-model="selectedOption"
                    accordion>
                    <v-expansion-panel v-if="$unleash.isFeatureEnabled('enable-portal-user-mfa')">
                        <AccordianPanelHeader
                            :title="$t('UserProfile.mfa')">
                            <template #tooltip-content>
                                {{ $t('UserProfile.mfaDescription') }}
                            </template>
                        </AccordianPanelHeader>
                        <v-expansion-panel-content>
                            <MfaToggle />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <AccordianPanelHeader
                            :title="$t('UserProfile.notificationPreferences')"
                            :show-tool-tip="false" />
                        <v-expansion-panel-content>
                            <NotificationPreferences />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <AccordianPanelHeader
                            :title="$t('UserProfile.apiKeys')"
                            :show-tool-tip="false" />
                        <v-expansion-panel-content>
                            <ApiKeyManagement />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'
    import MfaToggle from 'components/userProfile/MfaToggle.vue'
    import ApiKeyManagement from 'components/userProfile/ApiKeyManagement.vue'
    import NotificationPreferences from 'components/userProfile/NotificationPreferences.vue'

    export default {
        name: 'UserProfile',
        components: {
            AccordianPanelHeader,
            MfaToggle,
            ApiKeyManagement,
            NotificationPreferences,
        },
        data () {
            return {
                options: ['mfa', 'notifications', 'api-keys'],
                selectedOption: null,
                inEditNameMode: false,
                firstName: '',
                lastName: '',
            }
        },
        computed: {
            ...mapGetters(['user', 'usersLoading', 'updateUserResponse']),
        },
        watch: {
            selectedOption: function(value) {
                const option = this.options[value]
                if (option) {
                    this.$router.push({ name: 'userProfile', params: { tab: option, },})
                }
            },
            user: {
                handler () {
                    this.inEditNameMode = false
                },
                deep: true,
            },
        },
        methods: {
            ...mapActions(['updateUser']),
            async update() {
                const userObj = {
                    userId: this.user.id,
                    userObject: {
                        'first_name': this.firstName,
                        'last_name': this.lastName,
                    },
                }
                await this.updateUser(userObj)
                if (this.updateUserResponse) {
                    this.$store.commit('showMessage', {
                        text: this.$t('success.updateUser'),
                        type: this.$config.messageType.SUCCESS,
                    })
                }
            },
            editUser() {
                this.firstName = this.user.firstName
                this.lastName = this.user.lastName
                this.inEditNameMode = true
            },
        },
        created() {
            if (!this.$unleash.isFeatureEnabled('enable-portal-user-mfa')) {
                this.options.shift()
            }
        },
        async mounted() {
            this.firstName = this.user.firstName
            this.lastName = this.user.lastName
            const activeTabIndex = this.options.findIndex(option => option === this.$route.params.tab)
            if (activeTabIndex !== -1) {
                this.selectedOption = activeTabIndex
            }
        },
    }
</script>
