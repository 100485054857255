<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <BackupNavigation />
            <router-view />
        </v-col>
    </v-container>
</template>

<script>
    import BackupNavigation from '../components/backups/BackupNavigation.vue'

    export default {
        name: 'Backups',
        components: { BackupNavigation, },
    }
</script>
