<template>
    <div>
        <base-dialog
            v-model="isModalOpen"
            :min-width="700"
            :max-width="700"
            :on-close="closeModal"
            :on-cancel="closeModal"
            :on-save="doSave">
            <template #title>
                <div class="text-h4">
                    {{ $t('impersonateUser') }}
                </div>
            </template>
            <template #body>
                <div class="text-center color text-deep-ocean">
                    <v-text-field
                        v-model="email"
                        class="pt-5"
                        outlined
                        dense
                        required
                        autofocus
                        :placeholder="$t('email')" />
                </div>
            </template>
            <template #actions="{ cancel, save }">
                <v-spacer />
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    outlined
                    @click="cancel">
                    <span class="text-none">{{ $t('cancel') }} </span>
                </v-btn>
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    :disabled="!email.length"
                    @click="save">
                    <span class="text-none"> {{ $t('confirm') }}</span>
                </v-btn>
            </template>
        </base-dialog>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'ImpersonateUserModal',
        props: {
            showDialog: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                email: '',
                isModalOpen: this.showDialog,
            }
        },
        watch: {
            showDialog(val) {
                this.isModalOpen = val
            },
        },
        methods: {
            ...mapActions(['impersonateUser']),
            closeModal() {
                this.$emit('close')
            },
            async doSave() {
                await this.impersonateUser(this.email)
                this.$emit('close')
            },
        },
    }
</script>
