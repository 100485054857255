var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-dialog',{attrs:{"on-save":_vm.action,"on-cancel":_vm.doCancel,"min-width":600,"max-width":600},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"font-weight-light text-h5 pb-6"},[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")])]},proxy:true},{key:"body",fn:function(){return [(_vm.dialogType === _vm.$config.DIALOG_TYPE.INVITE)?_c('div',[_c('div',{staticClass:"pb-5"},[_vm._v(" "+_vm._s(_vm.$t('userManagementTab.usersWillSee'))+" ")]),_vm._l((_vm.usersList),function(user,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('v-text-field',{staticClass:"config-input mw-50",attrs:{"label":!index ? _vm.$t('email') : '',"outlined":"","dense":"","required":"","hide-details":""},on:{"change":function($event){return _vm.addNewRow(user)}},model:{value:(user.email),callback:function ($$v) {_vm.$set(user, "email", $$v)},expression:"user.email"}}),_c('v-select',{staticClass:"pl-2",attrs:{"items":_vm.roles,"label":!index ? _vm.$t('role') : '',"item-text":"name","item-value":"id","dense":"","menu-props":{
                        bottom: true,
                        offsetY: true,
                        contentClass: 'std--dropdown-list max-height-330',
                    },"outlined":"","hide-details":"","required":""},on:{"change":function($event){return _vm.addNewRow(user)}},model:{value:(user.role),callback:function ($$v) {_vm.$set(user, "role", $$v)},expression:"user.role"}})],1)})],2):_vm._e(),(_vm.dialogType === _vm.$config.DIALOG_TYPE.EDIT)?_c('div',{staticClass:"d-flex pt-4"},[_c('v-text-field',{staticClass:"config-input mw-50",attrs:{"label":_vm.$t('email'),"disabled":"","outlined":"","dense":"","required":"","hide-details":""},model:{value:(_vm.currentUser.email),callback:function ($$v) {_vm.$set(_vm.currentUser, "email", $$v)},expression:"currentUser.email"}}),_c('v-select',{staticClass:"pl-2",attrs:{"items":_vm.roles,"label":_vm.$t('role'),"item-text":"name","item-value":"id","dense":"","menu-props":{
                    bottom: true,
                    offsetY: true,
                    contentClass: 'std--dropdown-list max-height-330',
                },"outlined":"","required":""},model:{value:(_vm.currentUser.role),callback:function ($$v) {_vm.$set(_vm.currentUser, "role", $$v)},expression:"currentUser.role"}})],1):_vm._e(),(_vm.dialogType === _vm.$config.DIALOG_TYPE.RESEND)?_c('div',{staticClass:"d-flex pt-4"},[_c('v-text-field',{staticClass:"config-input mw-50",attrs:{"label":_vm.$t('email'),"disabled":"","outlined":"","dense":"","required":"","hide-details":""},model:{value:(_vm.currentUser.email),callback:function ($$v) {_vm.$set(_vm.currentUser, "email", $$v)},expression:"currentUser.email"}}),_c('v-select',{staticClass:"pl-2",attrs:{"items":_vm.roles,"label":_vm.$t('role'),"item-text":"name","item-value":"id","dense":"","menu-props":{
                    bottom: true,
                    offsetY: true,
                    contentClass: 'std--dropdown-list max-height-330',
                },"outlined":"","required":""},model:{value:(_vm.currentUser.role),callback:function ($$v) {_vm.$set(_vm.currentUser, "role", $$v)},expression:"currentUser.role"}})],1):_vm._e(),(_vm.dialogType === _vm.$config.DIALOG_TYPE.DEACTIVATE)?_c('div',[_c('i18n',{attrs:{"path":"userManagementTab.aboutToDeactivate","tag":"p"}},[_c('strong',{attrs:{"place":"userEmail"}},[_vm._v(_vm._s(_vm.currentUser.email))])])],1):_vm._e(),(_vm.dialogType === _vm.$config.DIALOG_TYPE.ACTIVATE)?_c('div',[_c('i18n',{attrs:{"path":"userManagementTab.aboutToActivate","tag":"p"}},[_c('strong',{attrs:{"place":"userEmail"}},[_vm._v(_vm._s(_vm.currentUser.email))])])],1):_vm._e(),(_vm.dialogType === _vm.$config.DIALOG_TYPE.CANCEL)?_c('div',[_c('i18n',{attrs:{"path":"userManagementTab.linkWillBeDisabled","tag":"p"}},[_c('strong',{attrs:{"place":"userEmail"}},[_vm._v(_vm._s(_vm.currentUser.email))])])],1):_vm._e()]},proxy:true},{key:"actions",fn:function({ cancel, save }){return [_c('v-spacer'),(_vm.dialogType !== _vm.$config.DIALOG_TYPE.CANCEL)?_c('v-btn',{staticClass:"cancel",attrs:{"color":"primary","depressed":"","rounded":"","outlined":""},on:{"click":cancel}},[_c('span',{staticClass:"text-none text-body-1 px-3"},[_vm._v(_vm._s(_vm.$t('cancel'))+" ")])]):_vm._e(),_c('v-btn',{attrs:{"color":[_vm.$config.DIALOG_TYPE.DEACTIVATE, _vm.$config.DIALOG_TYPE.CANCEL].includes(_vm.dialogType) ? 'error' : 'primary',"depressed":"","rounded":"","disabled":_vm.disableAction},on:{"click":save}},[_c('span',{staticClass:"text-capitalize text-body-1 px-3"},[_vm._v(" "+_vm._s(_vm.actionText()))])])]}}]),model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }