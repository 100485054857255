<style lang="scss" scoped>
    .selected-replica-box {
        border-radius: 1.25rem;
        border: 1px solid;
        min-width: 6rem;
        padding: 7px;
        color: #0e6488;
        width: auto;
        height: 2.5rem;
        font-weight: 500;
    }
    .selection-title {
        color: $small-text;
    }
    .text-caption {
        white-space: nowrap;
    }
</style>

<template>
    <div
        class="selection-wrapper mb-6"
        :data-auto="dataAutoPrefix()">
        <v-card class="px-4 py-2 mb-4 selection-entity-box">
            <div>
                <div class="selection-title text-caption">
                    {{ $t('launchService.type') }}
                </div>
                <div class="text-body-2">
                    <v-icon
                        :size="24"
                        class="mr-1">
                        {{ topologyIcon }}
                    </v-icon>
                    {{ $t(`serverType.${topologyType}`) }}
                    <span class="text-caption">
                        ({{ $t(`topology.${topologyType}`) }})
                    </span>
                </div>
            </div>
            <div class="mt-1">
                <div class="selection-title text-caption">
                    {{ $t('launchService.cloudProvider') }}
                </div>
                <div class="text-body-2">
                    <v-icon
                        :size="24"
                        class="mr-1">
                        {{ `$vuetify.icons.${providerType}` }}
                    </v-icon>
                    {{ $t(`cloudProvider.${providerType}`) }}
                    <span
                        v-if="isProviderTechPreview"
                        class="text-caption">
                        ({{ $t('techPreview') }})
                    </span>
                </div>
            </div>
            <div class="mt-1">
                <div class="selection-title text-caption">
                    {{ $t('launchService.location') }}
                </div>
                <div class="text-body-2">
                    <v-icon
                        :size="24"
                        class="mr-1">
                        {{ currentLocationIcon }}
                    </v-icon>
                    {{ locationLabel }}
                    <span class="text-caption">
                        ({{ location }}{{ singleZone.length ? ` • ${$t('launchService.singleZone')}` : '' }})
                    </span>
                </div>
            </div>
            <div class="mt-1">
                <div class="selection-title text-caption">
                    {{ $t('launchService.instance-size') }}
                </div>
                <div class="text-body-2">
                    <div v-if="selectedInstance">
                        {{ selectedInstance.display_name }}
                        <span class="text-caption">
                            ({{ selectedInstance.cpu }} • {{ selectedInstance.ram }} {{ $t('launchService.memory') }})
                        </span>
                    </div>
                    <div v-else>
                        ...
                    </div>
                </div>
            </div>
            <div class="mt-1">
                <div class="selection-title text-caption">
                    {{ $t('launchService.storageSize') }}
                </div>
                <div class="text-body-2">
                    {{ selectedInstanceDisk }} {{ $t('launchService.gb') }}
                    <span
                        v-if="showIopsLabel"
                        class="text-caption">
                        ({{ $t('iops', { iops: iopsVolumeSize }) }})
                    </span>
                </div>
            </div>
            <div class="mt-1">
                <div class="selection-title text-caption">
                    {{ $tc('node', 2) }}
                </div>
                <div class="text-body-2">
                    {{ isNodes ? $help.getNodesTextForNonReplicatedTopology(replicas) : $help.getNodesTextForReplicatedTopology(replicas) }}
                </div>
            </div>
            <div class="mt-1">
                <div class="selection-title text-caption">
                    {{ $t('version') }}
                </div>
                <div class="text-body-2">
                    {{ selectedServer }}
                </div>
            </div>
            <div class="mt-1">
                <div class="selection-title text-caption">
                    {{ $t('launchService.serviceName') }}
                </div>
                <div class="text-body-2">
                    {{ serviceName }}
                </div>
            </div>
            <div class="mt-1">
                <div class="selection-title text-caption">
                    SSL/TLS
                </div>
                <div
                    class="text-body-2"
                    :class="{
                        'red--text': !isSSLEnabled,
                        'green--text': isSSLEnabled
                    }">
                    {{ isSSLEnabled ? $t('enabled') : $t('disabled') }}
                </div>
            </div>
        </v-card>

        <ServiceComputation
            v-if="isInstanceSelected"
            :billing-items="unitCosts"
            :total-estimate="serviceCostEstimate" />
    </div>
</template>

<script>
    import ServiceComputation from 'components/common/ServiceComputation.vue'

    export default {
        name: 'SelectedServiceComputation',
        components: {
            ServiceComputation,
        },
        props: {
            selectedServer: { type: String, default: '', },
            serviceName: { type: String, default: '', },
            selectedInstanceDisk: { type: Number, default: 0, },
            topologyType: { type: String, default: '', },
            topologyIcon: { type: String, default: '', },
            providerType: { type: String, default: '', },
            location: { type: String, default: '', },
            locationLabel: { type: String, default: '', },
            currentLocationIcon: { type: String, default: '', },
            singleZone: { type: String, default: '', },
            unitCosts: {
                type: Array,
                required: true,
            },
            parentDataAutoPrefix: {
                type: Function,
                default: (txt) => txt,
            },
            replicas: { type: Number, default: 0, },
            iopsVolumeSize: { type: Number, default: 0, },
            iopsVolumeType: { type: String, default: '', },
            serviceCostEstimate: {
                type: Object,
                required: true,
            },
            selectedInstance: {
                type: Object,
                default: ()=>{},
            },
            topology: {
                type: String,
                required: true,
            },
            isSSLEnabled: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            showIopsLabel() {
                const { cloudProvider: { AWS, }, iops: { io1, }, } = this.$config
                return this.providerType === AWS && this.iopsVolumeType === io1
            },
            isInstanceSelected(){
                return this.selectedInstance && !this.$typy(this.selectedInstance).isEmptyObject
            },
            isNodes(){
                return this.$help.hasNodesInTopology(this.topology)
            },
            isProviderTechPreview(){
                return false
            },
        },
        methods: {
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(
                    `selectedServiceOverview${string ? '-' + string : ''}`
                )
            },
        },
    }
</script>
