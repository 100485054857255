<style lang="scss" scoped>
    .footer__separator:after {
        content: '|';
        margin: 0 10px;
    }
    .footer-text {
        font-size: 0.625rem;
        a {
            text-decoration: none;
        }
    }
    footer {
        border-top: 1px solid #e8eef1 !important;
        box-shadow: none !important;
    }
</style>
<template>
    <v-footer
        class="pl-2 d-flex color bg-background justify-center"
        padless
        app
        height="40"
        inset>
        <span class="footer-text color text-navigation align-center text-truncate">
            © {{ copyrightYear }}, SkySQL Inc. {{ $t('allRightsReserved') }}
            <span class="footer__separator"></span>
            <a
                class="color text-links"
                target="_blank"
                rel="noopener"
                href="https://skysql.com/tos/">
                {{ $t('privacyPolicy') }}
            </a>
            <span class="footer__separator"></span>
            <a
                class="color text-links"
                target="_blank"
                rel="noopener"
                href="https://skysql.com/tos/">
                {{ $t('termsOfUse') }}
            </a>
        </span>
    </v-footer>
</template>

<script>
    export default {
        name: 'Footer',
        computed: {
            copyrightYear(){
                return this.$moment().format('YYYY')
            },
        },
    }
</script>
