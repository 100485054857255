export default Object.freeze({
    CORTEX_REGIONS: {
        'Americas': 'us-central1',
        'EMEA': 'europe-west1',
        'APAC': 'asia-southeast1',
    },
    DEFAULT_DATA_POINTS: 60,
    DASHBOARD_MIN_GRID_SIZE_Y: 24,
    DASHBOARD_TIME_RANGES: {
        minutes: [5, 15, 30],
        hours: [1, 3, 6, 12, 24],
        days: [2, 7, 30],
    },
    DASHBOARD_DEFAULT_TIME_RANGE: ['now-30 minutes', 'now'],
})
