import { render, staticRenderFns } from "./RulesTable.vue?vue&type=template&id=a972a762&scoped=true"
import script from "./RulesTable.vue?vue&type=script&lang=js"
export * from "./RulesTable.vue?vue&type=script&lang=js"
import style0 from "./RulesTable.vue?vue&type=style&index=0&id=a972a762&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a972a762",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a972a762')) {
      api.createRecord('a972a762', component.options)
    } else {
      api.reload('a972a762', component.options)
    }
    module.hot.accept("./RulesTable.vue?vue&type=template&id=a972a762&scoped=true", function () {
      api.rerender('a972a762', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/alerts/RulesTable.vue"
export default component.exports