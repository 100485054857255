<style lang="scss" scoped>
.v-divider {
    margin: 0 -48px;
    max-width: none;
}

.notification-card {
    margin: 0 -48px;
    min-width: 776px;

    .notification-header {
        min-width: 776px;
        position: fixed;
        background: inherit;
        z-index: 1;

        :deep(.notification-name input) {
            font-size: 1.875rem !important;
            color: $deep-ocean !important;
            font-weight: 350;
            min-height: 50px;
        }
    }
}

v-card {
    overflow: hidden;
}

.pt-25 {
    padding-top: 100px !important;
}
</style>
<template>
    <base-dialog
        id="base-dial"
        ref="notificationDialogRef"
        v-model="isDialogOpen"
        :on-save="saveNotification"
        :has-changed="hasChanged"
        :on-cancel="clearState"
        :on-close="clearState"
        :min-width="776"
        :max-width="776">
        <template #body>
            <v-card
                flat
                class="notification-card"
                height="400px">
                <div class="d-inline-block notification-header py-0 px-12">
                    <label class="d-block text-capitalize color text-small-text field-label label-required">
                        {{ $t('notificationName') }}
                    </label>
                    <v-text-field
                        v-if="isNotificationNameEditable || !isEditMode"
                        v-model="notificationData.name"
                        class="std v-text-field--with-bottom-details notification-name"
                        type="text"
                        data-test-id="alertNotificationName"
                        height="54"
                        :rules="rules.notificationName"
                        :autofocus="!isEditMode"
                        outlined
                        required />
                    <div
                        v-else
                        class="d-flex align-center pb-3">
                        <truncate-string
                            class="ma-0 d-inline-block text-h4 font-weight-light color text-deep-ocean"
                            max-width="720px"
                            :text="notificationData.name ? notificationData.name : ''"
                            :dot-count="3"
                            :single-char-with-in-px="15.65" />
                        <v-icon
                            v-if="!isNotificationNameEditable"
                            color="primary"
                            size="18"
                            class="ml-4"
                            @click="isNotificationNameEditable = true">
                            $vuetify.icons.edit
                        </v-icon>
                    </div>
                </div>
                <v-col
                    cols="12"
                    class="pt-25 px-12">
                    <v-row>
                        <v-col cols="6">
                            <label class="d-block text-capitalize color text-small-text field-label label-required">
                                {{ $tc('channel') }}
                            </label>
                            <v-select
                                v-model="notificationData.channel_id"
                                :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                                outlined
                                single-line
                                item-text="channel_name"
                                item-value="id"
                                dense
                                attach
                                height="36"
                                required
                                :items="newChannels"
                                @change="onChannelSelect">
                                <template #item="{ item }">
                                    <span
                                        v-if="!item.id"
                                        class="text-decoration-underline color text-links pointer">{{
                                            item.channel_name }}</span>
                                    <span v-else>{{ item.channel_name }}</span>
                                </template>
                                <template #selection="{ item }">
                                    {{ item.channel_name }}
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="3">
                            <label class="d-block text-capitalize color text-small-text field-label label-required">
                                {{ $tc('repeatInterval') }}
                            </label>
                            <TimeRange
                                ref="timeRange"
                                height="36"
                                class-name="trigger-period"
                                :time="notificationData.repeat_interval"
                                :validation-handler="validateTriggerPeriod"
                                hide-details="auto"
                                @on-time-range-change="onPeriodChange" />
                        </v-col>
                    </v-row>
                    <div v-if="showNewChannel">
                        <v-row>
                            <v-col class="py-0">
                                <label class="d-block text-capitalize color text-small-text field-label label-required">
                                    {{ $t('channelName') }}
                                </label>
                                <v-text-field
                                    v-model="newChannelName"
                                    type="text"
                                    height="36"
                                    :rules="rules.channelName"
                                    single-line
                                    dense
                                    hide-details="auto"
                                    outlined
                                    required />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0">
                                <label class="d-block text-capitalize color text-small-text field-label label-required">
                                    {{ $t('to') }}
                                </label>
                                <EmailsInput
                                    data-test-id="alertChannelEmailTo"
                                    :label="$t('info.startTyping')"
                                    @on-change="onEmailChanged" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0">
                                <label class="d-block text-capitalize color text-small-text field-label label-required">
                                    {{ $t('categories') }}
                                </label>
                                <v-select
                                    v-model="newCategories"
                                    class="categories"
                                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                                    :elevation="200"
                                    outlined
                                    dense
                                    attach
                                    required
                                    single-line
                                    small-chip
                                    :rules="rules.categories"
                                    :items="categories"
                                    multiple>
                                    <template #selection="{ item }">
                                        <v-chip
                                            class="my-1"
                                            small>
                                            <span>{{ item }}</span>
                                        </v-chip>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="field-divider">
                        <v-divider />
                    </div>
                    <v-col
                        cols="12"
                        class="pa-0 mb-5">
                        <p class="my-2 text-uppercase font-weight-medium fields-header color text-deep-ocean">
                            {{ $tc('tag', 2) }}
                        </p>
                        <AlertTagsConfigurator
                            ref="alertTagsConfigurator"
                            :selected-tags="selectedTags"
                            :required-tag-types="['sysTags']"
                            :is-tag-without-label="true"
                            @on-tag-change="onTagChange"
                            @on-context-change="onSystemChange" />
                    </v-col>
                    <!-- TODO in MDBM-5016 -->
                    <!-- <div>
                        <div
                            class="text-decoration-underline color text-links pointer py-2"
                            @click="addNewPolicyBox">
                            {{ $t('createNewPolicies') }}
                        </div>
                        <div id="policies-container"></div>
                    </div> -->
                    <div class="field-divider">
                        <v-divider />
                    </div>
                    <v-col
                        cols="12"
                        class="pa-0 mb-5">
                        <p class="my-2 text-uppercase font-weight-medium fields-header color text-deep-ocean">
                            {{ $t('policiesThatTrigger') }}
                        </p>
                    </v-col>
                    <PolicyList :matching-tags="selectedTags" />
                </v-col>
            </v-card>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                class="save"
                :disabled="isSaveDisabled"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t('save') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import TimeRange from './TimeRange'
    import EmailsInput from './EmailsInput'
    import AlertTagsConfigurator from './AlertTagsConfigurator'
    import PolicyList from 'components/alerts/PolicyList.vue'
    import { createSystemTag } from 'utils/alert'
    export default {
        name: 'NotificationModal',
        components: {
            TimeRange,
            EmailsInput,
            AlertTagsConfigurator,
            PolicyList,
        },
        data() {
            return {
                isDialogOpen: false,
                isNotificationNameEditable: false,
                notificationData: null,
                selectedChannel: null,
                selectedTags: [],
                isEditMode: false,
                rules: {
                    notificationName: [(val) => !!val || this.$t('errors.fieldRequired', { field: this.$t('notificationName'), })],
                    categories: [(val) => !!val || this.$t('errors.fieldRequired', { field: this.$help.capitalize(this.$t('category')), })],
                    channelName: [(val) => !!val || this.$t('errors.fieldRequired', { field: this.$t('channelName'), })],
                },
                selectedRepeatInterval: '',
                showNewChannel: false,
                newChannelName: '',
                recipients: null,
                newCategories: null,
                categories: this.$config.ALL_CATEGORIES,
                customTags: [],
                selectedChannelId: '',
            }
        },
        computed: {
            ...mapGetters(['channels', 'tags']),
            newChannels() {
                return [...this.channels, { 'channel_name': this.$t('newChannel'), 'id': '', }]
            },
            hasChanged: function () {
                return !this.$lodash.isEqual(this.initialNotification, this.notificationData)
            },
            defEmails: function () {
                if (this.channelData.receivers) return this.channelData.receivers.map(receiver => receiver.email)
                return []
            },
            isFormValid: function () {
                return this.$refs.notificationDialogRef.isFormValid
            },
            isSaveDisabled: function () {
                return !this.hasChanged || !this.isFormValid
            },
        },
        methods: {
            ...mapActions(['createNotification', 'updateNotification', 'createTag', 'addChannel']),
            async open(notification, selectedChannel, selectedTags) {
                this.isDialogOpen = true
                if (notification) {
                    this.notificationData = this.$lodash.cloneDeep(notification)
                    this.initialNotification = this.$lodash.cloneDeep(notification)
                    this.selectedChannel = this.$lodash.cloneDeep(selectedChannel)
                    this.setSelectedTags(this.$lodash.cloneDeep(selectedTags))
                    this.isEditMode = true
                } else {
                    this.isEditMode = false
                    let allTag = createSystemTag(this.$config.allTopologiesTag)
                    const foundTag = this.findTag(allTag)
                    if (foundTag) this.setSelectedTags([foundTag])
                    else {
                        await this.createTag(allTag)
                        const foundTag = this.findTag(allTag)
                        if (foundTag) this.setSelectedTags([foundTag])
                    }
                }
            },
            onPeriodChange(timeRange) {
                this.notificationData['repeat_interval'] = `${timeRange.value}${timeRange.suffix}`
            },
            validateTriggerPeriod(v, suffix) {
                if (!v) return this.$t('errors.fieldRequired', { field: this.$t('repeatInterval'), })
                else if (this.$help.toSeconds({ v, suffix, }) < 600) return this.$t('errors.minRepeatInterval')
                return true
            },
            onEmailChanged(emails) {
                this.recipients = emails.map(email => { return { email, } })
            },
            onChannelSelect(id) {
                this.showNewChannel = !id
                this.notificationData['channel_id'] = id
            },
            setSelectedTags(tags) {
                this.selectedTags = tags
                this.notificationData.tags = this.selectedTags.map(tag => tag.id)
            },
            onTagChange(newTags) {
                this.setSelectedTags(newTags)
            },
            onSystemChange(sysTags = []) {
                if (!this.$typy(sysTags).isEmptyArray) {
                    sysTags.forEach(tag => {
                        const foundTag = this.selectedTags.find(({ value, type, }) => tag.value == value && tag.type == type)
                        if (foundTag) tag.id = foundTag.id
                    })
                }
                const { SYSTEM, } = this.$config.TAG_BODY.TYPE
                let i = 0
                while (i < this.selectedTags.length) {
                    if (this.selectedTags[i].type === SYSTEM) {
                        // Remove old system tags
                        this.selectedTags.splice(i, 1)
                    } else {
                        i++
                    }
                }
                this.setSelectedTags([...sysTags, ...this.selectedTags])
            },
            findTag(selTag) {
                return this.tags.find(({ value, type, }) => {
                    return selTag.value === value && selTag.type === type
                })
            },
            async updateNotificationData() {
                return new Promise(async (resolve, reject) => {
                    if (this.showNewChannel) {
                        let channelData = {
                            'channel_name': this.newChannelName,
                            'receivers': this.recipients,
                            'categories': this.newCategories,
                        }
                        await this.addChannel(channelData)
                        const foundChan = this.channels.find(channel => channel.channel_name === this.newChannelName)
                        if (foundChan) this.notificationData['channel_id'] = foundChan.id
                        else reject()
                    }
                    let selTagIds = []
                    for (let selTag of this.selectedTags) {
                        if (!selTag['id']) {
                            // If newly added tag is exiting, add id
                            const foundTag = this.findTag(selTag)
                            if (foundTag) selTag.id = foundTag.id
                            // else create tag
                            else {
                                await this.createTag(selTag)
                                const foundTag = this.findTag(selTag)
                                if (foundTag) selTag.id = foundTag.id
                                else reject()
                            }
                        }
                        selTagIds.push(selTag.id)
                    }
                    this.notificationData.tags = selTagIds
                    resolve()
                })
            },
            async saveNotification() {
                this.updateNotificationData().then(async () => {
                    if (this.isEditMode) {
                        await this.updateNotification({ id: this.notificationData.id, notificationData: this.notificationData, })
                    } else {
                        await this.createNotification(this.notificationData)
                    }
                    this.clearState()
                    this.isDialogOpen = false
                }, () => {
                    this.$store.commit('showMessage', { text: this.$t(this.isEditMode ? 'errors.notificationEdit' : 'errors.notificationCreate'), type: this.$config.messageType.ERROR, })
                })
            },
            clearState() {
                this.notificationData = {}
                this.isNotificationNameEditable = false
                this.showNewChannel = false
            },
            addNewPolicyBox() {
            // TODO
            },
        },
        created() {
            this.notificationData = {}
        },
    }
</script>
