import base from 'api/base'

const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL
const backupURI = 'skybackup/v1'

export default {
    get (uri, params, options) {
        return base.get(`${baseUrl}/${backupURI}/${uri}`, params, options)
    },
    post (uri, body, params, options) {
        return base.post(`${baseUrl}/${backupURI}/${uri}`, body, params, options)
    },
    patch (uri, body, params, options) {
        return base.patch(`${baseUrl}/${backupURI}/${uri}`, body, params, options)
    },
    delete (uri, params) {
        return base.delete(`${baseUrl}/${backupURI}/${uri}`, params)
    },
}
