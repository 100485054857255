<template>
    <div>
        <div
            class="mt-6">
            <h2>{{ $t('connectingUsing_X', [ $t('mariaDBCLI')]) }}</h2>
            <p class="my-3">
                {{ $t('dualEndpoints.mariadbCLINote') }}
            </p>
            <codeBlock
                v-for="(snippet, index) in generateMariaDBConnectSnippet()"
                :key="index"
                class="mt-2"
                :text="snippet" />

            <div class="mt-3">
                {{ $t('changeDefaultPassword') }}:
            </div>
            <codeBlock
                class="mt-2"
                :text="generateMariaDBChangePasswordSnippet()" />
        </div>
        <div
            v-if="service.nosql_enabled"
            class="mt-8">
            <h2>{{ $t('connectingUsing_X', [$t('mongosh')]) }}</h2>
            <codeBlock
                class="mt-2"
                :text="generateNoSQLConnectSnippet()" />

            <div class="mt-3">
                {{ $t('authenticate') }}:
            </div>
            <codeBlock
                class="mt-2"
                :text="generateNoSQLAuthSnippet()" />

            <div class="mt-3">
                {{ $t('changeDefaultPassword_MXS_MDB') }}:
            </div>
            <codeBlock
                class="mt-2"
                :text="generateNoSQLChangePasswordSnippet()" />

            <div class="mt-3">
                {{ $t('changeDefaultPassword_MXS') }}:
            </div>
            <codeBlock
                class="mt-2"
                :text="generateNoSQLChangePasswordSnippet(true)" />
        </div>
    </div>
</template>
<script>
    import { getEndpointFQDN, getNoSQLPort, getReadWritePort, isXpand } from 'utils/service'
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import codeBlock from 'components/common/codeBlock.vue'
    const ConnectionOptions = {
        name: 'ConnectionOptions',
        components: {
            codeBlock,
        },
        props: {
            service: {
                type: Object,
                required: true,
            },
            serviceCredentials: {
                type: Object,
                required: true,
            },
            endpoint: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapGetters(['getServiceById', 'getServiceEndpointPorts']),
            fqdn() {
                return getEndpointFQDN(this.endpoint)
            },
            readWrite() {
                return getReadWritePort(this.endpoint)?.port
            },
            nosql() {
                return getNoSQLPort(this.endpoint)?.port
            },
        },
        methods: {
            ...mapMutations(['setServiceCredentialsDialog']),
            ...mapActions(['copyToClipboard', 'openPrivateConnectModal']),
            extractChainFileName(URL) {
                const parts = URL.split('/')
                return parts.slice(-1)
            },
            generateMariaDBConnectSnippet() {
                let connectSnippets = []
                /* eslint-disable camelcase */
                const { username, authChainURL, } = this.serviceCredentials
                const { ssl_enabled, topology, domains, custom_ssl, } = this.service

                if (!this.fqdn || !this.readWrite || !username || !authChainURL) return this.$t('notApplicable')
                let options = ''

                if (ssl_enabled) {
                    const certificate = this.extractChainFileName(authChainURL)
                    options += custom_ssl ? `--ssl-ca ~/Downloads/${certificate}` : '--ssl-verify-server-cert'
                }

                if (isXpand(topology)) {
                    options += '--default-character-set=utf8'
                }

                connectSnippets = [`mariadb --host ${this.fqdn} --port ${this.readWrite} --user ${username} -p ${options}`]
                if (domains?.length && custom_ssl) {
                    domains.forEach(domain => {
                        connectSnippets.push(`mariadb --host ${domain} --port ${this.readWrite} --user ${username} -p ${options}`)
                    })
                }
                return connectSnippets
            /* eslint-enable */
            },
            generateMariaDBChangePasswordSnippet() {
                const { host, username, } = this.serviceCredentials
                if (!host || !username) return this.$t('notApplicable')
                return `SET PASSWORD FOR '${username}'@'${host}' = PASSWORD('new_password');`
            },
            generateNoSQLConnectSnippet() {
                /* eslint-disable camelcase */
                const { ssl_enabled, } = this.service

                if (!this.fqdn || !this.nosql) return this.$t('notApplicable')
                let options = ''
                if (ssl_enabled) {
                    options += '--tls'
                }

                return `mongosh --host ${this.fqdn} --port ${this.nosql} ${options}`
            /* eslint-enable */
            },
            generateNoSQLAuthSnippet() {
                const { username, } = this.serviceCredentials

                return `use mariadb;\ndb.auth("${username}");`
            },
            generateNoSQLChangePasswordSnippet(mxsOnly = false) {
                const { username, } = this.serviceCredentials
                if (!username) return this.$t('notApplicable')

                return `db.runCommand({ ${mxsOnly ? 'mxsUpdateUser' : 'updateUser'}: "${username}", pwd: "new_password" });`
            },
        },
    }

    export default ConnectionOptions
</script>
