import i18n from 'plugins/i18n'
import { isPrimaryReplica } from 'utils/service'

// TODO: This function has a confusing name and implications (needs refactoring)
export function hasNodesInTopology(topology) {
    return !isPrimaryReplica(topology)
}

export function getNodesTextForReplicatedTopology(nodeCount) {
    return `${i18n.t('launchService.primary')} • ${nodeCount - 1} ${i18n.tc(
        'launchService.replicas',
        nodeCount - 1
    )}`
}

export function getNodesTextForNonReplicatedTopology(nodeCount) {
    return `${nodeCount} ${i18n.tc('launchService.nodes', nodeCount)}`
}
