<template>
    <svg
        viewBox="0 0 117 115"
        fill="none">
        <path
            d="M116.159 60.8033V61.0707C116.13 62.4298 115.432 63.7518 114.087 64.5243L110.262 66.7301L63.6205 93.6605C60.5532 95.4281 56.7655 95.4281 53.6907 93.6605L7.06377 66.7301L3.23892 64.5243C1.85749 63.7296 1.16675 62.3407 1.16675 60.9444C1.16675 59.5481 1.85006 58.1593 3.23892 57.3646L53.6907 28.2358C56.7655 26.4533 60.5532 26.4533 63.6205 28.2358L114.08 57.3646C115.417 58.137 116.115 59.4665 116.152 60.8182L116.159 60.8033Z"
            fill="white"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M116.159 61.0711V80.0843C116.129 81.4434 115.432 82.7654 114.087 83.5379L63.6278 112.674C60.5605 114.442 56.7728 114.442 53.698 112.674L3.24624 83.5379C1.86481 82.7432 1.17407 81.3543 1.17407 79.958V60.9448C1.17407 62.3411 1.85739 63.73 3.24624 64.5247L7.07109 66.7305L53.698 93.6609C56.7728 95.4285 60.5605 95.4285 63.6278 93.6609L110.27 66.7305L114.095 64.5247C115.432 63.7522 116.13 62.4302 116.167 61.0711H116.159Z"
            fill="#D9DAE0"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M29.6639 93.8984L9.54416 82.2825C9.13568 82.0448 8.88306 81.6066 8.88306 81.1387V74.7515C8.88306 74.0756 9.61835 73.6449 10.2051 73.9865L30.325 85.6024C30.7335 85.84 30.9859 86.2782 30.9859 86.7461V93.1334C30.9859 93.8092 30.2506 94.24 29.6639 93.8984Z"
            fill="#5db459"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M23.9231 86.2485L26.879 87.9493"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M12.9905 79.9282L20.9522 84.5256"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M116.159 34.6881V34.9554C116.13 36.3146 115.432 37.6366 114.087 38.409L110.262 40.6148L63.6205 67.5452C60.5532 69.3129 56.7655 69.3129 53.6907 67.5452L7.06377 40.6148L3.23892 38.409C1.85749 37.6143 1.16675 36.2255 1.16675 34.8292C1.16675 33.4329 1.85006 32.0441 3.23892 31.2494L53.6907 2.12056C56.7655 0.33807 60.5532 0.33807 63.6205 2.12056L114.08 31.2494C115.417 32.0218 116.115 33.3512 116.152 34.7029L116.159 34.6881Z"
            fill="white"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M116.159 34.9554V53.9686C116.129 55.3277 115.431 56.6497 114.087 57.4221L63.6277 86.5584C60.5604 88.326 56.7727 88.326 53.6979 86.5584L3.24612 57.4221C1.86469 56.6274 1.17395 55.2386 1.17395 53.8423V34.8291C1.17395 36.2254 1.85727 37.6142 3.24612 38.4089L7.07097 40.6148L53.6979 67.5452C56.7727 69.3128 60.5604 69.3128 63.6277 67.5452L110.27 40.6148L114.095 38.4089C115.431 37.6365 116.129 36.3145 116.167 34.9554H116.159Z"
            fill="#D9DAE0"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M29.664 67.7826L9.54429 56.1668C9.1358 55.9291 8.88318 55.4909 8.88318 55.023V48.6358C8.88318 47.9599 9.61848 47.5291 10.2052 47.8708L30.3251 59.4867C30.7336 59.7243 30.986 60.1625 30.986 60.6304V67.0177C30.986 67.6935 30.2507 68.1243 29.664 67.7826Z"
            fill="#5db459"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M18.4274 56.9629L26.8792 61.8424"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M12.9906 53.8208L15.8278 55.4548"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M41.4794 72.582C42.3173 72.2255 42.4849 70.734 41.8537 69.2506C41.2226 67.7673 40.0317 66.8539 39.1938 67.2104C38.3559 67.5669 38.1883 69.0584 38.8195 70.5418C39.4506 72.0251 40.6415 72.9385 41.4794 72.582Z"
            fill="#003545" />
        <path
            d="M49.655 77.2993C50.4929 76.9427 50.6604 75.4513 50.0293 73.9679C49.3981 72.4846 48.2072 71.5712 47.3693 71.9277C46.5314 72.2842 46.3638 73.7757 46.995 75.259C47.6262 76.7424 48.817 77.6558 49.655 77.2993Z"
            fill="#003545" />
        <path
            d="M41.473 98.2148C42.3109 97.8583 42.4784 96.3668 41.8473 94.8835C41.2161 93.4001 40.0252 92.4867 39.1873 92.8432C38.3494 93.1998 38.1818 94.6912 38.813 96.1746C39.4441 97.6579 40.635 98.5713 41.473 98.2148Z"
            fill="#003545" />
        <path
            d="M49.6512 102.939C50.4891 102.583 50.6567 101.091 50.0255 99.6081C49.3943 98.1247 48.2034 97.2113 47.3655 97.5678C46.5276 97.9244 46.36 99.4158 46.9912 100.899C47.6224 102.382 48.8133 103.296 49.6512 102.939Z"
            fill="#003545" />
        <path
            d="M88.7386 62.8885L107.537 52.0376"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M74.3005 71.2211L81.6309 66.9951"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M73.9519 97.5609L92.7498 86.71"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M99.9465 82.0233L107.277 77.7974"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>

<script>
    export default {}
</script>
