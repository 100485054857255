import { render, staticRenderFns } from "./PartnerIntegrations.vue?vue&type=template&id=2d53bd87&scoped=true"
import script from "./PartnerIntegrations.vue?vue&type=script&lang=js"
export * from "./PartnerIntegrations.vue?vue&type=script&lang=js"
import style0 from "./PartnerIntegrations.vue?vue&type=style&index=0&id=2d53bd87&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d53bd87",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2d53bd87')) {
      api.createRecord('2d53bd87', component.options)
    } else {
      api.reload('2d53bd87', component.options)
    }
    module.hot.accept("./PartnerIntegrations.vue?vue&type=template&id=2d53bd87&scoped=true", function () {
      api.rerender('2d53bd87', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/integrations/PartnerIntegrations.vue"
export default component.exports