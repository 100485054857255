<style lang="scss" scoped>
.storage-note {
    font-size: 10px;
}
</style>
<template>
    <div>
        <v-row class="mt-5">
            <v-col cols="8">
                <div>
                    <div class="text-body-2 font-weight-medium">
                        {{ $t('launchService.provisionedIOPs') }}
                    </div>
                    <Slider
                        v-if="areGp3RangesReady"
                        :default-value="currIOPSValue"
                        :min-value="iopsValues.min"
                        :max-value="iopsValues.max"
                        :step-value="iopsValues.step"
                        :tick-size="0"
                        :ticks-labels="iopsLabels"
                        class-name="iops-slider"
                        :dirty-dom="false"
                        :show-input="false"
                        @handleChange="handleIOPSChange" />
                </div>
            </v-col>
            <v-col cols="4">
                <p class="pt-6 storage-note">
                    {{ $t('launchService.provisionedIOPSRange') }}
                </p>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col>
                <div>
                    <div class="text-body-2 font-weight-medium">
                        {{ $t('launchService.storageThroughput') }}
                    </div>
                    <Slider
                        v-if="areGp3RangesReady"
                        :default-value="currThroughputValue"
                        :min-value="throughputValues.min"
                        :max-value="throughputValues.max"
                        :step-value="throughputValues.step"
                        :tick-size="0"
                        :ticks-labels="throughputLabels"
                        class-name="throughput-slider"
                        :dirty-dom="false"
                        :show-input="false"
                        @handleChange="handleThroughputChange" />
                </div>
            </v-col>
            <v-col cols="4">
                <p class="pt-6  storage-note">
                    {{ $t('launchService.storageThroughputRange') }}
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Slider from 'components/Slider.vue'
    import { mapGetters } from 'vuex'
    export default {
        name: 'IopsAndThroughputSelection',
        components: { Slider, },
        props: {
            selectedIops: {
                type: Number,
                default: 0,
            },
            selectedThroughput: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                currIOPSValue: 0,
                currThroughputValue: 0,
            }
        },
        computed: {
            ...mapGetters(['volumeTypes']),
            gp3Ranges() {
                let type = this.volumeTypes.find(type => type.name == this.$config.iops.gp3)
                return type ? type.iops[0] : {}
            },
            iopsValues() {
                return {
                    min: this.gp3Ranges.total_iops_range_min,
                    max: this.gp3Ranges.total_iops_range_max,
                    step: 100,
                }
            },
            throughputValues() {
                return {
                    min: this.gp3Ranges.throughput_range_min,
                    max: this.gp3Ranges.throughput_range_max,
                    step: 1,
                }
            },
            areGp3RangesReady() {
                return !this.$typy(this.gp3Ranges).isEmptyObject
            },
            iopsLabels() {
                const { min, max, step, } = this.iopsValues
                return this.createLabels(min, max, step, this.currIOPSValue)
            },
            throughputLabels() {
                const { min, max, step, } = this.throughputValues
                return this.createLabels(min, max, step, this.currThroughputValue, this.$t('mbPerSec'))
            },
        },
        // If the Scale Storage modal is opened quickly on page load then the props are not updated
        // hence the need of Watch
        watch: {
            selectedIops() {
                this.currIOPSValue = this.selectedIops ? this.selectedIops : this.iopsValues.min
            },
            selectedThroughput() {
                this.currThroughputValue = this.selectedThroughput ? this.selectedThroughput : this.throughputValues.min
            },
        },
        methods: {
            createLabels(min, max, step, value, unit) {
                let labels = Array((max - min) / step + 1).fill('')
                labels[(value - min) / step] = unit ? `${value} ${unit}` : value
                return labels
            },
            setLabels(min, step, labels, currValue, newVal, unit) {
                labels[(currValue - min) / step] = ''
                labels[(newVal - min) / step] = unit ? `${newVal} ${unit}` : newVal
            },
            handleIOPSChange(newVal) {
                const { min, step, } = this.iopsValues
                this.setLabels(min, step, this.iopsLabels, this.currIOPSValue, newVal)
                this.currIOPSValue = newVal
                this.$emit('on-iops-change', newVal)
            },
            handleThroughputChange(newVal) {
                const { min, step, } = this.throughputValues
                this.setLabels(min, step, this.throughputLabels, this.currThroughputValue, newVal, this.$t('mbPerSec'))
                this.currThroughputValue = newVal
                this.$emit('on-throughput-change', newVal)
            },
        },
    }
</script>
