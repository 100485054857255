<style lang="scss">
.v-slider__thumb {
    height: 15px !important;
    width: 15px !important;
    border: 4px solid;
    background-color: $primary !important;
}

.v-slider--horizontal .v-slider__track-container {
    height: 10px !important;
}

.v-slider__tick,
.v-slider__tick--filled {
    background-color: $primary !important;
}

.v-slider__tick-label {
    top: 1rem !important;
    color: $small-text
}

.current-label {
    color: $black
}

.slider-input {
    border: 1px solid $tabs-border;
    border-radius: 4px
}

input[type='number'] {
    width: 30px;
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
<template>
    <div>
        <div v-if="showInput">
            <input
                v-model="currentValue"
                :disabled="isDisabled"
                class="slider-input"
                type="number"
                @keyup="onValueChange(currentValue)" />
            <slot name="input-text">
            </slot>
        </div>
        <v-slider
            v-model="currentValue"
            :class="className"
            thumb-color="#6353ef"
            :thumb-label="!ticks"
            :max="maxValue"
            :min="minValue"
            :step="stepValue"
            track-color="#E8EEF1"
            track-fill-color="#6353ef"
            height="50"
            :disabled="isDisabled"
            :tick-labels="ticksLabels"
            :tick-size="tickSize"
            @change="onValueChange" />
    </div>
</template>
<script>
    export default {
        props: {
            minValue: {
                type: Number,
                required: true,
            },
            maxValue: {
                type: Number,
                required: true,
            },
            stepValue: {
                type: Number,
                default: 1,
            },
            defaultValue: {
                type: Number,
                default: 0,
            },
            ticksLabels: {
                type: Array,
                default: () => {
                    return []
                },
            },
            className: {
                type: String,
                default: null,
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
            ticks: {
                type: String,
                default: '',
            },
            showInput: {
                type: Boolean,
                default: false,
            },
            showTicks: {
                type: Boolean,
                default: false,
            },
            dirtyDom: {
                type: Boolean,
                default: true,
            },
            tickSize: {
                type: Number,
                default: 2,
            },
        },
        data() {
            return {
                currentValue: this.defaultValue,
            }
        },
        computed: {
            domParentSelection() {
                return document.getElementsByClassName(this.className)[0]
            },
        },
        watch: {
            defaultValue() {
                // Current value should be always in sync with default value
                // There can be a case where default value gets updated late
                this.currentValue = this.defaultValue
            },
        },
        methods: {
            onValueChange(number) {
                if (this.dirtyDom) {
                    let index = null
                    if (this.ticksLabels.length && this.stepValue === 1) {
                        // Actual number is 1 less than the number in ticksLabel array
                        // as the Array starts with a non zero element
                        index = number - 1
                        number = this.ticksLabels[number - 1]
                    }
                    this.$emit('handleChange', number)

                    //If ticks need to be shown
                    if (this.showTicks) return

                    //back with dirty dom manipulation to show tick labels
                    const ticks = this.domParentSelection.getElementsByClassName('v-slider__tick-label')

                    if (ticks && ticks.length) {
                        for (let i = 0; i < ticks.length; i++) {
                            ticks[i].innerHTML = ''
                        }

                        if (index !== null) ticks[index].innerHTML = number
                    }
                } else {
                    this.$emit('handleChange', number)
                }
            },
        },
        mounted() {
            this.onValueChange(this.currentValue)
        },
    }
</script>
