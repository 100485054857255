<template>
    <v-snackbar
        v-model="message.status"
        :color="message.type"
        :bottom="true"
        :right="true"
        :timeout="timeout">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="message.text"></div>
        <!--eslint-enable-->
        <template #action="{ attrs }">
            <v-btn
                class="mr-0"
                v-bind="attrs"
                text
                icon
                @click="message.status = false">
                <v-icon color="white">
                    mdi-close
                </v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: 'Snackbars',
        data () {
            return {
                timeout: 6000,
            }
        },
        computed: {
            message () {
                return this.$store.state.message
            },
        },
    }
</script>
