export function serviceMemoryUsage ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(container_memory_rss{kubernetes_namespace="${id}", container=~".+"}) by (kubernetes_pod_name)`
    }
}

export function serviceMemoryUsageCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(container_memory_rss{kubernetes_pod_name=~"cs-node.*", kubernetes_namespace="${id}", container=~".+"}) by (kubernetes_pod_name)`
    }
}

export function maxAvailableMemory ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(container_spec_memory_limit_bytes{kubernetes_pod_name="${name}", kubernetes_namespace="${id}", container=~"mariadb|mariadb-server|columnstore-module.*"})`
    }
}

export function usedMemory ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(container_memory_rss{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}", container=~".+"})`
    }
}

export function cachedMemory ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(container_memory_cache{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}",container=~".+"})`
    }
}

export function innoDbBufferPoolData ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_status_innodb_page_size{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"} * on (instance) mariadb_global_status_buffer_pool_pages{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}",state="data"}) by (kubernetes_pod_name)`
    }
}

export function innoDbLogBufferSize ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_variables_innodb_log_buffer_size{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function innoDbBufferPoolSize ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `mariadb_global_variables_innodb_buffer_pool_size{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}/1073741824`
    }
}


export function innoDbBufferPoolSizeAgainstMemoryLimit ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `(max(container_spec_memory_limit_bytes{kubernetes_pod_name="${name}", kubernetes_namespace="${id}", container=~"mariadb|mariadb-server|columnstore-module.*"}) - scalar(mariadb_global_variables_innodb_buffer_pool_size{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}))/1073741824`
    }
}

export function keyBufferSize ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_variables_key_buffer_size{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serverMemoryUsage ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(container_memory_rss{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}", container=~".+"})/max(container_spec_memory_limit_bytes{kubernetes_pod_name="${name}", kubernetes_namespace="${id}", container=~"mariadb|mariadb-server|columnstore-module.*"}) * 100`
    }
}

export function maxScaleResidentMemory ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(process_resident_memory_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function maxScaleVirtualMemory ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(process_virtual_memory_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandMemoryReservedBytes ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_memory_reserved_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}"})`
    }
}

export function xpandMemoryTotalBytes ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(mariadb_xpand_memory_total_bytes{kubernetes_pod_name="${name}", kubernetes_namespace="${id}"})`
    }
}

export function xpandMemoryUsage ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_memory_working_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}"})/max(mariadb_xpand_memory_total_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}"})`
    }
}

export function xpandMemoryWorkingBytes ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_memory_working_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"})`
    }
}

export function xpandMemoryWorkingByBytesByNode ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_memory_working_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandMemoryReservedBytesByNode ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_memory_reserved_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandMemoryTotalBytesByNode ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_memory_total_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}
export function xpandMemoryTotal ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_memory_total_bytes{mariadb=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandMemoryBmBytes ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_memory_bm_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}"})`
    }
}

export function maxContainerMemoryLimitBytes ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(container_spec_memory_limit_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}", container=~".+"})`
    }
}

export function memoryRss ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(container_memory_rss{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}", container=~".+"})`
    }
}

export function xpandMemoryWorkingBytesNode ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_memory_working_bytes{mariadb=~"${name}", kubernetes_namespace=~"${id}"}) by (kubernetes_pod_name)/avg(mariadb_xpand_memory_total_bytes{mariadb=~"${name}", kubernetes_namespace=~"${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandMemoryReservesBytesMariadbNode ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_memory_reserved_bytes{mariadb=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}
export function xpandMemoryWorkingBytesMariadbNode ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_memory_working_bytes{mariadb=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandMemoryBmBytesMariadbNode ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_memory_bm_bytes{mariadb=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function containerMemoryRss ({ name, id, period = '300s', } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_memory_rss{mariadb=~"${name}", kubernetes_namespace=~"${id}", container=~".+"}[${period}])) by (kubernetes_pod_name)`
    }
}
