<style lang="scss" scoped>
.tech-preview {
    font-size: 8px;
    color: $accent;
    border: 1px solid $accent;
    border-radius: 4px;
    padding: 3px;
    font-weight: 500;
    white-space: nowrap;
}
</style>
<template>
    <span class="tech-preview text-uppercase d-inline-block">
        {{ label }}
    </span>
</template>
<script>
    export default {
        name: 'TechPreview',
        props: {
            label: {
                type: String,
                default: 'Tech Preview',
            },
        },
    }
</script>
