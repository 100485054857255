<style lang="scss" scoped>
.config-name {
    width: 189px;
}

.topology {
    width: 270px;
}

.server-version {
    width: 250px;
}

.version {
    width: 100px;
}
</style>
<template>
    <div>
        <div class="d-flex align-center justify-space-between mt-2">
            <div class="d-flex">
                <div class="config-name">
                    <label class="text-uppercase text-caption">
                        {{ $t('name') }}
                    </label>
                    <v-text-field
                        v-model="configName"
                        outlined
                        dense
                        height="32px"
                        required />
                </div>
                <div class="ml-2 topology">
                    <label class="text-uppercase text-caption">
                        {{ $t('launchService.topology') }}
                    </label>
                    <div>
                        <v-select
                            v-model="topologyId"
                            :items="filteredTopologies"
                            :disabled="configInEditMode"
                            :placeholder="$t('configManager.selectTopology')"
                            class="std std--dropdown"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                            height="40"
                            outlined
                            item-value="id"
                            attach
                            required
                            hide-details
                            @change="getVersions">
                            <template #item="{ item }">
                                {{ $help.getTopologyName(item.name) }}
                            </template>
                            <template #selection="{ item }">
                                {{ $help.getTopologyName(item.name) }}
                            </template>
                        </v-select>
                    </div>
                </div>
                <div
                    v-if="!configInEditMode"
                    class="ml-2 server-version">
                    <label class="text-uppercase text-caption">
                        {{ $t('configManager.serverVersion') }}
                    </label>
                    <div>
                        <v-select
                            v-model="versionId"
                            :items="sortedServerVersions"
                            :placeholder="$t('configManager.selectVersion')"
                            class="std std--dropdown"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                            height="40"
                            outlined
                            item-value="id"
                            attach
                            required
                            hide-details
                            @change="setConfigData">
                            <template #item="{ item }">
                                {{ item.display_name }}
                                <TechPreview
                                    v-if="isVersion23(item.version)"
                                    class="mt-n1 ml-3" />
                            </template>
                            <template #selection="{ item }">
                                {{ item.display_name }}
                            </template>
                        </v-select>
                    </div>
                </div>
                <div
                    v-if="!configInEditMode"
                    class="pl-5 version">
                    <label class="text-uppercase text-caption">
                        {{ $t('version') }}
                    </label>
                    <div>{{ $t('new') }}</div>
                </div>
            </div>
            <search-box
                v-model="searchText"
                class="color text-text border-text-subtle"
                position="right"
                @click.native.stop />
        </div>
        <config-data-table
            :configurations="filteredConfigs"
            :in-edit-mode="true"
            :topology-name="selectedTopology"
            @config-form-validity="setConfigFormValidity" />
        <div class="d-flex pt-5">
            <v-spacer />
            <v-btn
                color="primary"
                depressed
                rounded
                outlined
                class="cancel mr-3"
                @click="reset">
                <span class="text-none text-capitalize">{{ $t('configManager.resetToDefaults') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                depressed
                rounded
                outlined
                class="cancel mr-3"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                class="save"
                :disabled="disableSave"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t('save') }}</span>
            </v-btn>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import ConfigDataTable from 'components/configurationManager/ConfigDataTable.vue'
    import { fixDefaultValues } from 'utils/configManager'
    import { isVersion23, sortServerVersions } from 'utils/service'
    import TechPreview from 'components/common/TechPreview.vue'

    export default {
        name: 'ConfigureConfig',
        components: {
            'config-data-table': ConfigDataTable,
            TechPreview,
        },
        props: {
            selectedConfig: {
                type: Object,
                default: () => { },
            },
        },
        data() {
            return {
                configName: this.selectedConfig ? this.selectedConfig.name : this.$t('configManager.myNewConfig'),
                topologyId: this.selectedConfig ? this.selectedConfig.topology_id : '',
                searchText: '',
                showConfigData: false,
                versionId: this.selectedConfig ? this.selectedConfig.version_id : '',
                configFormValidity: false,
                baseConfigs: [],
                isReset: false,
            }
        },
        computed: {
            ...mapGetters(['allTopologies', 'products', 'configsPerProduct', 'newConfigResponse', 'configInEditMode', 'topologyConfigs', 'serverVersions', 'selectedValuesListSuccess', 'selectedConfigDetails', 'updateConfigSuccess']),
            filteredTopologies() {
                // TODO: remove the filter
                return this.allTopologies.filter(() => true)
            },
            filteredConfigs() {
                return !this.searchText ? this.combinedConfigs :
                    this.combinedConfigs.filter(config => {
                        let continueLoop = true
                        const { selected_values: selectedVal, default_value: defaultVal, name, tags, } = config;
                        [selectedVal, defaultVal, name, tags].every(prop => {
                            if (Array.isArray(prop)) prop = prop.join(', ')
                            if (prop) continueLoop = !prop.toLowerCase().includes(this.searchText.toLowerCase())
                            return continueLoop
                        })
                        return !continueLoop
                    })
            },
            selectedTopology() {
                const foundTopology = this.allTopologies.find(topology => topology.id == this.topologyId)
                return foundTopology ? foundTopology.name : ''
            },
            disableSave() {
                return !this.topologyId || !this.versionId || !this.configFormValidity
            },
            sortedServerVersions() {
                return sortServerVersions(this.serverVersions)
            },
            combinedConfigs: {
                get() {
                    return this.configInEditMode && !this.isReset ? this.$lodash.cloneDeep(this.selectedConfigDetails) : this.baseConfigs
                },
                set(configs) {
                    this.baseConfigs = configs
                },
            },
        },
        methods: {
            ...mapActions(['getAllTopologies', 'getProducts', 'getConfigsPerProduct', 'createNewConfig', 'getConfigsList', 'getTopologyConfigs', 'getServerVersions', 'setSelectedValuesList', 'updateSelectedConfig']),
            async setConfigData() {
                this.showConfigData = false
                this.combinedConfigs = []
                if (this.topologyId) {
                    let topologyName = this.filteredTopologies.find(topology => topology.id === this.topologyId).name
                    await this.getTopologyConfigs({ topologyName, })
                    this.combinedConfigs = this.$lodash.cloneDeep(this.topologyConfigs)
                    fixDefaultValues(this.combinedConfigs)
                    this.showConfigData = true
                }
            },
            reset() {
                this.isReset = true
                this.setConfigData()
            },
            cancel() {
                if (this.configInEditMode) {
                    this.combinedConfigs = this.$lodash.cloneDeep(this.selectedConfigDetails)
                    this.$store.commit('toggleEditMode', false)
                } else {
                    this.$router.push('/settings/configuration-manager/')
                }
            },
            async save() {
                let selectedValuesList = []
                this.combinedConfigs.forEach(config => {
                    const { selected_values: selectedVal, id, } = config
                    if (selectedVal && selectedVal.length) {
                        let newValues = []
                        if (this.$help.hasFlags([this.$config.CONFIG_MANAGER_FLAGS.SI_UNITS], config.flags)) {
                            selectedVal.forEach(value => {
                                newValues.push(String(this.byteToInt(value)))
                            })
                        }
                        selectedValuesList.push({
                            'config_key_id': id,
                            'selected_values': newValues.length ? newValues : selectedVal,
                        })
                    }
                })
                if (this.configInEditMode) {
                    if (this.configName != this.selectedConfig.name) {
                        await this.updateSelectedConfig({ configId: this.selectedConfig.id, nameObject: { name: this.configName, }, })
                    }
                    if (selectedValuesList.length) {
                        await this.setSelectedValuesList({ configId: this.selectedConfig.id, selectedValuesList, })
                    }
                    if (this.updateConfigSuccess) {
                        this.$router.push(`/settings/configuration-manager/${this.configName}`)
                    }
                    if (this.selectedValuesListSuccess || this.updateConfigSuccess) {
                        this.$emit('on-save')
                        this.$store.commit('showMessage', { text: this.$t('success.configEdited'), type: this.$config.messageType.SUCCESS, })
                        this.$store.commit('toggleEditMode', false)
                        this.getConfigsList()
                    } else {
                        this.$store.commit('showMessage', { text: this.$t('errors.configEdit'), type: this.$config.messageType.ERROR, })
                    }
                } else {
                    let configObject = {
                        'name': this.configName,
                        'topology_id': this.topologyId,
                        'version_id': this.versionId,
                    }
                    await this.createNewConfig({ configObject, })
                    if (this.newConfigResponse) {
                        await this.getConfigsList()
                        if (selectedValuesList.length) {
                            // Separate API call needed to save selected values if any
                            await this.setSelectedValuesList({ configId: this.newConfigResponse.id, selectedValuesList, })
                        }
                        if (!selectedValuesList.length || this.selectedValuesListSuccess) {
                            this.$store.commit('showMessage', { text: this.$t('success.configCreated'), type: this.$config.messageType.SUCCESS, })
                            this.$router.push(`/settings/configuration-manager/${this.configName}`)
                        }
                    }
                }
            },
            async getVersions() {
                this.combinedConfigs = []
                let topologyName = this.filteredTopologies.find(topology => topology.id === this.topologyId).name
                await this.getServerVersions(topologyName)
            },
            byteToInt(value) {
                const k = 1024
                if (Number(value)) {
                    return value
                } else {
                    const val = Number(value.slice(0, -2))
                    const unit = value.substr(-2, 2)
                    const index = this.$config.SIZE_UNITS.findIndex(sUnit => sUnit == unit)
                    return val * Math.pow(k, index)
                }
            },
            setConfigFormValidity(value) {
                this.configFormValidity = value
            },
            isVersion23,
        },
        async created() {
            await this.getAllTopologies()
        },
        destroy() {
            this.$store.commit('toggleEditMode', false)
        },
    }
</script>
