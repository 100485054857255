import Billing from 'pages/Billing.vue'
import Usage from 'pages/billing/Usage.vue'
import Payment from 'pages/billing/Payment.vue'
import HistoricalUsage from 'pages/billing/HistoricalUsage.vue'

export default [
    {
        path: '/billing',
        name: 'Billing',
        component: Billing,
        redirect: '/billing/usage',
        meta: {
            requiresAuth: true,
            title: 'Billing',
        },
        children: [
            {
                path: 'usage',
                name: 'Usage',
                component: Usage,
                meta: {
                    title: 'Usage',
                },
            },
            {
                path: 'history',
                name: 'History',
                component: HistoricalUsage,
                meta: {
                    title: 'History',
                },
            },
            {
                path: 'payment',
                name: 'Payment',
                component: Payment,
                meta: {
                    title: 'Payment',
                },
            }
        ],
    }
]
