<template>
    <svg
        viewBox="0 0 122 130"
        fill="none">
        <path
            d="M93.0179 10.3708C94.3663 11.2433 95.5561 12.3538 96.5575 13.722C97.7572 15.2985 98.6992 17.1923 99.3735 19.3835C100.494 18.4911 101.654 17.6979 102.834 17.0138C103.141 16.8353 103.438 16.6668 103.736 16.518C103.904 16.4288 104.053 16.3495 104.212 16.2801C104.38 16.1909 104.529 16.1214 104.688 16.052C104.856 15.9727 105.005 15.9132 105.164 15.8438C105.322 15.7744 105.481 15.705 105.63 15.6455C106.73 15.2093 107.801 14.892 108.842 14.7135C108.971 14.6937 109.1 14.6739 109.229 14.654C110.3 14.5053 111.321 14.4855 112.293 14.6243C113.373 14.7631 114.385 15.0803 115.297 15.5563L105.788 9.58754L104.995 9.12151C101.852 7.50538 97.6483 7.80283 93.0082 10.3609L93.0179 10.3708Z"
            fill="#D9DAE0"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M13.0345 98.4741C13.0345 100.051 13.2923 101.409 13.7682 102.519C14.2739 103.739 15.0473 104.651 16.009 105.246L16.247 105.385C18.1605 106.426 20.7582 106.247 23.6236 104.592L102.834 58.8543C112.828 53.0838 120.948 39.0344 120.948 27.4737C120.948 21.9015 119.074 17.9355 116.001 15.9625L115.317 15.5559C114.404 15.08 113.393 14.7627 112.312 14.6239C111.341 14.495 110.319 14.505 109.249 14.6537C109.12 14.6735 108.981 14.6835 108.862 14.7132C107.831 14.8917 106.76 15.2089 105.65 15.6452C105.491 15.7047 105.332 15.7741 105.183 15.8435C105.025 15.9129 104.866 15.9724 104.708 16.0517C104.549 16.1211 104.39 16.2005 104.232 16.2798C104.073 16.3591 103.914 16.4384 103.756 16.5177C103.458 16.6664 103.161 16.835 102.854 17.0135C101.674 17.6976 100.514 18.4908 99.3933 19.3831C98.7191 17.1919 97.7771 15.2982 96.5774 13.7217C95.5661 12.3535 94.3762 11.2331 93.0377 10.3705C92.8097 10.2118 92.5817 10.073 92.3536 9.94414C91.3721 9.39882 90.3311 8.96259 89.2206 8.66515C84.6102 7.41587 78.9189 8.38753 72.7519 11.947C60.5367 19.0064 50.156 34.0869 46.4082 48.8898C45.1986 49.3063 43.9394 49.8714 42.6504 50.615C33.2313 56.0583 25.448 68.6204 24.2086 79.8242C24.0302 79.9135 23.8417 80.0225 23.6533 80.1316C17.8135 83.5126 13.0643 91.7221 13.0643 98.4741H13.0345Z"
            fill="white"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M46.3785 48.8897C47.9451 48.3543 49.4323 48.0965 50.8303 48.0767"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M99.3834 19.3926C99.8693 20.979 100.216 22.7141 100.405 24.5979"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M81.725 39.3716L66.6743 48.067"
            stroke="#003545"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M92.3138 33.2637L89.0815 35.1277"
            stroke="#003545"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M64.9391 58.3682L59.5057 61.5012"
            stroke="#2F99A3"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M92.3139 42.5645L71.3142 54.6903"
            stroke="#2F99A3"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M53.4576 73.8452L47.3501 77.3749"
            stroke="#5db459"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M73.6246 62.2056L61.2211 69.3641"
            stroke="#5db459"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M92.3139 51.418L81.8142 57.4859"
            stroke="#5db459"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M1.01782 91.3354C1.01782 94.6271 2.1481 96.967 3.97244 98.1072L4.23024 98.256L16.009 105.246C15.0473 104.651 14.2838 103.729 13.7682 102.519C13.2923 101.409 13.0345 100.051 13.0345 98.4741C13.0345 91.722 17.7738 83.5026 23.6236 80.1315C23.812 80.0225 23.9905 79.9233 24.1789 79.8242C25.4182 68.6204 33.2015 56.0582 42.6206 50.615C43.9096 49.8713 45.1589 49.2963 46.3784 48.8798C50.1263 34.0769 60.507 18.9964 72.7221 11.937C78.8793 8.37753 84.5804 7.40588 89.1908 8.65515C90.3013 8.9526 91.3423 9.38883 92.3239 9.93415L80.4756 2.90453C75.4389 -0.0600206 68.4291 0.336548 60.7054 4.79825C48.4903 11.8576 38.1093 26.9382 34.3615 41.7411C33.1419 42.1575 31.8926 42.7326 30.6037 43.4762C21.1845 48.9195 13.4015 61.4817 12.1621 72.6855C11.9738 72.7847 11.7952 72.8838 11.6069 72.9929C5.75707 76.3738 1.01782 84.5834 1.01782 91.3354Z"
            fill="#D9DAE0"
            stroke="#003545"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M17.8734 128.408L10.9354 123.555C8.28813 122.028 8.28813 118.211 10.9354 116.684L101.161 64.6283C104.839 62.5065 109.38 62.5065 113.059 64.6283L119.997 69.4817C122.644 71.0086 122.644 74.8258 119.997 76.3527L29.7712 128.408C26.0928 130.53 21.5518 130.53 17.8734 128.408Z"
            fill="#EBEBF2" />
    </svg>
</template>

<script>
    export default {}
</script>
