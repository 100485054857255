<style lang="scss" scoped>
// https://stackoverflow.com/a/60552591
// To allow click events inside tooltip
.v-tooltip__content {
    pointer-events: initial;
}
</style>
<template>
    <v-tooltip
        :open-delay="openDelay"
        :close-delay="closeDelay"
        open-on-hover
        :max-width="maxWidth"
        :color="tooltipColor"
        top>
        <template #activator="{ on }">
            <v-icon
                :size="size"
                v-on="on">
                $vuetify.icons.questionCircle
            </v-icon>
        </template>
        <slot name="title">
            <!-- For later if tooltip needs header/title -->
        </slot>
        <slot name="message">
        </slot>
    </v-tooltip>
</template>
<script>
    export default {
        name: 'HelpTooltip',
        props: {
            maxWidth: {
                type: Number,
                default: 300,
            },
            closeDelay: {
                type: Number,
                default: 400,
            },
            openDelay: {
                type: Number,
                default: 400,
            },
            tooltipColor: {
                type: String,
                default: 'rgba(0, 53, 69, 0.9)',
            },
            size: {
                type: Number,
                default: 24,
            },
        },
    }
</script>
