export function getRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let randomString = ''
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        randomString += characters.charAt(randomIndex)
    }

    return randomString
}

export function getUniqueString(length) {
    let result = ''
    let counter = 0
    while (counter < length) {
        result += String.fromCharCode(Math.floor(Math.random() * 26) + 97)
        counter += 1
    }
    return result
}
