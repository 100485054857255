<style lang="scss" scoped>
div.database-processing {
    border-radius: 10px 0px 0px 10px;
    height: 8rem;
    width: 100%;

    &:hover .oversize-icon {
        top: -3.2rem;
        transform: scale(1.1212);
    }
}

div.oversize-icon-box {
    border: 1px solid #e8eef1;
    text-align: center;

    .oversize-icon {
        top: -2.4rem;
        transition: 0.3 all;
    }

    .icon-text {
        margin-top: -2.3rem;
        font-size: 1rem;
        font-weight: 500;
    }
}

.clickable-element:hover {
    cursor: pointer;
}
</style>

<template>
    <v-tooltip
        :max-width="300"
        bottom>
        <template #activator="{ on, attrs }">
            <div
                v-bind="attrs"
                :class="['database-processing', 'oversize-icon-box', 'clickable-element', {'opacity-50percent': type === 'analytical'}]"
                :data-auto="dataAutoPrefix('card')"
                v-on="on"
                @click="onClick">
                <v-icon
                    style="height: 130px"
                    :size="iconSize"
                    class="mr-3 oversize-icon">
                    {{ icon }}
                </v-icon>
                <div
                    class="icon-text"
                    :data-auto="dataAutoPrefix('title')">
                    {{ title }}
                    <TechPreview
                        v-if="type === 'analytical'"
                        :label="$t('comingSoon')" />
                </div>
            </div>
        </template>
        <span>{{ tooltip }}</span>
    </v-tooltip>
</template>
<script>
    import TechPreview from 'components/common/TechPreview.vue'

    const DatabaseTypeCard = {
        name: 'database-type-card',
        components: {
            TechPreview,
        },
        props: {
            title: String,
            icon: String,
            id: String,
            type: String,
            iconSize: Number,
            parentDataAutoPrefix: Function,
            tooltip: String,
        },
        methods: {
            onClick() {
                this.$emit('handleDatabaseRequirementSelection', {id: this.id, type: this.type,})
            },
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(this.type + '-' + string)
            },
        },
    }

    export default DatabaseTypeCard
</script>
