<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <BillingNavigation />
            <router-view />
        </v-col>
    </v-container>
</template>

<script>
    import BillingNavigation from 'components/billing/BillingNavigation.vue'

    export default {
        name: 'Billing',
        components: {
            BillingNavigation,
        },
    }
</script>
