import Copilot from 'pages/Copilot.vue'

export default [
    {
        path: '/copilot',
        name: 'copilot',
        component: Copilot,
        meta: {
            requiresAuth: true,
            title: 'SkyCopilot',
        },
    }
]
