<style lang="scss" scoped>
.control-label {
    font-size: 0.675rem;
}

.silence-banner {
    background-color: $light-yellow;
    border-radius: 7px;

    .v-icon {
        color: $inputs;
    }
}
</style>

<template>
    <div>
        <div class="d-flex align-center justify-space-between mb-2">
            <div style="width: 328px">
                <label class="d-block color text-small-text control-label">
                    {{ $t('context') }}
                </label>
                <SelectServers
                    :preselected="preselectedServices"
                    :filter="allServiceAndServers"
                    @change="onContextChange" />
            </div>
            <div class="ml-2">
                <label class="d-block color text-small-text control-label">
                    {{ $t('time') }}
                </label>
                <date-range-picker
                    :has-custom-range="true"
                    @change="onTimeChange" />
            </div>
            <div class="ml-auto pt-4"></div>
            <router-link
                class="settings ml-2 pt-4"
                to="/settings/policies">
                <v-tooltip top>
                    <template #activator="{ on }">
                        <v-icon
                            size="28"
                            color="primary"
                            v-on="on">
                            $vuetify.icons.settings
                        </v-icon>
                    </template>
                    <span>{{ $t('editAlerts') }}</span>
                </v-tooltip>
            </router-link>
        </div>

        <alerts-table
            :loading="alertsLoading"
            :alerts-total-count="alertsTotalCount"
            :alerts="alerts"
            :no-data-txt="$t('info.noAlerts')"
            @optionsChange="onOptionsChange" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import AlertsTable from 'components/alerts/AlertsTable'
    import SelectServers from 'components/common/SelectServers'

    export default {
        name: 'Alerts',
        components: {
            'alerts-table': AlertsTable,
            SelectServers,
        },
        props: {
            preselectedServices: { type: Array, default: () => [], },
        },
        data() {
            return {
                options: {},
                serverContext: [],
                dateRange: { from: 0, to: 0, },
                allServiceAndServers: [],
            }
        },
        computed: {
            ...mapGetters(['alerts', 'services', 'alertsLoading', 'alertsTotalCount']),
            selectedService() {
                const selectedId = this.$route.params.id
                return this.services.find(service => service.id === selectedId)
            },
        },
        methods: {
            ...mapActions(['fetchServices', 'stopAlertsInterval', 'startAlertsInterval']),

            onContextChange(servers) {
                this.serverContext = servers
                this.fetchAlertsWithParams()
            },
            onTimeChange(range) {
                this.dateRange = range
                this.fetchAlertsWithParams()
            },
            onOptionsChange(options) {
                this.options = options
                this.fetchAlertsWithParams()
            },
            async setAllServiceAndServers() {
                if (!this.services?.length) {
                    await this.fetchServices()
                }
                const serverList = []
                this.services.forEach(({ name, servers, }) => {
                    servers.forEach(server => {
                        serverList.push({
                            server: server.name,
                            serverDataSourceId: server.dataSourceId,
                            service: name,
                        })
                    })
                })
                this.allServiceAndServers = serverList
            },
            generateOrderBy(sortBy, sortDesc) {
                if (!sortBy && !sortDesc) return
                let sort = ''
                for (let i = 0; i < sortBy.length; i++) {
                    sort += `${sortBy[i]}.${sortDesc[0] ? 'desc' : 'asc'}${sortBy.length > 1 ? ',' : ''}`
                }
                return sort
            },
            async fetchAlertsWithParams() {
                if (!this.serverContext.length || this.dateRange.from === 0 || !this.options?.itemsPerPage) { return }

                const { sortBy, sortDesc, page, itemsPerPage, } = this.options
                const params = {
                    limit: itemsPerPage > 0 ? itemsPerPage : null,
                    offset: itemsPerPage > 0 ? (page - 1) * itemsPerPage : null,
                    fromDate: new Date(this.dateRange.from * 1000),
                    toDate: new Date(this.dateRange.to * 1000),
                    serverContext: this.serverContext.toString(),
                    orderBy: this.generateOrderBy(sortBy, sortDesc) || null,
                }

                await this.startAlertsInterval({ seconds: this.$config.fetchAlertsInterval, params, })
            },
        },
        mounted() {
            this.setAllServiceAndServers()
        },
        destroy() {
            this.stopAlertsInterval()
        },
    }
</script>
