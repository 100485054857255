import { getBackups, getSchedules, getRestores } from 'services/backups'
import to from 'await-to-js'
import common from 'config/common'
import i18n from 'plugins/i18n'

export default {
    state: {
        backupList: {},
        restoreList: [],
        scheduleList: [],
        isFetchingBackups: false,
        isFetchingRestores: false,
        isFetchingSchedules: false,
    },
    mutations: {
        setBackups(state, payload) {
            state.backupList = payload
            // const t = payload.backups
            // state.backupList = {...payload, backups: [...t, ...t,...t],}
        },
        setSchedules(state, payload) {
            state.scheduleList = payload
        },
        setRestores(state, payload) {
            state.restoreList = payload
        },
        setFetchingStatus(state, payload) {
            state[payload?.key] = payload?.value
        },
    },
    actions: {
        async fetchBackups({commit,}, params) {
            try {
                commit('setFetchingStatus', {key: 'isFetchingBackups', value: true,})
                const [error, response] = await to(getBackups(params))
                if(error) {
                    commit('showMessage', { text: error?.response?.data?.errors[0]?.message ?? i18n.t('errors.fetchBackupError', ['backups']), type: common.messageType.ERROR, })
                } else {
                    commit('setBackups', response)
                }
            } catch {
                commit('showMessage', { text: i18n.t('errors.fetchBackupError', ['backups']), type: common.messageType.ERROR, })
            } finally {
                commit('setFetchingStatus', {key: 'isFetchingBackups', value: false,})
            }
        },
        async fetchSchedules({commit,}, params) {
            try {
                commit('setFetchingStatus', {key: 'isFetchingSchedules', value: true,})
                const [error, response] = await to(getSchedules(params))
                if(error) {
                    commit('showMessage', { text:  error?.response?.data?.errors[0]?.message ?? i18n.t('errors.fetchBackupError', ['schedules']), type: common.messageType.ERROR, })
                } else {
                    commit('setSchedules', response)
                }
            } catch {
                commit('showMessage', { text: i18n.t('errors.fetchBackupError', ['schedules']), type: common.messageType.ERROR, })
            } finally {
                commit('setFetchingStatus', {key: 'isFetchingSchedules', value: false,})
            }
        },
        async fetchRestores({commit,}, params) {
            try {
                commit('setFetchingStatus', {key: 'isFetchingRestores', value: true,})
                const [error, response] = await to(getRestores(params))
                if(error) {
                    commit('showMessage', { text:  error?.response?.data?.errors[0]?.message ?? i18n.t('errors.fetchBackupError', ['restores']), type: common.messageType.ERROR, })
                } else {
                    commit('setRestores', response)
                }
            } catch {
                commit('showMessage', { text: i18n.t('errors.fetchBackupError', ['restores']), type: common.messageType.ERROR, })
            } finally {
                commit('setFetchingStatus', {key: 'isFetchingRestores', value: false,})
            }
        },
    },
    getters: {
        backupData: (state) => state.backupList,
        schedulesData: (state) => state.scheduleList,
        restoresData: (state) => state.restoreList,
        isFetchingBackups: (state) => state.isFetchingBackups,
        isFetchingSchedules: (state) => state.isFetchingSchedules,
        isFetchingRestores: (state) => state.isFetchingRestores,
    },
}
