
<style>
.grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 12px;
}
</style>
<template>
    <div class="grid">
        <template
            v-for="(key, idx) in keysToShow">
            <span
                :key="idx"
                class="font-weight-bold">
                {{ keyNameMap.get(key) }}
            </span>
            <span :key="key + idx">
                {{ getValue(key) }}
            </span>
        </template>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        name: 'Organisation',
        data() {
            return {
                keysToShow: ['id', 'name', 'tier', 'created_by', 'created_on', 'current_owner'],
                keyNameMap: new Map([
                    ['id', this.$t('orgId')],
                    ['name', this.$t('name')],
                    ['tier', this.$tc('tierUpgrade.tier', 1)],
                    ['created_by', this.$t('createdBy')],
                    ['created_on', this.$t('createdDate')],
                    ['current_owner', this.$tc('owner', 0)]
                ]),
            }
        },
        computed: {
            ...mapGetters(['orgData', 'users']),
        },
        methods: {
            ...mapActions(['fetchOrgDetails', 'getUsers']),
            getValue(key) {
                let value = this.orgData?.[key] ?? ''
                switch(key) {
                    case 'tier':
                        return value?.charAt(0)?.toUpperCase() + value?.slice(1)
                    case 'created_by':
                    case 'current_owner':
                        let firstName = this.users?.find(user => user.id === value)?.first_name
                        let lastName = this.users?.find(user => user.id === value)?.last_name
                        return firstName?.length > 0 ? `${firstName} ${lastName}` : value
                    case 'created_on':
                        return this.$moment(value*1000).format('YYYY-MM-DD (ddd)')
                    default:
                        return value
                }
            },
        },
        mounted() {
            if(!Object.keys(this.orgData)?.length) {
                this.fetchOrgDetails()
            }
            if(!this.users?.length) {
                this.getUsers()
            }
        },
    }
</script>
