<style lang="scss" scoped>
    .alert-channel-name--label {
        font-size: 0.6875rem;
    }
    .alert-channel-name--text {
        font-size: 1.875rem;
        color: $deep-ocean;
        font-style: normal;
        font-weight: 350;
    }
    ::v-deep .alert-channel-name.v-text-field .v-text-field__details {
        padding-left: 0;
        padding-right: 0;
        font-weight: 400;
    }
    ::v-deep .categories.v-text-field .v-text-field__details {
        top: 60px !important
    }
    ::v-deep .alert-channel-name.v-text-field--outlined {
        & input {
            @extend .alert-channel-name--text;
            max-height: unset;
        }
    }
    .fields-header {
        color: $deep-ocean;
        font-style: normal;
        font-size: 0.875rem;
    }
    .field-divider {
        margin-bottom: 5px;
        margin-left: -36px;
        flex: 0 0 calc(100% + 72px);
    }
    .v-btn {
        &.cancel {
            min-width: 0;
        }
        &.v-btn--depressed {
            min-width: 17% !important;
        }
    }
    .field-label {
        font-size: 0.675rem;
    }
</style>
<template>
    <base-dialog
        ref="alertDialogRef"
        v-model="isDialogOpen"
        :on-save="saveChannel"
        :has-changed="hasChanged"
        :on-cancel="onCancel"
        :on-close="clearState"
        :min-width="776"
        :max-width="776">
        <template #body>
            <v-card
                flat
                height="400px">
                <div
                    class="d-inline-block"
                    :style="{ width: isChannelNameEditable || isCreateMode ? '100%' : 'unset' }">
                    <label class="d-block text-capitalize color text-small-text field-label">
                        {{ $t('channelName') }}
                    </label>
                    <v-text-field
                        v-if="isChannelNameEditable || isCreateMode"
                        v-model="channelData.channel_name"
                        class="alert-channel-name std v-text-field--with-bottom-details"
                        type="text"
                        data-test-id="alertChannelName"
                        height="38"
                        :rules="rules.channelName"
                        :autofocus="isCreateMode"
                        outlined
                        required />
                    <div
                        v-else
                        class="d-flex align-center pb-3">
                        <truncate-string
                            class="ma-0 d-inline-block alert-channel-name--text"
                            max-width="720px"
                            :text="channelData.channel_name ? channelData.channel_name : ''"
                            :dot-count="3"
                            :single-char-with-in-px="15.65" />
                        <v-icon
                            v-if="!isChannelNameEditable"
                            color="primary"
                            size="18"
                            class="ml-4"
                            @click="isChannelNameEditable = true">
                            $vuetify.icons.edit
                        </v-icon>
                    </div>
                </div>
                <v-col
                    cols="12"
                    class="pa-0">
                    <label class="d-block text-capitalize color text-small-text field-label">
                        {{ $t('to') }}
                    </label>
                    <emails-input
                        data-test-id="alertChannelEmailTo"
                        :default-emails="defEmails"
                        :label="$t('info.startTyping')"
                        @on-change="onEmailChanged" />
                    <div class="mt-5">
                        <label class="d-block text-capitalize color text-small-text field-label">
                            {{ $t('categories') }}
                        </label>
                        <v-select
                            v-model="channelData.categories"
                            class="categories"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                            :elevation="200"
                            outlined
                            dense
                            attach
                            required
                            small-chip
                            :rules="rules.categories"
                            :items="items"
                            multiple>
                            <template #selection="{ item }">
                                <v-chip
                                    class="my-1"
                                    small>
                                    <span>{{ item }}</span>
                                </v-chip>
                            </template>
                        </v-select>
                    </div>
                </v-col>
            </v-card>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                class="save"
                :disabled="isSaveDisabled"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t('save') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>
<script>
    import EmailsInput from './EmailsInput'
    import { mapActions } from 'vuex'
    export default {
        name: 'NotificationChannelModal',
        components: {
            'emails-input': EmailsInput,
        },
        data () {
            return {
                channelNameLabel: 'Channel name',
                isDialogOpen: false,
                isChannelNameEditable: false,
                viewMode: 'create',
                initialChannel: null,
                channelData: null,
                rules: {
                    channelName: [(val) => this.validateChannelName(val)],
                    categories: [(val) => this.validateCategories(val)],
                    subject: [(val) => !!val || this.$t('errors.fieldRequired', { field: this.$t('subject'), })],
                },
                items: this.$config.ALL_CATEGORIES,
            }
        },
        computed: {
            isEditMode: function () { return this.viewMode === 'edit' },
            isCreateMode: function () { return this.viewMode === 'create' },
            hasChanged: function () {
                return !this.$lodash.isEqual(this.initialChannel, this.channelData)
            },
            defEmails: function () {
                if (this.channelData.receivers) return this.channelData.receivers.map(receiver => receiver.email)
                return []
            },
            isFormValid: function () {
                return this.$refs.alertDialogRef.isFormValid
            },
            isSaveDisabled: function () {
                return !this.hasChanged || !this.isFormValid
            },
        },
        methods: {
            ...mapActions(['editChannel', 'addChannel']),
            open (channel) {
                this.isDialogOpen = true
                if (channel) {
                    this.channelData = this.$help.objects.clone(channel)
                    this.initialChannel = this.$help.objects.clone(channel)
                    this.viewMode = 'edit'
                } else {
                    this.viewMode = 'create'
                    this.initialChannel = this.$help.objects.clone(this.channelData)
                }
            },
            clearState () {
                this.clearChannelData()
                this.viewMode = ''
                this.isChannelNameEditable = false
            },
            clearChannelData () {
                this.channelData = {}
            },
            onCancel () {
                this.clearState()
            },
            validateChannelName (v) {
                if (!v) return this.$t('errors.fieldRequired', { field: this.channelNameLabel, })
                return true
            },
            validateCategories (v) {
                if (!v.length) return this.$t('errors.fieldRequired', { field: 'Category', })
                return true
            },
            onEmailChanged (emails) {
                this.channelData.receivers = emails.map(email => { return {email,} })
            },
            async saveChannel () {
                if (this.isEditMode) {
                    this.editChannel(this.channelData)
                } else {
                    this.addChannel(this.channelData)
                }
                this.clearState()
                this.isDialogOpen = false
            },
        },
        created () {
            this.clearChannelData()
        },
    }
</script>
