import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import icons from 'icons'
import i18n from 'plugins/i18n'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: { ...icons, },
    },
    theme: {
        themes: {
            light: {
                primary: '#6353ef',
                secondary: '#363a4a',
                accent: '#5db459',
                ['accent-dark']: '#0b718c',
                success: '#7dd012',
                error: '#eb5757',
                warning: '#f59d34',
                info: '#4998c8',
                anchor: '#4998c8',
                navigation: '#424F62',
                ['deep-ocean']: '#003545',
                ['blue-azure']: '#0e6488',
                ['grayed-out']: '#A6A4A6',
            },
        },
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
})
