<style lang="scss" scoped>
.mw-50 {
    min-width: 50%
}
</style>
<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="action"
        :on-cancel="doCancel"
        :min-width="600"
        :max-width="600">
        <template #title>
            <div class="font-weight-light text-h5 pb-6">
                {{ dialogTitle }}
            </div>
        </template>
        <template #body>
            <div v-if="dialogType === $config.DIALOG_TYPE.INVITE">
                <div class="pb-5">
                    {{ $t('userManagementTab.usersWillSee') }}
                </div>
                <div
                    v-for="(user, index) in usersList"
                    :key="index"
                    class="d-flex">
                    <v-text-field
                        v-model="user.email"
                        :label="!index ? $t('email') : ''"
                        class="config-input mw-50"
                        outlined
                        dense
                        required
                        hide-details
                        @change="addNewRow(user)" />
                    <v-select
                        v-model="user.role"
                        :items="roles"
                        :label="!index ? $t('role') : ''"
                        class="pl-2"
                        item-text="name"
                        item-value="id"
                        dense
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                            contentClass: 'std--dropdown-list max-height-330',
                        }"
                        outlined
                        hide-details
                        required
                        @change="addNewRow(user)" />
                </div>
            </div>
            <div
                v-if="dialogType === $config.DIALOG_TYPE.EDIT"
                class="d-flex pt-4">
                <v-text-field
                    v-model="currentUser.email"
                    :label="$t('email')"
                    class="config-input mw-50"
                    disabled
                    outlined
                    dense
                    required
                    hide-details />
                <v-select
                    v-model="currentUser.role"
                    :items="roles"
                    :label="$t('role')"
                    class="pl-2"
                    item-text="name"
                    item-value="id"
                    dense
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        contentClass: 'std--dropdown-list max-height-330',
                    }"
                    outlined
                    required />
            </div>
            <div
                v-if="dialogType === $config.DIALOG_TYPE.RESEND"
                class="d-flex pt-4">
                <v-text-field
                    v-model="currentUser.email"
                    :label="$t('email')"
                    class="config-input mw-50"
                    disabled
                    outlined
                    dense
                    required
                    hide-details />
                <v-select
                    v-model="currentUser.role"
                    :items="roles"
                    :label="$t('role')"
                    class="pl-2"
                    item-text="name"
                    item-value="id"
                    dense
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        contentClass: 'std--dropdown-list max-height-330',
                    }"
                    outlined
                    required />
            </div>
            <div v-if="dialogType === $config.DIALOG_TYPE.DEACTIVATE">
                <i18n
                    path="userManagementTab.aboutToDeactivate"
                    tag="p">
                    <strong place="userEmail">{{ currentUser.email }}</strong>
                </i18n>
            </div>
            <div v-if="dialogType === $config.DIALOG_TYPE.ACTIVATE">
                <i18n
                    path="userManagementTab.aboutToActivate"
                    tag="p">
                    <strong place="userEmail">{{ currentUser.email }}</strong>
                </i18n>
            </div>
            <div v-if="dialogType === $config.DIALOG_TYPE.CANCEL">
                <i18n
                    path="userManagementTab.linkWillBeDisabled"
                    tag="p">
                    <strong place="userEmail">{{ currentUser.email }}</strong>
                </i18n>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                v-if="dialogType !== $config.DIALOG_TYPE.CANCEL"
                color="primary"
                depressed
                rounded
                outlined
                class="cancel"
                @click="cancel">
                <span class="text-none text-body-1 px-3">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                :color="[$config.DIALOG_TYPE.DEACTIVATE, $config.DIALOG_TYPE.CANCEL].includes(dialogType) ? 'error' : 'primary'"
                depressed
                rounded
                :disabled="disableAction"
                @click="save">
                <span class="text-capitalize text-body-1 px-3"> {{ actionText() }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    let userObject = { email: '', role: '', }
    export default {
        name: 'UserActionsModal',
        props: {
            dialogType: {
                type: String,
                required: true,
            },
            selectedUser: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                isDialogOpen: false,
                usersList: [this.$lodash.cloneDeep(userObject)],
            }
        },
        computed: {
            ...mapGetters(['inviteUserResponse', 'roles', 'cancelInviteResponse', 'updateUserResponse', 'editUserResponse']),
            currentUser() {
                return { ...this.$lodash.cloneDeep(this.selectedUser), ...{ role: this.selectedUser.roles[0], }, }
            },
            dialogTitle() {
                let title
                switch (this.dialogType) {
                    case this.$config.DIALOG_TYPE.INVITE:
                        title = this.$t('userManagementTab.inviteUsers')
                        break
                    case this.$config.DIALOG_TYPE.EDIT:
                        title = this.$t('userManagementTab.editUser')
                        break
                    case this.$config.DIALOG_TYPE.DEACTIVATE:
                        title = this.$t('userManagementTab.deactivateUser')
                        break
                    case this.$config.DIALOG_TYPE.CREATE:
                        title = this.$t('userManagementTab.createNewTeam')
                        break
                    case this.$config.DIALOG_TYPE.CANCEL:
                        title = `${this.$t('userManagementTab.cancelInvitation')}?`
                        break
                    case this.$config.DIALOG_TYPE.ACTIVATE:
                        title = this.$t('userManagementTab.activateUser')
                        break
                    case this.$config.DIALOG_TYPE.RESEND:
                        title = this.$t('userManagementTab.resendInvitation')
                        break
                }
                return title
            },
            disableAction() {
                if (this.dialogType === this.$config.DIALOG_TYPE.INVITE) {
                    const listLength = this.usersList.length
                    if (listLength > 1) {
                        // No need to check last row
                        const foundEmpty = this.usersList.find((user, index) => index !== listLength - 1 && (!user.email || !user.role))
                        return !!foundEmpty
                    }
                    return true
                }
                return false
            },
        },
        methods: {
            ...mapActions(['inviteUser', 'cancelInvite', 'updateUser', 'editUser']),
            actionText() {
                switch (this.dialogType) {
                    case this.$config.DIALOG_TYPE.INVITE:
                        return `${this.$t('add')} ${this.$tc('user', this.usersList.length)}`
                    case this.$config.DIALOG_TYPE.DEACTIVATE:
                        return this.$t('deactivate')
                    case this.$config.DIALOG_TYPE.ACTIVATE:
                        return this.$t('activate')
                    case this.$config.DIALOG_TYPE.CANCEL:
                        return this.$t('userManagementTab.cancelInvitation')
                    case this.$config.DIALOG_TYPE.RESEND:
                        return this.$t('resend')
                    default:
                        return this.$t('save')
                }
            },
            open() {
                this.isDialogOpen = true
            },
            doCancel() {
                this.isDialogOpen = false
            },
            addNewRow() {
                // Add a new row if values are entered in last row
                let lastUser = this.usersList.at(-1)
                if (lastUser.email && lastUser.role) {
                    this.usersList.push(this.$lodash.cloneDeep(userObject))
                }
            },
            async action() {
                switch (this.dialogType) {
                    case this.$config.DIALOG_TYPE.INVITE:
                    case this.$config.DIALOG_TYPE.RESEND:
                        let inviteArray = []
                        if (this.dialogType === this.$config.DIALOG_TYPE.RESEND) {
                            inviteArray.push({
                                email: this.currentUser.email,
                                roles: [this.currentUser.role],
                            })
                        } else {
                            this.usersList.forEach(user => {
                                if (user.email && user.role) {
                                    inviteArray.push({
                                        email: user.email,
                                        roles: [user.role],
                                    })
                                }
                            })
                        }
                        await this.inviteUser({ inviteArray, })
                        if (this.inviteUserResponse) this.$store.commit('showMessage', { text: this.$t('success.inviteUser'), type: this.$config.messageType.SUCCESS, })
                        break
                    case this.$config.DIALOG_TYPE.CANCEL:
                        await this.cancelInvite({ inviteeId: this.currentUser.id, })
                        if (this.cancelInviteResponse) this.$store.commit('showMessage', { text: this.$t('success.cancelInvite'), type: this.$config.messageType.SUCCESS, })
                        break
                    case this.$config.DIALOG_TYPE.DEACTIVATE:
                    case this.$config.DIALOG_TYPE.ACTIVATE:
                        await this.updateUser({
                            userId: this.currentUser.id, userObject: {
                                'activate': !this.currentUser.is_active,
                                'email': this.currentUser.email,
                                'first_name': this.currentUser.first_name,
                                'last_name': this.currentUser.last_name,
                            },
                        })
                        if (this.updateUserResponse) this.$store.commit('showMessage', { text: this.$t('success.updateUser'), type: this.$config.messageType.SUCCESS, })
                        break
                    case this.$config.DIALOG_TYPE.EDIT:
                        await this.editUser({ userId: this.currentUser.id, detachRoleId: this.currentUser.roles[0], attachRoleId: this.currentUser.role, })
                        if (this.editUserResponse) this.$store.commit('showMessage', { text: this.$t('success.updateUser'), type: this.$config.messageType.SUCCESS, })
                        break
                }

            },
        },
    }
</script>
