export default Object.freeze({
    regions: {
        america: 'Americas',
        emea: 'EMEA',
        apac: 'APAC',
    },
    iops: {
        io1: 'io1',
        gp2: 'gp2',
        gp3: 'gp3',
    },
    cloudProvider: {
        AWS: 'aws',
        GCP : 'gcp',
        AZURE : 'azure',
    },
    DEFAULT_ARCHITECTURE: 'amd64',
    ARCH_AMD: 'amd64',
    ARCH_ARM: 'arm64',
    INSTANCE_SIZE_TYPE: {
        SERVER: 'server',
        PROXY: 'proxy',
    },
    DEFAULT_REGIONS_LIST: {
        aws: ['us-west-2', 'eu-west-3', 'eu-west-2'],
        gcp: ['us-central1', 'europe-west2', 'europe-west9'],
        azure: ['eastus', 'northeurope'],
    },
})
