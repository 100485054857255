import { fetchServiceCredentials } from 'services/dbServices'

export default {
    state: {
        serviceId: null,
        isConnectDialogOpen: false,
        isFetchingServiceCredentials: false,
        host: null,
        username: null,
        password: null,
        authChainURL: process.env.VUE_APP_CHAIN_URL,
    },
    mutations: {
        setServiceCredentialsDialog (state, value) {
            state.isConnectDialogOpen = value
        },
        setServiceCredentialsStatus(state, status) {
            state.isFetchingServiceCredentials = status
        },
        setServiceCredentials (state, { serviceId, host, username, password, }) {
            state.serviceId = serviceId
            state.host = host
            state.username = username
            state.password = password
        },
        setServiceId (state, value) {
            state.serviceId = value
        },
    },
    actions: {
        async openServiceCredentials ({ commit, }, serviceId) {
            commit('setServiceCredentialsStatus', true)

            try {
                const credentialsData = await fetchServiceCredentials(serviceId)
                commit('setServiceCredentials', {
                    serviceId,
                    ...credentialsData,
                })
                commit('setServiceCredentialsDialog', true)
            } catch (_) { /* */ }

            commit('setServiceCredentialsStatus', false)
        },
    },
    getters: {
        isFetchingServiceCredentials: (state) => state.isFetchingServiceCredentials,
    },
}
