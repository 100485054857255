<template>
    <v-expansion-panel
        id="topologyPanel"
        :data-auto="dataAutoPrefix()">
        <AccordianPanelHeader
            :title="$t('launchService.type')"
            :parent-data-auto-prefix="dataAutoPrefix">
            <!-- Tooltip goes here -->
            <template #tooltip-content>
                <p>{{ $t('launchService.topologyInfo') }}</p>
                <a
                    rel="noopener noreferrer"
                    class="text-capitalize"
                    target="_blank"
                    href="https://r.mariadb.com/snr-launch-type-topology">{{ $t('learn-more') }}</a>
            </template>
        </AccordianPanelHeader>
        <v-expansion-panel-content :data-auto="dataAutoPrefix('content')">
            <div
                v-if="!serviceTypes.length"
                class="text-center pt-3">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </div>
            <v-row
                v-else
                class="d-none">
                <v-col
                    cols="auto mt-8 wrapper-container"
                    :data-auto="dataAutoPrefix('content-needDatabase')">
                    {{ $t('launchService.needDatabase') }}
                </v-col>
            </v-row>
            <v-row
                v-if="!isNeedForDBSelected"
                class="mt-12 d-none"
                align="center"
                justify="center">
                <v-col
                    v-for="(serviceDetails,index) in serviceTypes"
                    :key="serviceDetails.id"
                    :class="index%2 ? 'pl-0' :'pr-0'"
                    cols="6">
                    <DatabaseTypeCard
                        :id="serviceDetails.id"
                        :title="$t(`dbUseType.${serviceDetails.type}`)"
                        :icon="serviceDetails.icon"
                        :type="serviceDetails.name"
                        :icon-size="index%2 ? 132 :129"
                        :tooltip="serviceDetails.tooltip"
                        :parent-data-auto-prefix="dataAutoPrefix"
                        @handleDatabaseRequirementSelection="
                            handleDatabaseRequirementSelection
                        " />
                </v-col>
            </v-row>
            <v-row
                v-else
                class="minimized-icon-boxes d-none">
                <v-col
                    v-for="(serviceDetails,index) in serviceTypes"
                    :key="serviceDetails.id"
                    :class="index%2 ? 'pl-0' :'pr-0'"
                    cols="6">
                    <MinimizedDatabaseTypeCard
                        :id="serviceDetails.id"
                        :title="$t(`dbUseType.${serviceDetails.type}`)"
                        :icon="serviceDetails.icon"
                        :type="serviceDetails.name"
                        :icon-size="40"
                        :parent-data-auto-prefix="dataAutoPrefix"
                        :is-transaction-type="isTransactionType"
                        :is-need-for-d-b-selected="isNeedForDBSelected"
                        :selected-id="selectedId"
                        :tooltip="serviceDetails.tooltip"
                        :is-position-right="Boolean(!index%2)"
                        @handleDatabaseRequirementSelection="
                            handleDatabaseRequirementSelection
                        " />
                </v-col>
            </v-row>
            <!-- <v-row v-if="isNeedForDBSelected">
                <v-col cols="auto mt-4 pa-0 wrapper-container">
                    {{ $t('launchService.withTopology') }}
                </v-col>
            </v-row> -->
            <v-expand-transition>
                <v-row
                    v-if="isNeedForDBSelected"
                    align="center"
                    justify="center"
                    class="topology-wrapper">
                    <v-col cols="mt-3 mb-3 wrapper-container">
                        <div
                            v-if="isTopologyFetching"
                            class="text-center">
                            <v-progress-circular
                                size="20"
                                width="7"
                                color="primary"
                                indeterminate />
                        </div>
                        <v-row v-else>
                            <TopologyCard
                                v-for="topologyDetails in matchedTopologies"
                                :id="topologyDetails.id"
                                :key="topologyDetails.type"
                                :title="getTopologyTitle(topologyDetails)"
                                :server-type="topologyDetails.serverType"
                                :type="topologyDetails.name"
                                :description="topologyDetails.description"
                                :features="topologyDetails.features"
                                :icon="topologyDetails.icon"
                                :icon-size="topologyDetails.iconSize"
                                :current-topology-id="currentTopologyId"
                                :col-span="isTransactionType ? 6 : 12"
                                :is-tech-preview="topologyDetails.isTechPreview"
                                @handleTopologySelection="
                                    handleTopologySelection
                                " />
                        </v-row>
                    </v-col>
                </v-row>
            </v-expand-transition>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import TopologyCard from 'components/launchService/TopologyCard.vue'
    import DatabaseTypeCard from 'components/launchService/DatabaseTypeCard.vue'
    import MinimizedDatabaseTypeCard from 'components/launchService/MinimizedDatabaseTypeCard.vue'
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'

    export default {
        name: 'TopologySelection',
        components: {
            TopologyCard,
            DatabaseTypeCard,
            MinimizedDatabaseTypeCard,
            AccordianPanelHeader,
        },
        props: {
            isNeedForDBSelected: Boolean,
            isTopologySelected: Boolean,
            parentDataAutoPrefix: {
                type: Function,
                default: () => {},
            },
        },
        data: function () {
            return {
                isTransactionType: false,
                currentTopologyId: null,
                selectedId: null,
                matchedTopologies: [],
            }
        },
        computed:{
            ...mapGetters(['serviceTypes','topologiesDetails', 'isTopologyFetching', 'isServiceTypesFetching']),
        },
        methods: {
            ...mapActions(['fetchServiceTypes','fetchTopologyDetails']),
            handleDatabaseRequirementSelection ({ id, type, }) {
                if (type === 'analytical') {  // no action for Analytics type because it is disabled
                    return
                }
                const selectedService = this.serviceTypes.find(service =>
                    service.id === id
                )
                this.matchedTopologies = this.topologiesDetails.filter(topology=> topology.service_type === selectedService.name)
                this.selectedId = id
                this.currentTopologyId = null
                this.isTransactionType = selectedService.name.toLowerCase() === this.$config.TRANSACTIONAL
                this.$emit('onRequirementOfDBSelect', selectedService)
            },
            handleTopologySelection (id) {
                this.currentTopologyId = id
                const selectedTopology = this.topologiesDetails.find(topology =>
                    topology.id === id
                )
                this.$emit('onTopologySelect', selectedTopology)
            },
            dataAutoPrefix (string) {
                return this.parentDataAutoPrefix(
                    `topologyPanel${string ? '-' + string : ''}`
                )
            },
            getTopologyTitle(topology) {
                return topology.title
            },
        },
        async created () {
            if (this.$lodash.isEmpty(this.serviceTypes)) {
                await this.fetchServiceTypes()
            }
            if (this.$lodash.isEmpty(this.topologiesDetails)) {
                await this.fetchTopologyDetails()
            }
            // Preselect options
            const transactional = this.serviceTypes.find(service => service.name === 'transactional')
            if (transactional) {
                this.handleDatabaseRequirementSelection(transactional)
                const esSingle = this.matchedTopologies.find(topology => topology.name === 'es-replica')
                if (esSingle) {
                    this.handleTopologySelection(esSingle.id)
                }
            }
        },
    }
</script>
