<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.0072 18.1135L13.8031 18.1917V18.4102V32.1487C13.8031 40.9527 20.9855 48.0863 29.8411 48.0863C38.6967 48.0863 45.8791 40.9527 45.8791 32.1487V18.4102V18.1917L45.675 18.1135L29.9547 12.0946L29.8411 12.0511L29.7275 12.0946L14.0072 18.1135ZM27.3714 33.0276L27.5953 33.2502L27.8193 33.0276L35.2194 25.6747C35.5321 25.3641 36.0368 25.3641 36.3494 25.6747C36.6648 25.9882 36.6648 26.4985 36.3494 26.8119L28.2671 34.8425C27.8954 35.2119 27.2953 35.2119 26.9235 34.8425L23.3328 31.2748C23.0174 30.9613 23.0174 30.451 23.3328 30.1376C23.6454 29.827 24.1502 29.827 24.4628 30.1376L27.3714 33.0276ZM47.4895 32.1487C47.4895 41.8303 39.59 49.6823 29.8411 49.6823C20.0922 49.6823 12.1927 41.8303 12.1927 32.1487V17.0973L29.8411 10.3402L47.4895 17.0973V32.1487Z"
            fill="#4998c8"
            stroke="#4998c8"
            stroke-width="0.635417" />
    </svg>
</template>

<script>
    export default {}
</script>
