<style lang="scss" scoped>

</style>
<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <SettingHeader
                :header-text="$t('')"
                class="mb-10" />

            <Organisation />
        </v-col>
    </v-container>
</template>
<script>

    import Organisation from 'components/organisation/Organisation.vue'
    import SettingHeader from 'pages/settings/SettingHeader.vue'

    export default {
        components: { SettingHeader, Organisation, },
    }
</script>
