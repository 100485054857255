import Backups from 'pages/Backups'
import ServiceBackups from '../components/backups/ServiceBackups.vue'
import ServiceRestores from '../components/backups/ServiceRestores.vue'
import ServiceSchedules from '../components/backups/ServiceSchedules.vue'

export default [
    {
        path: '/backups',
        name: 'backups',
        component: Backups,
        redirect: '/backups/service-backups',
        meta: {
            requiresAuth: true,
            title: 'Backups',
        },
        children: [
            {
                path: 'service-backups',
                name: 'service-backups',
                component: ServiceBackups,
                meta: {
                    title: 'Backups',
                },
            },
            {
                path: 'service-restores',
                name: 'service-restores',
                component: ServiceRestores,
                meta: {
                    title: 'Restores',
                },
            },
            {
                path: 'service-schedules',
                name: 'service-schedules',
                component: ServiceSchedules,
                meta: {
                    title: 'Schedules',
                },
            }
        ],
    }
]
