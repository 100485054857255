export function getFlagIconForRegion (regionName) {
    switch (regionName) {
    case 'af-south-1':
        return 'southAfrica'
    case 'ap-east-1':
    case 'asia-east2':
        return 'hongkong'
    case 'ap-southeast-3':
    case 'asia-southeast2':
        return 'indonesia'
    case 'ap-south-1':
    case 'asia-south1':
        return 'india'
    case 'ap-northeast-3':
    case 'asia-northeast1':
    case 'asia-northeast2':
    case 'ap-northeast-1':
        return 'japan'
    case 'ap-northeast-2':
    case 'asia-northeast3':
        return 'southKorea'
    case 'ap-southeast-1':
    case 'asia-southeast1':
    case 'southeastasia':
        return 'singapore'
    case 'ap-southeast-2':
    case 'australia-southeast1':
        return 'australia'
    case 'europe-north1':
        return 'finland'
    case 'ca-central-1':
    case 'northamerica-northeast1':
        return 'canada'
    case 'eu-central-1':
    case 'europe-west3':
    case 'germanywestcentral':
        return 'germany'
    case 'eu-west-1':
    case 'northeurope':
        return 'ireland'
    case 'europe-west1':
        return 'belgium'
    case 'eu-west-2':
    case 'europe-west2':
    case 'uksouth':
        return 'uk'
    case 'europe-west4':
        return 'netherlands'
    case 'europe-west6':
        return 'switzerland'
    case 'eu-south-1':
        return 'italy'
    case 'eu-west-3':
    case 'europe-west9':
    case 'francecentral':
        return 'france'
    case 'eu-north-1':
        return 'sweden'
    case 'me-south-1':
        return 'bahrain'
    case 'sa-east-1':
    case 'southamerica-east1':
        return 'brazil'
    case 'asia-east1':
        return 'taiwan'
    default:
        return 'us'
    }
}
