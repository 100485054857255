<style lang="scss" scoped>
    .backup-navigation {
        a {
            text-decoration: none;
            height: 40px;
            line-height: 40px;
            display: block;
            font-size: 14px;
        }
        .router-link-active {
            border-bottom: 3px solid $primary;
            font-weight: bold;
        }
    }
</style>

<template>
    <v-container
        fluid
        class="py-0 px-0">
        <v-row>
            <v-col
                cols="8"
                class="mb-2 view-headline color text-navigation font-weight-light">
                {{ $tc('backups.backup', 2) }}
                <v-tooltip
                    v-if="isInfoVisible"
                    :max-width="400"
                    open-delay="400"
                    right>
                    <template #activator="{ on }">
                        <v-icon
                            size="26"
                            class="ml-1"
                            v-on="on">
                            mdi-information-outline
                        </v-icon>
                    </template>
                    <div class="text-center">
                        {{ $t('info.backupInfo') }}
                    </div>
                </v-tooltip>
            </v-col>
            <v-col
                cols="4"
                class="px-0 ">
                <div class="backup-navigation">
                    <div class="route-tabs d-flex justify-end">
                        <router-link
                            v-for="(item, idx) in tabList"
                            :key="idx"
                            class="mx-2 px-3"
                            :to="`/backups/${item.key}`">
                            {{ item.value }}
                        </router-link>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'BackupNavigation',
        data() {
            return {
                isInfoVisible: localStorage.getItem('isBackupInfoDissmissed'),
                tabList:
                    [
                        {
                            key: 'service-backups',
                            value: this.$tc('backups.backup', 2),
                        },
                        {
                            key: 'service-schedules',
                            value: this.$tc('backups.schedule', 2),
                        },
                        {
                            key: 'service-restores',
                            value: this.$tc('backups.restore', 2),
                        }
                    ],
            }
        },
    }
</script>
