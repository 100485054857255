import devFeatureFlags from 'data/unleash/dev.json'
import testFeatureFlags from 'data/unleash/test.json'
import prodFeatureFlags from 'data/unleash/prod.json'

export function getBootstrapFFsForUnleash(){
    const ENV = process.env.VUE_APP_PORTAL_ENV

    let bootstrapFFs = null

    switch (ENV) {
    case 'development':
        bootstrapFFs = devFeatureFlags.toggles
        break
    case 'test':
        bootstrapFFs = testFeatureFlags.toggles
        break
    case 'production':
        bootstrapFFs = prodFeatureFlags.toggles
        break
    default:
        break
    }
    return bootstrapFFs
}
