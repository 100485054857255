<style lang="scss" scoped>
    .region-wrapper {
        position: relative;
        padding: 5px;
        border: 2px solid transparent;
    }
    .region-wrapper.highlight-instance-row {
        border-radius: 10px;
    }
    .region-wrapper .selection-status {
        position: absolute;
        right: 0px;
        top: 6px;
    }
    .region-text {
        line-height: 1.363em;
        vertical-align: middle;
        font-size: 13px;
        min-height: 70px;
    }
    .disabled-item {
        color: $grayed-out
    }
</style>

<template>
    <v-col :data-auto="dataAutoPrefix('')">
        <div
            v-for="(item, index) in locationData"
            :key="item.name"
            :class="[
                'region-wrapper',
                {'clickable-element': !item.isDisabled},
                { 'disabled-item': item.isDisabled},
                currentLocation === item.name && 'highlight-instance-row',
            ]"
            :data-auto="dataAutoPrefix(++index)"
            @click="onClick(item)">
            <v-icon
                v-if="currentLocation === item.name"
                size="20"
                class="selection-status">
                $vuetify.icons.statusOk
            </v-icon>
            <v-row>
                <v-col cols="2">
                    <v-icon
                        size="20"
                        :class="['mr-3', { 'disabled-item': item.isDisabled},]"
                        class="">
                        {{ item.icon }}
                    </v-icon>
                </v-col>
                <v-col
                    class="region-text pr-0"
                    cols="8">
                    <h3>{{ item.label }}</h3>
                    <span>{{ item.name }}</span>
                </v-col>
            </v-row>
        </div>
    </v-col>
</template>

<script>
    const RegionList = {
        name: 'region-list',
        props: {
            locationData: Array,
            region: String,
            parentDataAutoPrefix: Function,
            currentLocation: String,
        },
        methods: {
            onClick(item) {
                if(item.isDisabled) return
                this.$emit('handleRegionSelection', item)
            },
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(
                    'content-regions-' + this.region + (string ? '-' + string : '')
                )
            },
        },
    }

    export default RegionList
</script>
