<style lang="scss" scoped>
.label-wrapper {
    width: 208px
}

.select-direction {
    flex: 0 0 160px;
}

.w-fit-content {
    width: fit-content;
}
</style>
<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="action"
        :on-cancel="doCancel"
        :min-width="600"
        :max-width="600">
        <template #title>
            <div class="font-weight-light text-h5 pb-6">
                {{ dialogTitle }}
            </div>
        </template>
        <template #body>
            <div v-if="dialogType === $config.DIALOG_TYPE.REMOVE">
                {{ $t('globalXpand.areYouSure') }}
            </div>
            <div v-else>
                <div class="pb-5">
                    {{ getDescriptionText }}
                </div>
                <div class="d-flex pb-3 text-subtitle-2 label-wrapper justify-space-between">
                    <label>{{ $t('globalXpand.direction') }}</label>
                    <label>{{ $tc('service', 1) }}</label>
                </div>
                <div
                    v-for="(replication, index) in replicationRows"
                    :key="index"
                    class="d-flex pb-2">
                    <v-select
                        v-model="replication.direction"
                        :items="replicationDirections(index)"
                        :placeholder="$t('select')"
                        dense
                        class="pr-4 select-direction"
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                            contentClass: 'std--dropdown-list max-height-330',
                        }"
                        outlined
                        hide-details
                        required />
                    <v-select
                        v-model="replication.serviceId"
                        :items="xpandServices"
                        :placeholder="$t('select')"
                        class="pr-4"
                        dense
                        item-text="name"
                        item-value="id"
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                            contentClass: 'std--dropdown-list max-height-330',
                        }"
                        outlined
                        hide-details
                        required />
                    <v-icon
                        color="primary"
                        class="pointer"
                        size="20"
                        @click="removeRow(index)">
                        mdi-close
                    </v-icon>
                </div>
                <div
                    class="color text-primary text-caption pointer w-fit-content"
                    @click="addNewRow">
                    {{ $t('globalXpand.addAnother') }}
                </div>
                <div class="color text-grey text-caption mt-3">
                    <v-icon
                        size="18"
                        class="mb-1">
                        mdi-information-outline
                    </v-icon>
                    {{ $t('globalXpand.singleMaster') }}
                </div>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                v-if="dialogType !== $config.DIALOG_TYPE.CANCEL"
                color="primary"
                depressed
                rounded
                outlined
                class="cancel"
                @click="cancel">
                <span class="text-none text-body-1 px-3">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                :color="[$config.DIALOG_TYPE.REMOVE].includes(dialogType) ? 'error' : 'primary'"
                depressed
                rounded
                :disabled="isSaveDisabled"
                @click="save">
                <span class="text-capitalize text-body-1 px-3"> {{ actionText() }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>
<script>
    import { getReplicas } from 'utils/replication'
    import { mapActions, mapGetters } from 'vuex'
    import { isXpand } from 'utils/service'

    export default {
        name: 'GlobalXpandModal',
        props: {
            dialogType: {
                type: String,
                required: true,
            },
            selectedService: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                isDialogOpen: false,
                replicationRows: [],
            }
        },
        computed: {
            ...mapGetters(['services', 'createReplicationSuccess', 'getServiceById', 'deleteReplicationSuccess']),
            dialogTitle() {
                return this.$t(`globalXpand.${this.dialogType}`)
            },
            xpandServices() {
                let xpServices = []
                this.services.forEach(service => {
                    // Push other Xpand services which don't have a FAILED status
                    if (isXpand(service.topology) && service.id !== this.selectedService.id && service.status !== this.$config.serviceStatus.FAILED) {
                        xpServices.push(service)
                    }
                })
                return xpServices
            },
            replicas() {
                return getReplicas(this.services, this.selectedService)
            },
            isSaveDisabled() {
                // Always enable for Remove dialog
                if (this.dialogType === this.$config.DIALOG_TYPE.REMOVE) return false
                let disable = false
                // Enable if any rows removed by user
                if (this.replicationRows.length < this.replicas.length) disable = false
                else if (this.replicationRows.length) {
                    for (const row of this.replicationRows) {
                        // Disable if either direction or service id is empty
                        if (!row.direction || !row.serviceId) disable = true
                        else {
                            // disable only if an existing row with same inputs found
                            const replica = this.replicas.find(replica => row.serviceId === replica.service.id)
                            disable = replica && replica.direction === row.direction
                        }
                    }
                } else {
                    // Disable if no rows added
                    disable = true
                }
                return disable
            },
            getDescriptionText() {
                const topologyName = this.$t('topology.xpand') // topology will always be xpand-direct
                return this.replicas ? this.$t('globalXpand.currentlyReplicating', { topologyName, }) : this.$t('globalXpand.allowsToReplicate', { topologyName, })
            },
        },
        methods: {
            ...mapActions(['createReplication', 'deleteReplicas', 'fetchServices']),
            actionText() {
                switch (this.dialogType) {
                    case this.$config.DIALOG_TYPE.REMOVE:
                        return this.$t('globalXpand.yesRemove')
                    default:
                        return this.$t('save')
                }
            },
            open() {
                this.isDialogOpen = true
                this.setReplicationRows()
            },
            doCancel() {
                this.isDialogOpen = false
                this.setReplicationRows()
            },
            addNewRow() {
                this.replicationRows.push({ direction: '', serviceId: '', })
            },
            removeRow(index) {
                this.replicationRows.splice(index, 1)
                document.activeElement.blur()
            },
            async action() {
                const { TO, FROM, BOTH_WAYS, } = this.$config.REPLICATION_DIRECTION_OPTIONS
                const { UNI, BI, } = this.$config.REPLICATION_DIRECTIONS
                const { SETUP, EDIT, REMOVE, } = this.$config.DIALOG_TYPE
                switch (this.dialogType) {
                    case SETUP:
                    case EDIT:
                        if (this.replicationRows.length < this.replicas.length) {
                            // Delete existing replications if removed by user
                            for (let replica of this.replicas) {
                                let row = this.replicationRows.find(row => row.serviceId === replica.service.id)
                                if (!row) {
                                    await this.handleReplicationDeletion({ direction: replica.direction, serviceId: replica.service.id, })
                                    if (this.deleteReplicationSuccess) {
                                        await this.fetchServices()
                                        this.$store.commit('showMessage', { text: this.$t('success.replicationEdited'), type: this.$config.messageType.SUCCESS, })
                                    }
                                }
                            }
                        }
                        for (let row of this.replicationRows) {
                            let replica
                            if (this.hasExistingDirectionChanged(row)) {
                                // Delete existing replication before creating new one for same service
                                replica = this.replicas.find(replica => row.serviceId === replica.service.id)
                                await this.handleReplicationDeletion({ direction: replica.direction, serviceId: replica.service.id, })
                            }
                            switch (row.direction) {
                                case FROM:
                                    // If previous direction was BOTH_WAYS then no need to create new replication
                                    if (!replica || (replica && replica.direction !== BOTH_WAYS))
                                        await this.createReplication({ serviceId: this.selectedService.id, primaryServiceId: row.serviceId, replicationType: UNI, })
                                    break
                                case TO:
                                    // If previous direction was BOTH_WAYS then no need to create new replication
                                    if (!replica || (replica && replica.direction !== BOTH_WAYS))
                                        await this.createReplication({ serviceId: row.serviceId, primaryServiceId: this.selectedService.id, replicationType: UNI, })
                                    break
                                case BOTH_WAYS:
                                    if (!replica)
                                        await this.createReplication({ serviceId: this.selectedService.id, primaryServiceId: row.serviceId, replicationType: BI, })
                                    else {
                                        // Only creating remaining direction which is not existing
                                        replica.direction === FROM ? await this.createReplication({ serviceId: row.serviceId, primaryServiceId: this.selectedService.id, replicationType: UNI, }) :
                                        await this.createReplication({ serviceId: this.selectedService.id, primaryServiceId: row.serviceId, replicationType: UNI, })
                                    }
                                    break
                            }
                        }
                        if (this.createReplicationSuccess) {
                            await this.fetchServices()
                            this.$store.commit('showMessage', { text: this.dialogType === SETUP ? this.$t('success.replicationCreated') : this.$t('success.replicationEdited'), type: this.$config.messageType.SUCCESS, })
                        }
                        break
                    case REMOVE:
                        for (let row of this.replicationRows) {
                            await this.handleReplicationDeletion(row)
                        }
                        if (this.deleteReplicationSuccess) {
                            await this.fetchServices()
                            this.$store.commit('showMessage', { text: this.$t('success.replicationRemoved'), type: this.$config.messageType.SUCCESS, })
                        }
                        break
                }
            },
            hasExistingDirectionChanged(row) {
                let replica = this.replicas.find(replica => row.serviceId === replica.service.id)
                return replica && replica.direction !== row.direction
            },
            replicationDirections(index) {
                let directionArray = []
                const { TO, FROM, BOTH_WAYS, } = this.$config.REPLICATION_DIRECTION_OPTIONS
                // Any xpand service can have multiple TO direction replication to other xpand service
                // But there can exist only either one other FROM or BOTH_WAYS replication for that service
                Object.values([TO, FROM, BOTH_WAYS]).forEach(direction => {
                    if (direction == TO) directionArray.push({ text: this.$t(TO), value: TO, })
                    else if (!this.replicationRows.find((row, key) => [FROM, BOTH_WAYS].includes(row.direction) && index !== key)) {
                        directionArray.push({ text: this.$t(direction), value: direction, })
                    }
                })
                return directionArray
            },
            async handleReplicationDeletion(row) {
                const { FROM, BOTH_WAYS, } = this.$config.REPLICATION_DIRECTION_OPTIONS
                const { EDIT, } = this.$config.DIALOG_TYPE
                const oldRow = this.replicationRows.find(replica => row.serviceId === replica.serviceId)
                const isEditDialog = this.dialogType === EDIT
                if (!isEditDialog || (isEditDialog && (!oldRow || oldRow.direction !== BOTH_WAYS))) {
                    switch (row.direction) {
                        case FROM:
                            await this.deleteReplicas({ serviceId: this.selectedService.id, })
                            break
                        case BOTH_WAYS:
                            if (isEditDialog && oldRow) {
                                // Direction changed. Only delete the direction which is not needed
                                oldRow.direction === FROM ? await this.deleteReplicas({ serviceId: row.serviceId, }) :
                                await this.deleteReplicas({ serviceId: this.selectedService.id, })
                            } else {
                                await this.deleteReplicas({ serviceId: this.selectedService.id, })
                                await this.deleteReplicas({ serviceId: row.serviceId, })
                            }
                            break
                        default:
                            await this.deleteReplicas({ serviceId: row.serviceId, })
                    }
                }
            },
            setReplicationRows() {
                if (this.replicas.length) {
                    this.replicationRows = []
                    this.replicas.forEach(replica => {
                        this.replicationRows.push({ direction: replica.direction, serviceId: replica.service.id, })
                    })
                } else {
                    this.replicationRows = []
                    this.addNewRow()
                }
            },
        },
        created() {
            this.setReplicationRows()
        },
    }
</script>
