<style lang="scss" scoped>
.need-more-box {
    border: 1px solid $need-more-border;
    position: relative;
}
</style>

<template>
    <div
        data-auto="power-tier-storage-banner"
        class="mt-2 pt-4 mb-2 need-more-box">
        <div class="pl-8">
            <p class="mb-2">
                <span data-auto="power-tier-storage-banner-title">
                    {{ title }}
                </span>
                <span class="font-weight-bold float-right mr-8">
                    <a
                        rel="noopener noreferrer"
                        :href="$config.SUPPORT_URL"
                        target="_blank">
                        {{ $t('contact-us') }}</a>
                    {{ $t('power-tier-offering') }}
                </span>
            </p>
            <p class="storage-footnote pt-2">
                {{ $t('ideal-prod-workload-note') }}
            </p>
        </div>
    </div>
</template>

<script>
    const StorageTierUpgradeBanner = {
        name: 'StorageTierUpgradeBanner',
        props: {
            title: {
                required: false,
                type: String,
            },
        },
    }

    export default StorageTierUpgradeBanner
</script>
