import { CONFIG } from 'config/index.js'

export function isDevEnv () {
    return CONFIG.APP_ENV === 'development'
}

export function isTestEnv () {
    return CONFIG.APP_ENV === 'test'
}

export function isProdEnv () {
    return CONFIG.APP_ENV === 'production'
}
