<style lang="scss" scoped>
.selection-group {
    height: 90px
}

.autoscale-label :deep label {
    font-size: 0.75em;
}

.width-85 {
    width: 85px;
}

.width-400 {
    width: 400px;
}

:deep(.v-list-item) {
    font-size: 0.875rem !important;
}
</style>

<template>
    <div data-auto="storage-auto-scale">
        <v-row class="mt-4">
            <v-col class="d-flex">
                <v-checkbox
                    v-model="isMaxScaleRedundancyEnabled"
                    class="autoscale-label"
                    :label="checkboxLabel"
                    @change="onMaxScaleToggle" />
                <div class="mt-4 pl-2">
                    <HelpToolTip>
                        <template #message>
                            <p>
                                {{ $t('launchService.highlyAdvisable') }}
                            </p>
                            <a
                                rel="noopener noreferrer"
                                class="text-capitalize"
                                target="_blank"
                                href="https://r.mariadb.com/skysql-maxscale-ha">{{
                                    $t('learn-more')
                                }}</a>
                        </template>
                    </HelpToolTip>
                </div>
            </v-col>
            <v-col />
        </v-row>
        <div v-if="isMaxScaleRedundancyEnabled">
            <span v-if="showNodeSelection">
                <v-row class="text-body-2 pl-4">
                    {{ $t('launchService.selectMaxScaleNodes') }}
                </v-row>
                <v-row class="text-body-2 pl-4 pt-2 width-85">
                    <v-select
                        v-model="selectedMaxScaleNode"
                        class="text-body-2"
                        :items="maxScaleNodes"
                        required
                        :menu-props="{ top: false, offsetY: true }"
                        dense
                        outlined
                        @change="setNode" />
                </v-row>
            </span>
            <v-row class="text-body-2 pl-4">
                {{ $t('launchService.selectMaxScaleSize') }}
            </v-row>
            <v-row class="text-body-2 pl-4 pt-2 width-400">
                <v-select
                    v-model="selectedMaxScaleSize"
                    :items="instanceSizeList"
                    class="text-body-2"
                    item-value="id"
                    required
                    :menu-props="{ top: false, offsetY: true }"
                    dense
                    outlined
                    @change="setSize">
                    <template #item="{ item }">
                        <span>{{ item.displayName }}</span>
                        <span class="pl-5">{{ item.cpu }}</span>
                        <span class="pl-5">{{ item.ram }}</span>
                        <span class="pl-5">{{ item.displayPrice }}</span>
                    </template>
                    <template #selection="{ item }">
                        <span>{{ item.displayName }}</span>
                        <span class="pl-5">{{ item.cpu }}</span>
                        <span class="pl-5">{{ item.ram }}</span>
                        <span class="pl-5">{{ item.displayPrice }}</span>
                    </template>
                </v-select>
            </v-row>
        </div>
    </div>
</template>

<script>
    import HelpToolTip from 'components/common/HelpTooltip'
    import { mapGetters } from 'vuex'

    export default {
        name: 'MaxScaleSelection',
        components: { HelpToolTip, },
        props: {
            selectedInstance: {
                type: Object,
                default: () => { },
            },
            instanceCost: {
                type: Array,
                required: true,
            },
            maxScaleInstances: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                isMaxScaleRedundancyEnabled: false,
                selectedMaxScaleNode: 2,
                selectedMaxScaleSize: {},
                instanceSizeList: [],
            }
        },
        computed: {
            ...mapGetters(['maxScaleNodes']),
            checkboxLabel() {
                return this.maxScaleNodes.length === 2 ? `${this.$t('launchService.enableMaxScale')} (${this.$t('launchService.twoMaxScales')})` : this.$t('launchService.enableMaxScale')
            },
            showNodeSelection() {
                return this.maxScaleNodes.length > 2
            },
        },
        watch: {
            selectedInstance() {
                this.setSelectedMaxScaleSize()
            },
            maxScaleInstances() {
                this.setInstanceSizeList()
                this.setSelectedMaxScaleSize()
            },
        },
        methods: {
            onMaxScaleToggle(isChecked) {
                this.$emit('on-maxscale-toggle', isChecked)
                if (isChecked) {
                    this.setInstanceSizeList()
                    this.setSelectedMaxScaleSize()
                }
            },
            filterByLowestPrice(instances) {
                const filteredInstances = {}
                instances.forEach(instance => {
                    const name = instance.name
                    const pricePerMonth = parseFloat(instance.pricePerMonth)

                    if (!filteredInstances[name] || pricePerMonth < parseFloat(filteredInstances[name].pricePerMonth)) {
                        filteredInstances[name] = instance
                    }
                })
                return Object.values(filteredInstances)
            },
            setInstanceSizeList() {
                if (!this.instanceCost.length) return []
                const list = []
                for (let maxScaleSize of this.maxScaleInstances) {
                    const foundCost = this.instanceCost.find(
                        cost =>
                            cost.name.toLowerCase() === maxScaleSize.name &&
                            cost.arch === maxScaleSize.architecture
                    )
                    const { id, name, display_name: displayName, cpu, ram, } = maxScaleSize
                    if (foundCost) {
                        const pricePerHour = Number(foundCost.pricePerHour).toFixed(5)
                        const pricePerMonth = Number(foundCost.pricePerMonth).toFixed(5)
                        list.push({ id, name, displayName, cpu, ram, pricePerHour, pricePerMonth, displayPrice: `$${pricePerHour}/hr`, })
                    } else {
                        list.push({ id, name, displayName, cpu, ram, pricePerHour: this.$i18n.t('notApplicable'), pricePerMonth: this.$i18n.t('notApplicable'), displayPrice: 'n/a', })
                    }
                }

                this.instanceSizeList = this.filterByLowestPrice(list)
            },
            setSelectedMaxScaleSize() {
                if (!this.selectedInstance) this.selectedMaxScaleSize = this.instanceSizeList[0]
                else this.selectedMaxScaleSize = this.instanceSizeList.find(maxScaleSize => maxScaleSize.name === this.selectedInstance.default_maxscale_size_name)
                this.$emit('on-maxscale-size-selection', this.selectedMaxScaleSize)
            },
            setNode() {
                this.$emit('on-maxscale-node-selection', this.selectedMaxScaleNode)
            },
            setSize() {
                let foundSize = this.instanceSizeList.find(size => size.id === this.selectedMaxScaleSize)
                this.$emit('on-maxscale-size-selection', foundSize)
            },
        },
    }
</script>
