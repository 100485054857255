<style lang="scss" scoped>
    .time-range-input {
        max-width: 242px;
        font-size: 14px;
        &.v-input ::v-deep .v-input__control {
            .v-input__prepend-inner {
                margin-top: 6px;
            }
            .v-input__slot {
                min-height: 36px;
            }
        }
    }
    .time-range-date-select {
        font-size: 14px;
    }
    .inner-icon {
        width: 16px;
    }
</style>

<template>
    <div>
        <v-select
            ref="timeRange"
            v-model="selectedTime"
            :items="relativeTimes"
            append-icon=""
            item-text="label"
            item-value="value"
            height="36"
            hide-details
            return-object
            outlined
            dense
            attach
            class="time-range-input std std--dropdown"
            :menu-props="{ bottom: true, left: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
            @change="onTimeChange">
            <template #prepend-inner>
                <v-icon class="inner-icon">
                    $vuetify.icons.calendar
                </v-icon>
            </template>
            <template #selection="{ item }">
                <div v-if="item.disabled">
                    {{ customRangeTxt }}
                </div>
                <div v-else>
                    {{ selectedTime.label }}
                </div>
            </template>
            <template #append-item>
                <div class="d-flex px-3">
                    <v-menu
                        ref="menu"
                        v-model="isCustomTimeOpen"
                        :return-value.sync="customTime"
                        :close-on-content-click="false"
                        :close-on-click="false"
                        min-width="290px"
                        nudge-top="-10"
                        nudge-left="10"
                        top
                        internal-activator
                        open-on-focus>
                        <template #activator="{ on, attrs }">
                            <v-text-field
                                v-model="customRangeTxt"
                                v-bind="attrs"
                                class="pa-0 time-range-date-select"
                                hide-details="auto"
                                outlined
                                dense
                                readonly
                                v-on="on">
                                <template #prepend-inner>
                                    <v-icon class="inner-icon">
                                        $vuetify.icons.calendar
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker
                            v-model="customTime"
                            color="primary"
                            range
                            no-title
                            scrollable>
                            <v-spacer />
                            <v-btn
                                text
                                @click="isCustomTimeOpen = false">
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-btn
                                color="primary"
                                :disabled="customTime.length <= 1"
                                depressed
                                rounded
                                @click="onCustomTimeChange(customTime)">
                                {{ $t('ok') }}
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
    export default {
        name: 'MonitoringTimePicker',
        props: {
            options: {
                type: Object,
                default: () => {},
            },
            defaultValue: {
                type: Array,
                default: () => [],
            },
        },
        data () {
            return {
                relativeTimes: [],
                selectedTime: {
                    label: '',
                    value: [],
                },
                customTime: [],
                isCustomTimeOpen: false,
            }
        },
        computed: {
            customRangeTxt () {
                const dateFormat = 'YYYY-MM-DD'
                const [start, end] = this.selectedTime.value
                if (!this.$typy(start).isNumber || !this.$typy(end).isNumber) return this.$t('selectTimeRange')

                return `${this.$moment(start).format(dateFormat)} to ${this.$moment(end ).format(dateFormat)}`
            },
        },
        methods: {
            onTimeChange ({ value, }) {
                this.$emit('change', value)
            },
            onCustomTimeChange (range) {
                this.$refs.menu.save(range)
                range = [
                    this.$moment(range[0]).valueOf(),
                    this.$moment(range[1]).valueOf()
                ].sort((a, b) => a - b)
                const customRange = this.relativeTimes.find(time => time.disabled)
                customRange.value = range
                this.selectedTime = customRange
                this.onTimeChange(this.selectedTime)
                this.closeSelect()
            },
            closeSelect () {
                this.$refs.timeRange.blur()
            },
            generateRelativeTimes (options) {
                const times = []
                for (const [key, values] of Object.entries(options)) {
                    values.forEach(value => {
                        times.push({
                            label: this.$t(`monitoring.last_x_${key}`, [value]),
                            value: [`now-${value} ${key}`, 'now'],
                        })
                    })
                }
                times.push({ label: this.$t('customRange'), value: [], disabled: true, })
                this.relativeTimes = times
            },
        },
        created () {
            this.generateRelativeTimes(this.options)
            let selectedIndex = this.relativeTimes.findIndex(({ value, }) => value.join() === this.defaultValue.join())
            this.selectedTime = this.relativeTimes[selectedIndex !== -1 ? selectedIndex : 0]
        },
    }
</script>
