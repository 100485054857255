<style lang="scss" scoped>
.scale-reduce {
    transform: scale(0.725);
    transform-origin: 50% 50%;
}

.tags-list-chips-container {
    max-height: 250px;
    overflow-y: auto;
}

// Hide selected items
::v-deep .context-tag:not(.show) .v-select__selection {
    display: none;
}

// show placeholder even when selected items are hidden
::v-deep .context-tag.v-input--is-dirty ::placeholder {
    color: $navigation !important;
    opacity: 0.5;
}
</style>
<template>
    <div>
        <v-autocomplete
            :value="selected"
            :items="items"
            item-text="txt"
            item-value="val"
            :placeholder="$unleash.isFeatureEnabled('enable-portal-logs-with-viewer') ? $t('contextPlaceholder') : !isServicePreselected ? $t('contextPlaceholder') : ''"
            class="show std std--dropdown context-tag v-text-field--with-bottom-details"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
            height="36"
            hide-details="auto"
            :disabled="$unleash.isFeatureEnabled('enable-portal-logs-with-viewer') ? false : isServicePreselected"
            outlined
            attach
            single-line
            multiple>
            <template #item="{ item }">
                <v-list-item
                    v-if="visibleServices.length"
                    :class="[{ 'font-weight-medium': item.isService }, 'list-item context-list-item d-flex align-center pr-6']"
                    :style="{ paddingLeft: `${item.isService || item.val === $config.allTopologiesTag ? 8 : 36}px` }"
                    @click="toggle(item)">
                    <v-checkbox
                        class="scale-reduce"
                        :input-value="isItemSelected(item)"
                        primary />
                    <truncate-string
                        :class="{ 'grey--text': !item.isActive }"
                        :text="item.txt"
                        :max-width="'190px'"
                        :dot-count="3"
                        :has-tooltip="true" />
                </v-list-item>
                <v-list-item v-else>
                    {{ $t('info.noServers') }}
                </v-list-item>
            </template>
            <template #selection="{ item, index }">
                <div
                    v-if="index === 0"
                    class="v-select__selection v-select__selection--comma">
                    <span v-if="selected.length > 1">
                        {{ areAllSelected ? $t('anyServer') : `${selected.length} ${$t('selected')}` }}
                    </span>
                    <truncate-string
                        v-else
                        :text="item.txt"
                        :max-width="'190px'"
                        :dot-count="3"
                        :has-tooltip="true" />
                </div>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'SelectServers',
        props: {
            filter: { type: Array, required: true, },
            preselected: { type: Array, default: () => [], },
            autoSelectAll: { type: Boolean, default: true, },
        },
        data() {
            return {
                selected: [],
            }
        },
        computed: {
            ...mapGetters(['services']),
            visibleServices() {
                const services = []

                this.filter.forEach(server => {
                    let service = services.find(val => val.name === server.service)
                    if (!service) {
                        service = { name: server.service, namespace: server.serverDataSourceId.split('/')[0], servers: [], }
                        services.push(service)
                    }
                    service.servers.push({ name: server.server, })
                })

                return services
            },
            items() {
                const items = [
                    this.generateItem(this.$t('anyServer'), this.$config.allTopologiesTag, false, true)
                ]

                this.visibleServices.forEach(service => {
                    const checkService = this.services.find(s => s.name === service.name)
                    items.push(this.generateItem(`${service.name}/Any`, service.name, true, Boolean(checkService)))

                    service.servers.forEach(server => {
                        let checkServer
                        if (checkService) checkServer = checkService.servers.find(s => s.name === server.name)

                        items.push(this.generateItem(server.name, this.getServerValue(service, server), false, Boolean(checkServer)))
                    })
                })

                return items
            },
            totalServers() {
                return this.visibleServices.reduce((acc, service) => acc + service.servers.length, 0)
            },
            areAllSelected() {
                return this.totalServers === this.selected.length
            },
            isServicePreselected() {
                return Boolean(this.preselected.length)
            },
        },
        watch: {
            selected(servers) {
                this.$emit('change', servers)
            },
            visibleServices() {
                if (this.autoSelectAll) {
                    // Clear selection
                    this.selected = []
                    this.toggleAll()
                }
            },
            preselected: {
                deep: true,
                immediate: true,
                handler(v) {
                    if (v.length)
                        this.selected = v
                },
            },
        },
        methods: {
            generateItem(txt, val, isService, isActive) {
                return {
                    txt,
                    val,
                    isService,
                    isActive,
                }
            },
            getServiceByName(serviceName) {
                return this.visibleServices.find(service => service.name === serviceName)
            },
            getServerValue(service, server) {
                return `${service.namespace}/${server.name}`
            },
            isItemSelected(item) {
                if (item.val === this.$config.allTopologiesTag) return this.areAllSelected
                else if (item.isService) return this.isServiceSelected(item.val)
                else return this.isServerSelected(item.val)
            },
            isServiceSelected(serviceName) {
                const service = this.getServiceByName(serviceName)
                if (!service) return false

                for (let i = 0; i < service.servers.length; i++) {
                    const server = this.getServerValue(service, service.servers[i])
                    if (!this.isServerSelected(server)) return false
                }
                return true
            },
            isServerSelected(server) {
                return this.selected.includes(server)
            },
            toggle(item) {
                if (item.val === this.$config.allTopologiesTag) this.toggleAll()
                else if (item.isService) this.toggleService(item.val)
                else this.toggleServer(item.val)
            },
            toggleService(serviceName) {
                const service = this.getServiceByName(serviceName)
                if (!service) return false

                const isSelected = this.isServiceSelected(serviceName)
                for (let i = 0; i < service.servers.length; i++) {
                    const server = this.getServerValue(service, service.servers[i])
                    if (isSelected && this.isServerSelected(server)) this.toggleServer(server)
                    if (!isSelected && !this.isServerSelected(server)) this.toggleServer(server)
                }
            },
            toggleServer(server) {
                if (this.isServerSelected(server)) this.selected = this.selected.filter(val => val !== server)
                else this.selected.push(server)
            },
            toggleAll() {
                const areAllSelected = this.areAllSelected

                for (let i = 0; i < this.visibleServices.length; i++) {
                    const { name, } = this.visibleServices[i]
                    if (areAllSelected && this.isServiceSelected(name)) this.toggleService(name)
                    if (!areAllSelected && !this.isServiceSelected(name)) this.toggleService(name)
                }
            },
        },
    }
</script>
