<template>
    <svg
        width="91"
        height="129"
        viewBox="0 0 91 129"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_333_3909)">
            <path
                d="M41.0905 127.108L1.78408 104.414C1.36652 104.174 1.01974 103.827 0.778633 103.41C0.537523 102.992 0.410583 102.519 0.410583 102.037C0.410583 101.555 0.537523 101.081 0.778633 100.664C1.01974 100.246 1.36652 99.8998 1.78408 99.659L41.0905 76.9659C42.3423 76.2431 43.7623 75.8626 45.2078 75.8626C46.6532 75.8626 48.0732 76.2431 49.3251 76.9659L88.6314 99.659C89.049 99.8998 89.3958 100.246 89.6369 100.664C89.878 101.081 90.0049 101.555 90.0049 102.037C90.0049 102.519 89.878 102.992 89.6369 103.41C89.3958 103.827 89.049 104.174 88.6314 104.414L49.3251 127.108C48.0732 127.83 46.6532 128.211 45.2078 128.211C43.7623 128.211 42.3423 127.83 41.0905 127.108Z"
                fill="#EBEBF2" />
            <path
                d="M0.411685 71.8398V85.5641C0.410974 86.0467 0.537466 86.5208 0.778414 86.9389C1.01936 87.3569 1.36625 87.7041 1.78411 87.9453L41.0905 110.638C42.3423 111.361 43.7623 111.742 45.2078 111.742C46.6533 111.742 48.0733 111.361 49.3251 110.638L88.6315 87.9453C89.0493 87.7041 89.3962 87.3569 89.6372 86.9389C89.8781 86.5208 90.0046 86.0467 90.0039 85.5641V71.8398H0.411685Z"
                fill="#D9DAE0"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M41.0905 96.9141L1.78408 74.221C1.36652 73.9802 1.01974 73.6336 0.778633 73.2162C0.537523 72.7988 0.410583 72.3253 0.410583 71.8433C0.410583 71.3612 0.537523 70.8877 0.778633 70.4703C1.01974 70.0529 1.36652 69.7064 1.78408 69.4655L41.0905 46.7724C42.3423 46.0497 43.7623 45.6692 45.2078 45.6692C46.6532 45.6692 48.0732 46.0497 49.3251 46.7724L88.6314 69.4655C89.049 69.7064 89.3958 70.0529 89.6369 70.4703C89.878 70.8877 90.0049 71.3612 90.0049 71.8433C90.0049 72.3253 89.878 72.7988 89.6369 73.2162C89.3958 73.6336 89.049 73.9802 88.6314 74.221L49.3251 96.9141C48.0732 97.6369 46.6532 98.0174 45.2078 98.0174C43.7623 98.0174 42.3423 97.6369 41.0905 96.9141Z"
                fill="white"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M69.5547 92.3096L84.1573 83.876"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M58.1086 98.9179L62.8778 96.1661"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M8.81487 86.8164C9.37284 86.579 9.48474 85.5865 9.06481 84.5996C8.64487 83.6127 7.85213 83.0051 7.29416 83.2425C6.7362 83.4799 6.6243 84.4724 7.04423 85.4593C7.46416 86.4462 8.2569 87.0538 8.81487 86.8164Z"
                fill="#003545" />
            <path
                d="M14.2476 89.9553C14.8056 89.7179 14.9175 88.7254 14.4976 87.7385C14.0776 86.7516 13.2849 86.144 12.7269 86.3814C12.169 86.6188 12.0571 87.6113 12.477 88.5982C12.8969 89.5851 13.6897 90.1927 14.2476 89.9553Z"
                fill="#003545" />
            <path
                d="M19.6831 93.0936C20.241 92.8562 20.3529 91.8637 19.933 90.8768C19.5131 89.8899 18.7203 89.2823 18.1624 89.5197C17.6044 89.7572 17.4925 90.7497 17.9124 91.7366C18.3324 92.7235 19.1251 93.331 19.6831 93.0936Z"
                fill="#003545" />
            <path
                d="M0.411682 49.2153V62.9396C0.412176 63.421 0.53924 63.8937 0.780127 64.3105C1.02101 64.7272 1.36725 65.0733 1.78411 65.3139L41.0905 88.0071C42.3423 88.7298 43.7623 89.1103 45.2078 89.1103C46.6533 89.1103 48.0733 88.7298 49.3251 88.0071L88.6315 65.3139C89.0512 65.0765 89.3997 64.7312 89.6411 64.3137C89.8824 63.8962 90.0077 63.4218 90.0039 62.9396V49.2153H0.411682Z"
                fill="#D9DAE0"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M41.0905 74.2828L1.78408 51.5896C1.36652 51.3488 1.01974 51.0023 0.778633 50.5849C0.537523 50.1675 0.410583 49.6939 0.410583 49.2119C0.410583 48.7299 0.537523 48.2563 0.778633 47.8389C1.01974 47.4215 1.36652 47.075 1.78408 46.8342L41.0905 24.141C42.3423 23.4183 43.7623 23.0378 45.2078 23.0378C46.6532 23.0378 48.0732 23.4183 49.3251 24.141L88.6314 46.8342C89.049 47.075 89.3958 47.4215 89.6369 47.8389C89.878 48.2563 90.0049 48.7299 90.0049 49.2119C90.0049 49.6939 89.878 50.1675 89.6369 50.5849C89.3958 51.0023 89.049 51.3488 88.6314 51.5896L49.3251 74.2828C48.0732 75.0055 46.6532 75.386 45.2078 75.386C43.7623 75.386 42.3423 75.0055 41.0905 74.2828Z"
                fill="white"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M79.3058 64.0513L84.1573 61.2515"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M58.1086 76.2934L75.0924 66.4874"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M8.81295 64.1871C9.37092 63.9497 9.48282 62.9572 9.06288 61.9703C8.64295 60.9834 7.85021 60.3758 7.29224 60.6132C6.73427 60.8506 6.62237 61.8431 7.04231 62.83C7.46224 63.8169 8.25498 64.4245 8.81295 64.1871Z"
                fill="#003545" />
            <path
                d="M14.2484 67.3254C14.8063 67.088 14.9182 66.0955 14.4983 65.1086C14.0784 64.1217 13.2856 63.5141 12.7277 63.7515C12.1697 63.989 12.0578 64.9815 12.4777 65.9684C12.8977 66.9553 13.6904 67.5629 14.2484 67.3254Z"
                fill="#003545" />
            <path
                d="M19.688 70.4643C20.246 70.2269 20.3579 69.2344 19.9379 68.2475C19.518 67.2606 18.7253 66.653 18.1673 66.8904C17.6093 67.1278 17.4974 68.1204 17.9174 69.1073C18.3373 70.0942 19.13 70.7017 19.688 70.4643Z"
                fill="#003545" />
            <path
                d="M0.411685 26.584V40.3083C0.410974 40.7908 0.537466 41.2649 0.778414 41.683C1.01936 42.101 1.36625 42.4482 1.78411 42.6894L41.0905 65.3825C42.3423 66.1053 43.7623 66.4858 45.2078 66.4858C46.6533 66.4858 48.0733 66.1053 49.3251 65.3825L88.6315 42.6894C89.0493 42.4482 89.3962 42.101 89.6372 41.683C89.8781 41.2649 90.0046 40.7908 90.0039 40.3083V26.584H0.411685Z"
                fill="#D9DAE0"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M41.0905 51.6582L1.78411 28.9651C1.36655 28.7243 1.01977 28.3777 0.778664 27.9603C0.537554 27.5429 0.410614 27.0694 0.410614 26.5874C0.410614 26.1054 0.537554 25.6318 0.778664 25.2144C1.01977 24.797 1.36655 24.4505 1.78411 24.2097L41.0905 1.51652C42.3423 0.793789 43.7623 0.4133 45.2078 0.4133C46.6533 0.4133 48.0733 0.793789 49.3251 1.51652L88.6315 24.2097C89.049 24.4505 89.3958 24.797 89.6369 25.2144C89.878 25.6318 90.005 26.1054 90.005 26.5874C90.005 27.0694 89.878 27.5429 89.6369 27.9603C89.3958 28.3777 89.049 28.7243 88.6315 28.9651L49.3251 51.6582C48.0733 52.381 46.6533 52.7615 45.2078 52.7615C43.7623 52.7615 42.3423 52.381 41.0905 51.6582Z"
                fill="white"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M74.4543 44.2266L84.1574 38.6202"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M58.1086 53.662L69.5547 47.0538"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M8.81371 41.5573C9.37168 41.3198 9.48358 40.3273 9.06364 39.3404C8.64371 38.3535 7.85097 37.746 7.293 37.9834C6.73504 38.2208 6.62314 39.2133 7.04307 40.2002C7.463 41.1871 8.25575 41.7947 8.81371 41.5573Z"
                fill="#003545" />
            <path
                d="M14.2465 44.6961C14.8045 44.4587 14.9164 43.4662 14.4965 42.4793C14.0765 41.4924 13.2838 40.8848 12.7258 41.1222C12.1679 41.3597 12.056 42.3522 12.4759 43.3391C12.8958 44.326 13.6886 44.9336 14.2465 44.6961Z"
                fill="#003545" />
            <path
                d="M19.6889 47.8413C20.2468 47.6039 20.3587 46.6114 19.9388 45.6245C19.5189 44.6376 18.7261 44.03 18.1682 44.2674C17.6102 44.5049 17.4983 45.4974 17.9182 46.4843C18.3382 47.4712 19.1309 48.0788 19.6889 47.8413Z"
                fill="#003545" />
            <path
                d="M45.7019 12.894L68.8479 26.2546C70.6252 27.2839 70.6252 28.9445 68.8479 29.967L52.1523 39.622C51.1556 40.1262 50.0543 40.3888 48.9374 40.3888C47.8204 40.3888 46.7192 40.1262 45.7225 39.622L27.5103 29.123V19.6806L39.272 12.894C40.2678 12.3867 41.3694 12.1222 42.487 12.1222C43.6045 12.1222 44.7061 12.3867 45.7019 12.894Z"
                fill="#2F99A3"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M33.0206 28.1623L39.5397 24.4018L33.0206 20.6413V28.1623Z"
                fill="#ABC74A"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-miterlimit="10" />
            <path
                d="M59.4741 25.9183H50.2583L52.9345 27.4692L43.4167 32.9658L47.2732 35.1891L56.7979 29.6925L59.4741 31.2434V25.9183Z"
                fill="white"
                stroke="#003545"
                stroke-width="0.823458"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_333_3909">
                <rect
                    width="90.4157"
                    height="128.212"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
