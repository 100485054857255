<style lang="scss" scoped>
.payment-option {
    border: 1px solid $cornsilk
}
.payment-option:not(:first-child) {
    margin-top: 25px;
}
</style>
<template>
    <div class="mt-8">
        <div v-if="!isFetchingInvoiceDeps">
            <template v-if="hasPaymentProfile">
                <v-row
                    v-for="profile in paymentProfile?.filter(profile => profile?.paymentMethodId)"
                    :key="profile?.paymentMethodId"
                    data-auto="invoice-summary"
                    class="font-weight-bold text-subtitle-1 payment-option pa-2">
                    <v-col cols="3">
                        <div
                            data-auto="payment-method"
                            class="text-h6 font-weight-bold">
                            {{ $t("billings.paymentMethod") }}
                        </div>
                        <div>
                            {{ $t('billings.creditCard') }} {{ getMaskedCCNumber(profile) }}
                        </div>
                    </v-col>
                </v-row>
            </template>
            <v-row>
                <v-col cols="3">
                    <v-btn
                        outlined
                        large
                        rounded
                        color="primary"
                        class="px-8 text-subtitle-1"
                        @click="openPaymentDialog">
                        <span class="pr-1">+</span> {{ $t('addNew') }}
                    </v-btn>
                </v-col>
            </v-row>
            <div v-if="showPaymentDialog">
                <Payment
                    :legal-entity-identifier="legalEntityIdentifier"
                    @on-update-profile="handleProfileUpdate"
                    @on-payment-close="handleProfileClose" />
            </div>
        </div>
        <div
            v-else
            class="text-center pt-3">
            <v-progress-circular
                size="70"
                width="7"
                color="primary"
                indeterminate />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Payment from 'components/billing/Payment.vue'

    export default {
        name: 'Invoice',
        components: { Payment, },
        data() {
            return {
                isFetchingInvoiceDeps: true,
                showPaymentDialog: false,
            }
        },
        computed: {
            ...mapGetters([
                'billingRecord',
                'paymentProfile',
                'isFetchingBillingRecord',
                'isFetchingPaymentDetail',
                'billingCountries'
            ]),
            hasPaymentProfile() {
                return this.paymentProfile?.length
            },
            isRemittance() {
                if (this.billingRecord) return this.billingRecord?.remittance
                return false
            },
            legalEntityIdentifier() {
                if (!this.billingRecord) return ''
                return this.billingRecord?.legalEntityIdentifier || ''
            },
        },
        methods: {
            ...mapActions([
                'togglePaymentDialog',
                'fetchBillingRecord',
                'fetchPaymentProfile',
                'fetchCountries'
            ]),
            async openPaymentDialog() {
                if (this.$lodash.isEmpty(this.paymentProfile)) {
                    await this.fetchPaymentProfile()
                }
                this.showPaymentDialog = true
                if (this.$lodash.isEmpty(this.billingCountries)) {
                    await this.fetchCountries()
                } else {
                    // Minor delay is required to open the
                    // payment dialog for cached data
                    await this.$nextTick()
                }
                this.togglePaymentDialog(true)
            },
            async handleProfileUpdate() {
                await this.fetchPaymentProfile()
                this.togglePaymentDialog(false)
                this.showPaymentDialog = false
            },
            handleProfileClose() {
                this.togglePaymentDialog(false)
                this.showPaymentDialog = false
            },
            getMaskedCCNumber(profile) {
                const splitCC = profile.card?.maskedCardNumber?.split('-')
                return '**** ' + splitCC?.at(-1)
            },
        },
        async mounted() {
            if (this.$lodash.isEmpty(this.billingRecord)) {
                this.fetchBillingRecord()
            }
            if (this.$lodash.isEmpty(this.paymentProfile)) {
                await this.fetchPaymentProfile()
            }
            this.isFetchingInvoiceDeps = false
        },
    }
</script>
