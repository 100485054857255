import { render, staticRenderFns } from "./RegionList.vue?vue&type=template&id=dfea79e2&scoped=true"
import script from "./RegionList.vue?vue&type=script&lang=js"
export * from "./RegionList.vue?vue&type=script&lang=js"
import style0 from "./RegionList.vue?vue&type=style&index=0&id=dfea79e2&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfea79e2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dfea79e2')) {
      api.createRecord('dfea79e2', component.options)
    } else {
      api.reload('dfea79e2', component.options)
    }
    module.hot.accept("./RegionList.vue?vue&type=template&id=dfea79e2&scoped=true", function () {
      api.rerender('dfea79e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/launchService/RegionList.vue"
export default component.exports