<style lang="scss" scoped>
.sub-navigation {
    width: 800px;
    flex-wrap: wrap;

    .sub-link {
        font-weight: 500;
        border: 1px solid $grayed-out;
        border-radius: 8px;
        width: 225px;
    }

    .router-link-active,
    .router-link-exact-active {
        border-bottom: 3px solid $primary;
        color: $primary;
    }
}
</style>

<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <div class="mb-4 view-headline color text-navigation font-weight-light">
                <span>{{ $t('settings') }}</span>
            </div>
            <div class="sub-navigation d-flex">
                <router-link
                    class="mr-7 mb-7 sub-link d-flex py-5 px-5 text-decoration-none"
                    :to="`/settings/organization`">
                    <span> <v-icon
                        size="45"
                        class="color text-links expand-arrow mr-3">
                        $vuetify.icons.settings
                    </v-icon></span>
                    <span class="d-flex align-center mb-1 text-capitalize"> {{ $t('organization') }} {{ 'Settings' }}
                    </span>
                </router-link>
                <router-link
                    class="mr-7 mb-7 sub-link d-flex py-5 px-5 text-decoration-none"
                    :to="`/settings/user-management`">
                    <span> <v-icon
                        size="60"
                        class="color text-links expand-arrow mr-3">
                        $vuetify.icons.userManagement
                    </v-icon></span>
                    <span class="d-flex align-center mb-1 text-capitalize"> {{ $t('userManagement') }} </span>
                </router-link>
                <router-link
                    class="mr-7 mb-7 sub-link d-flex py-5 px-5 text-decoration-none"
                    to="/settings/secure-access">
                    <span> <v-icon
                        size="60"
                        class="color text-links expand-arrow mr-3">
                        $vuetify.icons.firewall
                    </v-icon></span>
                    <span class="d-flex align-center mb-1 text-capitalize"> {{ $tc("secureAccess") }} </span>
                </router-link>
                <router-link
                    class="mr-7 mb-7 sub-link d-flex py-5 px-5 text-decoration-none"
                    :to="`/settings/configuration-manager`">
                    <span> <v-icon
                        size="60"
                        class="color text-links expand-arrow mr-3">
                        $vuetify.icons.configManager
                    </v-icon></span>
                    <span class="d-flex align-center mb-1 text-capitalize"> {{ $t('configurationManager') }} </span>
                </router-link>
                <router-link
                    class="mr-7 mb-7 sub-link d-flex py-5 px-5 text-decoration-none"
                    :to="`/settings/policies`">
                    <span> <v-icon
                        size="60"
                        class="color text-links expand-arrow mr-3">
                        $vuetify.icons.policies
                    </v-icon></span>
                    <span class="d-flex align-center mb-1 text-capitalize"> {{ $t('policies') }} </span>
                </router-link>
                <router-link
                    v-if="$unleash.isFeatureEnabled('enable-portal-settings-notifications-tab')"
                    class="mr-7 mb-7 sub-link d-flex py-5 px-5 text-decoration-none"
                    :to="`/settings/notifications`">
                    <span> <v-icon
                        size="60"
                        class="color text-links expand-arrow mr-3">
                        $vuetify.icons.notification
                    </v-icon></span>
                    <span class="d-flex align-center mb-1 text-capitalize"> {{ $tc('notification', 2) }} </span>
                </router-link>
                <router-link
                    class="mr-7 mb-7 sub-link d-flex py-5 px-5 text-decoration-none"
                    :to="`/settings/notification-channels`">
                    <span> <v-icon
                        size="60"
                        class="color text-links expand-arrow mr-3">
                        $vuetify.icons.channel
                    </v-icon></span>
                    <span class="d-flex align-center mb-1 text-capitalize"> {{ $tc('notification') }} {{ $tc('channel') }}
                    </span>
                </router-link>
            </div>
        </v-col>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        name: 'SettingsView',
        computed: {
            ...mapGetters(['service', 'server', 'users']),
        },
        methods: {
            ...mapActions(['getUsers']),
        },
        mounted() {
            if(!this.users?.length) {
                this.getUsers()
            }
        },
    }
</script>
