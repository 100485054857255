<template>
    <v-row justify="center">
        <v-dialog
            v-model="isDialogOpen"
            persistent
            max-width="800px"
            scrollable>
            <v-card>
                <v-card-title class="pt-7 px-10">
                    <span class="text-h4 font-weight-medium">
                        {{ $t('backups.editCronSchedule') }}
                    </span>
                </v-card-title>
                <v-card-text class="overflow-visible">
                    <v-container style="min-height: 270px;">
                        <v-row>
                            <v-col cols="6">
                                <div>
                                    <label class="text-uppercase">
                                        {{ $tc('service') }}
                                    </label>
                                    <div class="text-body-1">
                                        {{ schedule.serviceName }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <div>
                                    <label class="text-uppercase">
                                        {{ $t('backups.type') }}
                                    </label>
                                    <div class="text-body-1">
                                        {{ schedule.type }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <label class="text-uppercase">
                                    {{ $t('frequency') }}
                                </label>
                                <v-radio-group
                                    value="cron"
                                    dense
                                    hide-details
                                    row
                                    readonly
                                    disabled
                                    class="mt-0">
                                    <v-radio
                                        :label="$t('once')"
                                        value="once" />
                                    <v-radio
                                        :label="$t('cron')"
                                        value="cron" />
                                </v-radio-group>
                            </v-col>
                            <v-col
                                cols="6"
                                class="pb-0 pt-4">
                                <v-text-field
                                    :value="value"
                                    :label="$t('cronValue')"
                                    :error-messages="cronError"
                                    placeholder="* * * * *"
                                    outlined
                                    dense
                                    @input="debounceCronInput" />
                            </v-col>
                            <v-col
                                cols="12"
                                class="pt-0">
                                <cronLight
                                    v-model="value"
                                    @error="cronError=$event" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider />
                <v-card-actions class="px-9 py-4">
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        outlined
                        :ripple="false"
                        @click="onClose">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        class="text-none"
                        color="primary"
                        style="min-width: 100px;"
                        rounded
                        depressed
                        :disabled="!isFormValid"
                        :loading="isLoading"
                        @click="onSubmit">
                        {{ $t('change') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import { debounce } from 'lodash'

    export default {
        name: 'EditScheduleModal',
        props: {
            showDialog: {
                type: Boolean,
                required: true,
            },
            schedule: {
                type: Object,
                required: true,
            },
            isLoading: {
                type: Boolean,
            },
        },
        data () {
            return {
                isDialogOpen: false,
                value: '0 0 * * *',
                cronError: '',
            }
        },
        computed: {
            isFormValid() {
                return this.cronError.length <= 0
            },
        },
        watch: {
            showDialog() {
                this.isDialogOpen = this.showDialog
                this.value = this.schedule.schedule ||  '0 0 * * *'
            },
        },
        methods: {
            onSubmit() {
                this.$emit('update', {
                    schedule: this.value,
                })
            },
            onClose() {
                this.$emit('cancel')
            },
            debounceCronInput: debounce(function (value) {
                this.value = value.trim()
            }, 500),
        },
    }
</script>
