import to from 'await-to-js'
import { getProjects, upgradeTier, getOrgDetails } from 'services/organizations'

export default {
    state: {
        projects: [],
        projectsError: false,
        currentTier: '',
        tierError: false,
        orgData: null,
        orgError: null,
        tierUpgrade: null,
        tierUpgradeError: false,
    },
    mutations: {
        setProjects(state, payload) {
            state.projects = payload
        },
        setProjectErrors(state, payload) {
            state.projectsError = payload
        },
        setOrg(state, payload){
            state.orgData = payload
        },
        setOrgError(state, payload) {
            state.orgError = payload
        },
        setCurrentTier(state, payload) {
            state.currentTier = payload.tier
        },
        setTierUpgrade(state, payload) {
            state.tierUpgrade = payload
        },
        setTierUpgradeErrors(state, payload) {
            state.tierUpgradeError = payload
        },
    },
    actions: {
        async fetchProjects({ commit, } ) {
            const [error, response] = await to(getProjects())
            if (error) {
                commit('setProjectErrors', error)
            } else {
                commit('setProjects', response)
                commit('setProjectErrors', false)
            }
        },
        setOrg({commit,}, org){
            commit('setOrg', org)
        },
        async upgradeToPowerTier({commit,}, updateProfile) {
            const [error, response] = await to(upgradeTier(updateProfile))
            if (error) {
                commit('setTierUpgradeErrors', error)
            } else {
                commit('setTierUpgrade', response)
                commit('setTierUpgradeErrors', false)
            }
        },
        async fetchOrgDetails({commit,}, orgId) {
            const [error, response] = await to(getOrgDetails(orgId))
            if (!error) {
                commit('setCurrentTier', response)
                commit('setOrg', response)
                if (!response?.is_active || response?.is_deleted) {
                    commit('setOrgError', {type: response?.is_deleted ? 'ORG_DELETED' : 'ORG_INACTIVE',})
                }
            }
            else if (error?.response?.data?.code === 404) {
                commit('setOrgError', {type: 'ORG_NOT_FOUND',})
            }
        },
    },
    getters: {
        projects: state => state.projects,
        projectErrors: state => state.projectsError,
        currentProjectId: state => {
            //TODO:: Remove this mock project later as cleanup
            let projectId

            let defaultProject
            if (state.projects.length) {
                // eslint-disable-next-line camelcase
                defaultProject = state.projects.find(({is_default,}) => is_default)

                if (defaultProject && defaultProject.id) {
                    projectId = defaultProject.id
                }
            }

            return projectId
        },
        currentTier: state => state.currentTier,
        orgData: state => state.orgData,
        orgError: state => state.orgError,
        tierUpgrade: state => state.tierUpgrade,
        tierUpgradeError: state => state.tierUpgradeError,
    },
}
