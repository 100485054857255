import BlankPage from 'pages/Blank.vue'

export default [
    {
        path: '/login/oauth2/code/grant',
        name: 'auth-grant',
        component: BlankPage,
        meta: {
            title: 'AuthGrant',
            requiresAuth: true,
        },
    },
    {
        path: '/login/oauth2/code/impersonation',
        name: 'auth-impersonation',
        component: BlankPage,
        meta: {
            title: 'Auth Impersonation',
            requiresAuth: true,
        },
    }
]
