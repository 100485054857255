<style lang="scss" scoped>
    .grayed-text{
        color: $auro-metal-saurus;
    }
</style>

<template>
    <div>
        <div>
            <v-checkbox
                v-model="isSingleZone"
                class="pt-0 mt-0"
                @change="toggleZoneSelect">
                <template #label>
                    <div>
                        {{ isSingleNode ? $t('launchService.singleZoneDeploySingleNode'): $t('launchService.singleZoneDeploy') }}
                        <HelpTooltip>
                            <template #message>
                                <p> {{ $t('launchService.zoneToolTip') }} </p>
                            </template>
                        </HelpTooltip>
                    </div>
                </template>
            </v-checkbox>
        </div>
        <div v-if="isSingleZone">
            <v-row>
                <v-col cols="6">
                    <div class="text-caption grayed-text mb-2 ml-1">
                        {{ $t('launchService.zoneSelectTitle') }}
                    </div>
                    <v-select
                        v-model="currentZone"
                        :items="zones"
                        item-text="name"
                        item-value="name"
                        required
                        :rules="[(val) => !!val || $t('errors.fieldRequired', { field: $t('launchService.zone') })]"
                        :menu-props="{ top: false, offsetY: true }"
                        outlined
                        @change="onZoneSelect" />
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
    import HelpTooltip from 'components/common/HelpTooltip.vue'
    export default {
        name: 'CloudZoneSelection',
        components: { HelpTooltip, },
        props: {
            zones: {
                type: Array,
                required: true,
            },
            isSingleNode: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                isSingleZone: false,
                currentZone: null,
            }
        },
        watch: {
            isSingleNode(newVal){
                this.isSingleZone = !newVal
                this.toggleZoneSelect()
            },
        },
        methods: {
            onZoneSelect() {
                this.$emit('on-zone-select', this.currentZone)
            },
            toggleZoneSelect(){
                if (!this.isSingleZone) {
                    this.currentZone = ''
                } else {
                    const randomIndex = Math.floor(Math.random() * this.zones.length)
                    this.currentZone = this.zones[randomIndex]?.name
                }
                this.onZoneSelect()
            },
        },
        created () {
            // Preselect for multinode topology
            if (!this.isSingleNode) {
                this.isSingleZone = true
                this.toggleZoneSelect()
            }
        },
    }
</script>
