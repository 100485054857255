/* eslint-disable camelcase */
import dbServices from 'api/dbServices'
import { convertToSnakeCase } from 'helpers/snakeCase'
import { CONFIG } from 'config/'
import { isProviderGCP } from 'utils/service'

export async function createService(serviceObject) {
    return dbServices.post('services', serviceObject).then((response) => {
        return response.data
    })
}

export async function fetchServices() {
    return dbServices.get('services').then((response) => {
        return response.data
    }).catch(ex => {
        return ex
    })
}

export async function fetchServiceCredentials(serviceId) {
    return dbServices.get(`services/${serviceId}/security/credentials`).then((response) => {
        return response.data
    }).catch(ex => {
        return ex
    })
}

export async function deleteService(serviceId) {
    return dbServices.delete(`services/${serviceId}`).then(response => response.data)
}

export async function toggleServiceState(serviceId, shouldStartService) {
    return dbServices
        .post(`services/${serviceId}/${shouldStartService ? 'start' : 'stop'}`)
        .then((response) => response.data)
}

export async function scaleServiceNodes(serviceId, nodes) {
    const postBody = {
        nodes,
    }
    return dbServices.post(`services/${serviceId}/nodes`, postBody)
        .then(response => response.data)
}

export async function scaleServiceNodesUpDown(serviceId, size) {
    const postBody = {
        size,
    }
    return dbServices.post(`services/${serviceId}/size`, postBody)
        .then(response => response.data)
}

export async function deleteReplication(serviceId) {
    return dbServices.delete(`services/${serviceId}/replication`).then(response => response.data)
}

export async function fetchReplicas(serviceId) {
    return dbServices.get(`services/${serviceId}/replicas`).then(response => response.data)
}

export async function createReplication(serviceId, primaryServiceId, replicationType) {
    let postBody = {
        'primary_host': primaryServiceId,
        'replication_type': replicationType,
    }
    return dbServices.post(`services/${serviceId}/replication`, postBody).then((response) => response.data)
}

export async function fetchAllowListForService(serviceId) {
    return dbServices.get(
        `services/${serviceId}/security/allowlist`
    ).then(response => response.data)
}

export async function scaleStorageAndIOPs(serviceId, postBody) {
    return dbServices
        .patch(`services/${serviceId}/storage`, postBody)
        .then((response) => response.data)
}

export async function setMaintenanceWindow(serviceId, windowObj) {
    return dbServices.post(`services/${serviceId}/maintenance-window`, windowObj).then(response => { return response.data })
}

export async function attachPrivateConnect(serviceId, projects, provider) {
    if (isProviderGCP(provider)) {
        return attachPrivateConnectProjects(serviceId, projects)
    }
    return attachPrivateLinkAccounts(serviceId, projects)
}

export async function attachPrivateConnectProjects(serviceId, projects) {
    const patchBody = {
        mechanism: CONFIG.ENDPOINT_MECHANISM_PRIVATELINK,
        privateServiceConnect: {
            projects,
        },
    }
    return dbServices
        .patch(`services/${serviceId}/endpoints`, [
            convertToSnakeCase(patchBody)
        ])
        .then((response) => response.data)
}

export async function attachPrivateLinkAccounts(serviceId, accounts) {
    const patchBody = {
        mechanism: CONFIG.ENDPOINT_MECHANISM_PRIVATELINK,
        privateLink: {
            accounts,
        },
    }

    return dbServices
        .patch(`services/${serviceId}/endpoints`, [
            convertToSnakeCase(patchBody)
        ])
        .then((response) => response.data)
}

export function removePrivateConnectProject(serviceId, removeProjectId, existingProjects, provider){
    const keepProjects = existingProjects
        .filter(project => project.id !== removeProjectId)
    // We've to work around right now, due to the API
    // not supporting a Dedicated Delete project API
    return attachPrivateConnect(serviceId, keepProjects, provider)
}

export function disconnectPrivateConnect(serviceId){
    const patchBody = [{ mechanism : CONFIG.ENDPOINT_MECHANISM_NLB,}]
    return dbServices
        .patch(`services/${serviceId}/endpoints`,
            patchBody
        )
        .then((response) => response.data)
}

export function removeSecondaryEndpoint(serviceId, endpointName) {
    return dbServices
        .delete(`services/${serviceId}/endpoints/${endpointName}`)
        .then((response) => response.data)
}

export function addSecondaryEndpoint(serviceId, endpointName) {
    return dbServices
        .post(`services/${serviceId}/endpoints`,
            { 'name': endpointName,}
        )
        .then((response) => response.data)
}
