import User from 'icons/User'
import Monitoring from 'icons/Monitoring'
import Alerts from 'icons/Alerts'
import Security from 'icons/Security'
import Users from 'icons/Users'
import StatusOk from 'icons/StatusOk'
import StatusWarning from 'icons/StatusWarning'
import StatusError from 'icons/StatusError'
import StatusInfo from 'icons/StatusInfo'
import Running from 'icons/Running'
import Stopped from 'icons/Stopped'
import Starting from 'icons/Starting'
import Paused from 'icons/Paused'
import HealthOk from 'icons/HealthOk'
import HealthBad from 'icons/HealthBad'
import HealthAlert from 'icons/HealthAlert'
import ArrowRight from 'icons/ArrowRight'
import ArrowLeft from 'icons/ArrowLeft'
import Close from 'icons/Close'
import Reload from 'icons/Reload'
import Edit from 'icons/Edit'
import Delete from 'icons/Delete'
import Restore from 'icons/Restore'
import QuestionCircle from 'icons/QuestionCircle'
import Table from 'icons/Table'
import Comments from 'icons/Comments'
import Email from 'icons/Email'
import EmailCustom from 'icons/EmailCustom'
import Expand from 'icons/Expand'
import Gear from 'icons/Gear'
import Settings from 'icons/Settings'
import AlertInfo from 'icons/AlertInfo'
import Calendar from 'icons/Calendar'
import GCP from 'icons/GCP'
import AWS from 'icons/AWS'
import QPS from 'icons/QPS'
import CPU from 'icons/CPU'
import Copy from 'icons/Copy'
import Bell from 'icons/Bell'
import Logs from 'icons/Logs'
import TransactionsProcessing from 'icons/TransactionsProcessing'
import InteractiveAnalytics from 'icons/InteractiveAnalytics'
import SingleNode from 'icons/SingleNode'
import Replicated from 'icons/Replicated'
import Distributed from 'icons/Distributed'
import GoogleCloud from 'icons/GoogleCloud'
import Belgium from 'icons/countries/Belgium'
import Canada from 'icons/countries/Canada'
import Finland from 'icons/countries/Finland'
import Singapore from 'icons/countries/Singapore'
import US from 'icons/countries/US'
import Brazil from 'icons/countries/Brazil'
import Ireland from 'icons/countries/Ireland'
import France from 'icons/countries/France'
import Hongkong from 'icons/countries/Hongkong'
import India from 'icons/countries/India'
import Italy from 'icons/countries/Italy'
import SouthAfrica from 'icons/countries/SouthAfrica'
import SouthKorea from 'icons/countries/SouthKorea'
import Sweden from 'icons/countries/Sweden'
import Taiwan from 'icons/countries/Taiwan'
import Germany from 'icons/countries/Germany'
import Netherlands from 'icons/countries/Netherlands'
import UK from 'icons/countries/UK'
import Japan from 'icons/countries/Japan'
import Australia from './countries/Australia'
import Bahrain from './countries/Bahrain'
import Switzerland from './countries/Switzerland'
import Indonesia from './countries/Indonesia'
import HourSlider from 'icons/temp/HourSlider'
import LaunchRocketVue from 'icons/LaunchRocket'
import Minus from 'icons/Minus'
import Plus from 'icons/Plus'
import ServiceLogs from 'icons/service/Logs'
import ServiceAlerts from 'icons/service/Alerts'
import ServiceMonitoring from 'icons/service/Monitoring'
import ServiceOverview from 'icons/service/Overview'
import Dashboard from 'icons/Dashboard'
import ScalingUp from 'icons/ScalingUp'
import ScalingDown from 'icons/ScalingDown'
import Primary from 'icons/Primary'
import Replica from 'icons/Replica'
import ServerCritical from 'icons/ServerCritical'
import ServerOk from 'icons/ServerOk'
import ServerProvisioning from 'icons/ServerProvisioning'
import ConnectivityBad from 'icons/ConnectivityBad'
import Region from 'icons/Region.vue'
import UpTime from 'icons/UpTime.vue'
import UserManagement from 'icons/settings/UserManagement.vue'
import ConfigManager from 'icons/settings/ConfigManager.vue'
import Firewall from 'icons/settings/Firewall.vue'
import Policies from 'icons/settings/Policies.vue'
import Notification from 'icons/settings/Notification.vue'
import Channel from 'icons/settings/Channel.vue'
import GoBack from 'icons/GoBack.vue'
import MapMarker from 'icons/MapMarker'
import DbQuery from 'icons/DbQuery'
import MinusDisabled from 'icons/MinusDisabled.vue'
import PlusDisabled from 'icons/PlusDisabled.vue'
import ServiceReady from 'icons/serviceStatus/Ready.vue'
import ServiceFailed from 'icons/serviceStatus/Failed.vue'
import ServiceStopped from 'icons/serviceStatus/Stopped.vue'
import ServicePending from 'icons/serviceStatus/Pending.vue'
import ServicePendingDelete from 'icons/serviceStatus/PendingDelete.vue'
import ServicePendingScale from 'icons/serviceStatus/PendingScale.vue'
import ServicePendingStart from 'icons/serviceStatus/PendingStart.vue'
import ServicePendingStop from 'icons/serviceStatus/PendingStop.vue'
import Unknown from 'icons/serviceStatus/Unknown.vue'
import Autonomous from 'icons/Autonomous.vue'
import NoRegion from 'icons/NoRegion.vue'
import Geospatial from 'icons/Geospatial.vue'
import Exclamation from 'icons/Exclamation.vue'
import TechPreview from 'icons/TechPreview.vue'
import PrivateConnect from 'icons/PrivateConnect.vue'
import Remove from 'icons/Remove.vue'
import DbLaunch from 'icons/DbLaunch.vue'
import DbMonitor from 'icons/DbMonitor.vue'
import Integrations from 'icons/Integrations.vue'
import Qlik from 'icons/partnerIcons/Qlik.vue'
import Striim from 'icons/partnerIcons/Striim.vue'
import MindsDB from 'icons/partnerIcons/MindsDB.vue'
import Datadog from 'icons/partnerIcons/Datadog.vue'
import HashiCorp from 'icons/partnerIcons/HashiCorp.vue'
import Liquibase from 'icons/partnerIcons/Liquibase.vue'
import Hasura from 'icons/partnerIcons/Hasura.vue'
import EndpointsHealthy from 'icons/EndpointsHealthy.vue'
import EndpointsUnHealthy from 'icons/EndpointsUnHealthy.vue'
import SilencedAlert from 'icons/SilencedAlert.vue'
import Satellite from 'icons/Satellite.vue'
import AiCopilot from 'icons/AiCopilot.vue'
import CloudBackupIcon from 'icons/CloudBackupIcon.vue'
import ApiIcon from 'icons/ApiIcon.vue'
import MicrosoftAzure from 'icons/MicrosoftAzure.vue'
import Azure from 'icons/Azure.vue'

export default {
    user: {
        component: User,
    },
    monitoring: {
        component: Monitoring,
    },
    alerts: {
        component: Alerts,
    },
    security: {
        component: Security,
    },
    users: {
        component: Users,
    },
    statusOk: {
        component: StatusOk,
    },
    statusWarning: {
        component: StatusWarning,
    },
    statusError: {
        component: StatusError,
    },
    running: {
        component: Running,
    },
    stopped: {
        component: Stopped,
    },
    starting: {
        component: Starting,
    },
    paused: {
        component: Paused,
    },
    healthOk: {
        component: HealthOk,
    },
    healthBad: {
        component: HealthBad,
    },
    healthAlert: {
        component: HealthAlert,
    },
    arrowRight: {
        component: ArrowRight,
    },
    arrowLeft: {
        component: ArrowLeft,
    },
    close: {
        component: Close,
    },
    reload: {
        component: Reload,
    },
    edit: {
        component: Edit,
    },
    delete: {
        component: Delete,
    },
    restore: {
        component: Restore,
    },
    statusInfo: {
        component: StatusInfo,
    },
    questionCircle: {
        component: QuestionCircle,
    },
    table: {
        component: Table,
    },
    comments: {
        component: Comments,
    },
    email: {
        component: Email,
    },
    emailCustom: {
        component: EmailCustom,
    },
    expand: {
        component: Expand,
    },
    gear: {
        component: Gear,
    },
    settings: {
        component: Settings,
    },
    alertInfo: {
        component: AlertInfo,
    },
    gcp: {
        component: GCP,
    },
    aws: {
        component: AWS,
    },
    qps: {
        component: QPS,
    },
    cpu: {
        component: CPU,
    },
    copy: {
        component: Copy,
    },
    calendar: {
        component: Calendar,
    },
    bell: {
        component: Bell,
    },
    logs: {
        component: Logs,
    },
    transactionsProcessing: {
        component: TransactionsProcessing,
    },
    interactiveAnalytics: {
        component: InteractiveAnalytics,
    },
    singleNode: {
        component: SingleNode,
    },
    replicated: {
        component: Replicated,
    },
    distributed: {
        component: Distributed,
    },
    googleCloud: {
        component: GoogleCloud,
    },
    belgium: {
        component: Belgium,
    },
    canada: {
        component: Canada,
    },
    finland: {
        component: Finland,
    },
    singapore: {
        component: Singapore,
    },
    us: {
        component: US,
    },
    brazil: {
        component: Brazil,
    },
    ireland: {
        component: Ireland,
    },
    france: {
        component: France,
    },
    hongkong: {
        component: Hongkong,
    },
    india: {
        component: India,
    },
    italy: {
        component: Italy,
    },
    southAfrica: {
        component: SouthAfrica,
    },
    southKorea: {
        component: SouthKorea,
    },
    sweden: {
        component: Sweden,
    },
    taiwan: {
        component: Taiwan,
    },
    japan: {
        component: Japan,
    },
    netherlands: {
        component: Netherlands,
    },
    germany: {
        component: Germany,
    },
    uk: {
        component: UK,
    },
    australia: {
        component: Australia,
    },
    indonesia: {
        component: Indonesia,
    },
    bahrain: {
        component: Bahrain,
    },
    switzerland: {
        component: Switzerland,
    },
    hourSlider: {
        component: HourSlider,
    },
    launchRocket: {
        component: LaunchRocketVue,
    },
    plus: {
        component: Plus,
    },
    minus: {
        component: Minus,
    },
    serviceAlerts: {
        component: ServiceAlerts,
    },
    serviceLogs: {
        component: ServiceLogs,
    },
    serviceMonitoring: {
        component: ServiceMonitoring,
    },
    serviceOverview: {
        component: ServiceOverview,
    },
    dashboard: {
        component: Dashboard,
    },
    scalingUp: {
        component: ScalingUp,
    },
    scalingDown: {
        component: ScalingDown,
    },
    primary: {
        component: Primary,
    },
    replica: {
        component: Replica,
    },
    serverCritical: {
        component: ServerCritical,
    },
    serverOk: {
        component: ServerOk,
    },
    serverProvisioning: {
        component: ServerProvisioning,
    },
    connectivityBad: {
        component: ConnectivityBad,
    },
    region: {
        component: Region,
    },
    upTime: {
        component: UpTime,
    },
    userManagement: {
        component: UserManagement,
    },
    configManager: {
        component: ConfigManager,
    },
    firewall: {
        component: Firewall,
    },
    policies: {
        component: Policies,
    },
    notification: {
        component: Notification,
    },
    channel: {
        component: Channel,
    },
    goBack: {
        component: GoBack,
    },
    mapMarker: {
        component: MapMarker,
    },
    dbQuery: {
        component: DbQuery,
    },
    minusDisabled: {
        component: MinusDisabled,
    },
    plusDisabled: {
        component: PlusDisabled,
    },
    serviceReady: {
        component: ServiceReady,
    },
    serviceFailed: {
        component: ServiceFailed,
    },
    serviceStopped: {
        component: ServiceStopped,
    },
    servicePending: {
        component: ServicePending,
    },
    servicePendingDelete: {
        component: ServicePendingDelete,
    },
    servicePendingScale: {
        component: ServicePendingScale,
    },
    servicePendingStart: {
        component: ServicePendingStart,
    },
    servicePendingStop: {
        component: ServicePendingStop,
    },
    unknown: {
        component: Unknown,
    },
    autonomous: {
        component: Autonomous,
    },
    noRegion: {
        component: NoRegion,
    },
    geospatial: {
        component: Geospatial,
    },
    exclamation: {
        component: Exclamation,
    },
    techPreview: {
        component: TechPreview,
    },
    privateConnect: {
        component: PrivateConnect,
    },
    remove: {
        component: Remove,
    },
    dbLaunch: {
        component: DbLaunch,
    },
    dbMonitor: {
        component: DbMonitor,
    },
    integrations: {
        component: Integrations,
    },
    qlik: {
        component: Qlik,
    },
    striim: {
        component: Striim,
    },
    mindsDB: {
        component: MindsDB,
    },
    datadog: {
        component: Datadog,
    },
    hashiCorp: {
        component: HashiCorp,
    },
    liquibase: {
        component: Liquibase,
    },
    hasura: {
        component: Hasura,
    },
    endpointsHealthy: {
        component: EndpointsHealthy,
    },
    endpointsUnHealthy: {
        component: EndpointsUnHealthy,
    },
    silencedAlert: {
        component: SilencedAlert,
    },
    satellite: {
        component: Satellite,
    },
    aiCopilot: {
        component: AiCopilot,
    },
    cloudBackupIcon: {
        component: CloudBackupIcon,
    },
    apiIcon: {
        component: ApiIcon,
    },
    microsoftAzure: {
        component: MicrosoftAzure,
    },
    azure: {
        component: Azure,
    },
}
