import Vue from 'vue'
import 'utils/helpers'
import 'utils/formatters'
import 'utils/config'
import 'plugins/axios'
import 'plugins/moment'
import 'plugins/typy'
import 'plugins/shortkey'
import 'plugins/filters'
import 'plugins/lodash'
import 'plugins/apexcharts'
import 'plugins/unleash'
import 'plugins/cronlight.js'
import 'plugins/sentry.js'
import vuetify from 'plugins/vuetify'
import i18n from 'plugins/i18n'
import router from 'router'
import App from 'App'
import store from 'store'
import commonComponents from 'components/common'
import * as monitoringComponents from 'components/monitoring/widgets/'

Object.keys(monitoringComponents).forEach(name => {
    Vue.component(name, monitoringComponents[name])
})
Object.keys(commonComponents).forEach(name => {
    Vue.component(name, commonComponents[name])
})

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    vuetify,
    store,
    render: h => h(App),
}).$mount('#app')
