import UserProfile from 'pages/UserProfile'

export default [
    {
        path: '/user-profile/:tab?',
        name: 'userProfile',
        component: UserProfile,
        meta: {
            requiresAuth: true,
            title: 'Profile',
        },
    }
]
