import billing from 'api/billing'
//TODO:: replace this when #203 is merged
import { camelize } from 'helpers/camelCase'
import { convertToSnakeCase } from 'helpers/snakeCase'

export function getInstancesCosts(params){
    return billing.get('prices/instance', params).then(response=> camelize(response.data))
}

export function getStorageCost(params){
    return billing.get('prices/storage', params).then(response=> camelize(response.data))
}

export function getIOPSCost(params){
    return billing.get('prices/iops', params).then(response=> camelize(response.data))
}

export function getThroughputCost(params){
    return billing.get('prices/throughput', params).then(response=> camelize(response.data))
}

export function getEndpointCost(params) {
    return billing.get('prices/endpoint', params).then((response) => camelize(response.data))
}

export function getBillingUsageDetails(){
    return billing.get('usage/preview').then(response=> camelize(response.data))
}

export function getBillingRecord(noCache){
    return billing.get(`account${noCache ? '?no-cache=' + new Date().getTime() : ''}`).then(response=> camelize(response.data))
}

export function getPaymentProfile(){
    return billing.get('payment/profile').then(response=> camelize(response.data.result || response.data))
}

export function getCountries(){
    return billing.get('locale/country').then(response=> camelize(response.data))
}

export function getStatesForCountry(params){
    return billing.get('locale/state-province',params ).then(response=> camelize(response.data))
}

export function getSpendingByService(serviceId){
    return billing.get(`usage/service/${serviceId}`).then(response=> camelize(response.data))
}

export function createPaymentProfile(paymentProfile){
    return billing.post('payment/profile', {...convertToSnakeCase(paymentProfile), }).then(response=> camelize(response.data.result))
}

export function getSpendingForAllServices(){
    return billing.get('usage/service').then(response=> camelize(response.data))
}

export function getSpendingFoService(serviceId){
    return billing.get(`usage/service/${serviceId}`).then(response=> camelize(response.data))
}

export function getInvoices(){
    return billing.get('invoices').then(response=> camelize(response.data))
}

export function getInvoiceById(invoiceId) {
    return billing.get(`invoices/${invoiceId}`).then(response => camelize(response.data))
}

export function getInvoicePdf(invoiceId) {
    return billing.get(`invoices/${invoiceId}/pdf?as=attachment`).then(response => response.data)
}

export function setupIntent() {
    return billing.post('payment/setup-intent').then((response) => camelize(response.data))
}
