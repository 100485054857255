<style lang="scss" scoped>
    .topology {
        border-bottom: 1px solid #aaa;
    }
</style>

<template>
    <v-tooltip
        :disabled="!servers.length && !topology"
        :nudge-left="nudgeLeft"
        open-delay="400"
        color="rgba(0, 53, 69, 0.9)"
        top>
        <template #activator="{ on }">
            <div v-on="on">
                <slot></slot>
            </div>
        </template>
        <div>
            <div
                v-if="topology"
                class="topology pb-1 mb-1 font-weight-bold">
                <icon-sprite-sheet
                    v-if="topologyStatus"
                    class="status-icon mr-1"
                    size="16"
                    :frame="topologyStatus"
                    :color="
                        topologyStatus === 'STARTING' ||
                            topologyStatus === 'STOPPING' ||
                            topologyStatus === 'STOPPED' ||
                            topologyStatus === 'SCALINGUP' ||
                            topologyStatus === 'SCALINGDOWN'
                            ? 'white'
                            : ''
                    ">
                    health
                </icon-sprite-sheet>
                {{ topology.name }}
                <span class="grey--text text--lighten-1">
                    {{ $help.getTopologyName(topology.topology, true) }}
                </span>
                <span
                    v-if="topology.inboundReplication && topology.inboundReplication.internalReplica === null"
                    class="d-block pl-7 color red--text text--accent-2 font-weight-regular">
                    {{ $tc('errors.replicationNotRunning') }}
                </span>
            </div>
            <div
                v-if="!servers.length"
                class="text-center">
                {{ $tc('info.noServers') }}
            </div>
            <div
                v-for="server in servers"
                :key="server.id">
                <icon-sprite-sheet
                    class="status-icon mr-1"
                    size="13"
                    :frame="server.serverStatus"
                    :color="server.serverStatus === 'STARTING' || server.serverStatus === 'STOPPING' ? 'white' : ''">
                    server
                </icon-sprite-sheet>
                <template v-if="server.serverStatus === 'CRITICAL' || server.serverStatus === 'WARNING'">
                    <!-- eslint-disable vue/no-v-html -->
                    <span
                        :class="[`color ${server.serverStatus === 'CRITICAL' ? 'red--text text--accent-2' : 'text-warning'}`]"
                        v-html="
                            $t(`serverStatusInfo.${server.serverStatusInfo ? server.serverStatusInfo.toLowerCase() : 'error'}`, {
                                name: `<span class='white--text'>${server.name}</span>`,
                                time: $moment(server.statusChangedAt).fromNow()
                            })
                        "></span>
                    <!--eslint-enable-->
                    <inbound-replication-info
                        :topology="topology"
                        :server="server"
                        class="d-block pl-7" />
                </template>

                <template v-else-if="server.serverStatus === 'OK'">
                    {{ server.name }}
                    <span class="more-info grey--text text--lighten-1">
                        <span v-if="topology && topology.topologyType === 'galera' && server.serverRole === 'server'">
                            {{ $tc(`serverStatusInfo.${server.serverStatusInfo ? server.serverStatusInfo.toLowerCase() : 'error'}`) }}
                            ({{ server.serverStatusInfo }})
                        </span>
                        <span v-else-if="server.metadata.primaryReplicaRole">
                            <icon-sprite-sheet
                                :frame="server.metadata.primaryReplicaRole.toUpperCase()"
                                size="13"
                                color="white"
                                class="status-icon mb-1">
                                server
                            </icon-sprite-sheet>
                        </span>
                        <span v-else>
                            {{ $t(`serverRole.${server.serverRole}`) }}
                        </span>
                    </span>
                    <v-tooltip
                        v-if="server.metadata && $typy(server.metadata.secondsBehindMaster).isNumber"
                        top>
                        <template #activator="{ on }">
                            <span
                                class="color text-text-subtle"
                                v-on="on">
                                (+{{
                                    server.metadata.secondsBehindMaster > 0
                                        ? server.metadata.secondsBehindMaster.toFixed(0)
                                        : server.metadata.secondsBehindMaster
                                }}s)
                            </span>
                        </template>
                        <div>{{ $t('lag_in_secs') }}</div>
                    </v-tooltip>
                    <v-tooltip
                        v-if="server.metadata && $typy(server.metadata.sendQueue || server.metadata.receiveQueue).isNumber"
                        top>
                        <template #activator="{ on }">
                            <span
                                class="color text-text-subtle"
                                v-on="on">
                                (&#8593;{{ server.metadata.sendQueue }}/&#8595;{{ server.metadata.receiveQueue }})
                            </span>
                        </template>
                    </v-tooltip>

                    <inbound-replication-info
                        :topology="topology"
                        :server="server"
                        class="d-block pl-7" />
                </template>

                <template v-else>
                    <!-- TODO: Refactor this post MS3, remove the legacy v-html and use i18n directive -->
                    <span
                        class="more-info grey--text text--lighten-1"
                        v-html="
                            $t(`serverStatusInfo.${server.serverStatusInfo ? server.serverStatusInfo.toLowerCase() : 'error'}`, {
                                name: `<span class='white--text'>${server.name}</span>`,
                                time: $moment(server.statusChangedAt).fromNow()
                            })
                        "></span>
                </template>
            </div>
        </div>
    </v-tooltip>
</template>

<script>
    export default {
        name: 'StatusTooltip',
        props: {
            topology: {
                type: Object,
                default: () => {},
            },
            servers: {
                type: Array,
                default: () => [],
            },
            nudgeLeft: {
                type: Number,
                default: 90,
            },
        },
        computed: {
            topologyStatus(){
                return  this.topology.topologyStatus || this.topology.status
            },
        },
    }
</script>
