<style lang="scss" scoped>
    .time-range-input {
        max-width: 242px;
        font-size: 14px;
        &.v-input ::v-deep .v-input__control {
            .v-input__prepend-inner {
                margin-top: 6px;
            }
            .v-input__slot {
                min-height: 36px;
            }
        }
    }

    .time-range-date-select {
        font-size: 14px;
    }
    .inner-icon {
        width: 16px;
    }
</style>

<template>
    <div>
        <v-select
            ref="timerange"
            v-model="selectedRange"
            :items="items"
            append-icon=""
            item-text="label"
            item-value="value"
            height="36"
            hide-details
            return-object
            single-linelabel="Select Item"
            outlined
            dense
            attach
            class="time-range-input std std--dropdown"
            :menu-props="{ bottom: true, left: true, offsetY: true, contentClass: 'history-time-range-slt-dropdown std--dropdown-list max-height-330' }">
            <template #prepend-inner>
                <v-icon class="inner-icon">
                    $vuetify.icons.calendar
                </v-icon>
            </template>
            <template #selection="{ item }">
                <div v-if="item.disabled">
                    {{ customRangeTxt }}
                </div>
                <div v-else>
                    {{ selectedRange.label }}
                </div>
            </template>
            <template
                v-if="hasCustomRange"
                #append-item>
                <div class="d-flex px-3">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :return-value.sync="customTimeRange"
                        :close-on-content-click="false"
                        :close-on-click="false"
                        min-width="290px"
                        nudge-top="-10"
                        nudge-left="10"
                        top
                        internal-activator>
                        <template #activator="{ on, attrs }">
                            <v-text-field
                                v-model="customRangeTxt"
                                v-bind="attrs"
                                class="pa-0 time-range-date-select"
                                hide-details="auto"
                                outlined
                                dense
                                readonly
                                v-on="on">
                                <template #prepend-inner>
                                    <v-icon class="inner-icon">
                                        $vuetify.icons.calendar
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker
                            v-model="customTimeRange"
                            color="primary"
                            range
                            no-title
                            scrollable>
                            <v-spacer />
                            <v-btn
                                text
                                @click="menu = false">
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-btn
                                color="primary"
                                :disabled="customTimeRange.length <= 1"
                                depressed
                                rounded
                                @click="onCustomRangeChange(customTimeRange)">
                                {{ $t('ok') }}
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
    export default {
        name: 'DateRangePicker',
        props: {
            hasCustomRange: {
                type: Boolean,
                default () {
                    return false
                },
            },
        },
        data () {
            return {
                timeRange: [],
                customTimeRange: [],
                menu: false,
                selectedRange: {
                    label: '',
                    value: [],
                },
                items: [
                    { label: this.$t('today'), value: ['startOfToday', 'now'], },
                    { label: this.$t('yesterday'), value: ['now-1d/d', 'startOfToday'], },
                    { label: this.$t('lastTwoDays'), value: ['now-2d', 'now'], },
                    { label: this.$t('lastWeek'), value: ['now-1w', 'now'], },
                    { label: this.$t('lastTwoWeeks'), value: ['now-2w', 'now'], },
                    { label: this.$t('lastMonth'), value: ['now-1m', 'now'], }
                ],
            }
        },
        computed: {
            customRangeTxt () {
                const dateFormat = 'YYYY-MM-DD'
                return `${this.timeRange[0].format(dateFormat)} to ${this.timeRange[1].format(dateFormat)}`
            },
        },
        watch: {
            selectedRange (val) {
                this.onDateRangeSelectChanged(val)
            },
            timeRange () {
                this.$emit('change', {
                    from: this.timeRange[0].unix(),
                    to: this.timeRange[1].unix(),
                })
            },
        },
        methods: {
            onDateRangeSelectChanged (range) {
                // Sort to display smaller dates first
                this.timeRange = [
                    this.convertTextToTime(range.value[0]),
                    this.convertTextToTime(range.value[1])
                ].sort((a, b) => a.unix() - b.unix())
                this.customTimeRange = [this.timeRange[0].toISOString(), this.timeRange[1].toISOString()]
            },
            onCustomRangeChange (range) {
                this.$refs.menu.save(range)
                const customRange = this.items.find(item => item.disabled)
                customRange.value = range
                this.selectedRange = { ...customRange, }
                this.closeSelect()
            },
            closeSelect () {
                this.$refs.timerange.blur()
            },
            convertTextToTime (timeText) {
                let date
                switch (timeText) {
                    case 'now':
                        date = this.$moment()
                        break
                    case 'now-1m':
                        date = this.$moment().subtract(1, 'months')
                        break
                    case 'now-2d':
                        date = this.$moment().subtract(2, 'days')
                        break
                    case 'now-1w':
                        date = this.$moment().subtract(1, 'week')
                        break
                    case 'now-2w':
                        date = this.$moment().subtract(2, 'week')
                        break
                    case 'now-1d/d':
                        date = this.$moment().subtract(1, 'day').startOf('day')
                        break
                    case 'startOfToday':
                        date = this.$moment().startOf('day')
                        break
                    default:
                        date = this.$moment(timeText).utc()
                        break
                }
                return date
            },
        },
        created () {
            this.selectedRange = this.items[0]

            if (this.hasCustomRange) {
                this.items.push({ label: this.$t('customRange'), value: [], disabled: true, })
                this.customTimeRange = this.timeRange
            }
        },
    }
</script>
