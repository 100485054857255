<style lang="scss" scoped>
.minimized-icon-boxes {
    .right-card {
        border-radius: 10px 0px 0px 10px;
    }
    .left-card {
        border-radius: 0px 10px 10px 0px;
    }
    div.oversize-min-icon-box {
        border: 1px solid #e8eef1;
    }
    .selection-status {
        float: right;
        padding-top: 0.3rem;
    }
    .icon-title {
        font-weight: 500;
        font-size: 1rem;
    }
}
</style>
<template>
    <v-tooltip
        :max-width="300"
        bottom>
        <template #activator="{ on, attrs }">
            <div
                v-bind="attrs"
                :class="[
                    isPositionRight ? 'right-card' : 'left-card',
                    'pa-1',
                    'clickable-element',
                    (isSelected && 'highlight-instance-row') || 'oversize-min-icon-box',
                    {'opacity-50percent': type === 'analytical'}
                ]"
                :data-auto="dataAutoPrefix('card')"
                v-on="on"
                @click="onClick()">
                <span class="pl-4">
                    <v-icon
                        :size="iconSize"
                        class="mr-3">{{ icon }}</v-icon>
                </span>
                <span
                    :data-auto="dataAutoPrefix('title')"
                    class="icon-title mr-2">{{
                        title
                    }}</span>
                <TechPreview
                    v-if="type === 'analytical'"
                    :label="$t('comingSoon')" />
                <span class="selection-status"><v-icon
                    v-if="isSelected"
                    size="13"
                    class="mr-3 text-right">$vuetify.icons.statusOk</v-icon></span>
            </div>
        </template>
        <span>{{ tooltip }}</span>
    </v-tooltip>
</template>
<script>
    import TechPreview from 'components/common/TechPreview.vue'

    const MinimizedDatabaseTypeCard = {
        name: 'minimized-database-type-card',
        components: {
            TechPreview,
        },
        props: {
            type: String,
            title: String,
            icon: String,
            id: String,
            selectedId: String,
            iconSize: Number,
            parentDataAutoPrefix: Function,
            isPositionRight: Boolean,
            tooltip: String,
        },
        computed: {
            isSelected() {
                return this.id === this.selectedId
            },
        },
        methods: {
            onClick() {
                this.$emit('handleDatabaseRequirementSelection', {id: this.id, type: this.type,})
            },
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(this.type + '-min-' + string)
            },
        },
    }
    export default MinimizedDatabaseTypeCard
</script>
