import Vue from 'vue'
import i18n from 'plugins/i18n'
import { enableMFA, disableMFA, getUserById } from 'services/users'
import to from 'await-to-js'

export default {
    state: {
        isLoggedIn: false,
        user: {
            id: '',
            firstName: '',
            lastName: '',
            name: '',
            email: '',
            isEmployee: '',
        },
        role: 'admin',
        dashboards: [],
    },
    mutations: {
        setUser (state, user = {}) {
            state.isLoggedIn = true
            state.user.id = user.user_id || user.id
            state.user.name = `${user.first_name} ${user.last_name}`
            state.user.firstName = user.first_name
            state.user.lastName = user.last_name
            state.user.email = user.email
            state.user.isEmployee = user.employee
        },
        changeUser (state, { name, email, }) {
            if (name) state.name = name
            if (email) state.email = email
        },
        dashboards (state, dashboards) {
            state.dashboards = dashboards
        },
    },
    actions: {
        async setUser ({ commit, }, userObj) {
            commit('setUser', userObj)
            commit('hideOverlay')
        },
        async fetchUserDashboards ({ commit, state, }) {
            if (!state.isLoggedIn) return

            try {
                const res = await Vue.axios.get(`/api/users/${state.id}/dashboards`)
                const dashboards = res.data

                commit('dashboards', dashboards)
            } catch (error) {
                commit('showMessage', {
                    text: i18n.t('errors.fetchUserDashboards'),
                    type: 'error',
                })
            }
        },
        async fetchUserById({commit,}, id) {
            const [error, response] = await to(getUserById(id, false))
            if (error) {
                commit('showMessage', { text: error.response.data.errors[0].message, type: common.messageType.ERROR, })
            } else {
                commit('setUser', response)
            }
        },
        async enableMFA({ commit, }) {
            const [error] = await to(enableMFA())
            if (error) {
                commit('showMessage', {
                    text: i18n.t('errors.unknown'),
                    type: 'error',
                })
                return false
            }
            return true
        },
        async disableMFA({ commit, }) {
            const [error] = await to(disableMFA())
            if (error) {
                commit('showMessage', {
                    text: i18n.t('errors.unknown'),
                    type: 'error',
                })
                return false
            }
            return true
        },
    },
    getters: {
        user: (state) => state.user,
        userDashboards: (state) => state.dashboards,
    },
}
