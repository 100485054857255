import { render, staticRenderFns } from "./Alerts.vue?vue&type=template&id=77072abb"
import script from "./Alerts.vue?vue&type=script&lang=js"
export * from "./Alerts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77072abb')) {
      api.createRecord('77072abb', component.options)
    } else {
      api.reload('77072abb', component.options)
    }
    module.hot.accept("./Alerts.vue?vue&type=template&id=77072abb", function () {
      api.rerender('77072abb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Alerts.vue"
export default component.exports