import Vue from 'vue'
import base from 'api/base'

const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL

export default {
    get (uri, options = {}) {
        return Vue.axios.get(`${baseUrl}/${uri}`, {
            ...options,
        })
    },
    post (uri, body, options = {}) {
        return Vue.axios.post(`${baseUrl}/${uri}`, body, {
            ...options,
        })
    },
    postWithHeader (uri, body, options = {}) {
        return base.post(`${baseUrl}/${uri}`, body, {
            ...options,
        })
    },
}
