<style scoped>
    .s0 { fill: url(#g1) }
    .s1 { fill: #0078d4 }
    .s2 { fill: url(#g2) }
    .s3 { fill: url(#g3) }
    .s4 { fill: #2b98e1 }
</style>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-0.02 0.5 271.63 83">
        <defs>
            <linearGradient
                id="g1"
                x2="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(-27.041,79.886,-23.131,-7.83,38.828,6.688)">
                <stop
                    offset="0"
                    stop-color="#114a8b" />
                <stop
                    offset="1"
                    stop-color="#0669bc" />
            </linearGradient>
            <linearGradient
                id="g2"
                x2="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(-6.255,2.115,-2.552,-7.548,47.275,43.917)">
                <stop
                    offset="0"
                    stop-color="#000000"
                    stop-opacity=".3" />
                <stop
                    offset=".071"
                    stop-color="#000000"
                    stop-opacity=".2" />
                <stop
                    offset=".321"
                    stop-color="#000000"
                    stop-opacity=".1" />
                <stop
                    offset=".623"
                    stop-color="#000000"
                    stop-opacity=".05" />
                <stop
                    offset="1"
                    stop-color="#000000"
                    stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="g3"
                x2="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(29.683,79.081,-28.892,10.845,43.835,4.358)">
                <stop
                    offset="0"
                    stop-color="#3ccbf4" />
                <stop
                    offset="1"
                    stop-color="#2892df" />
            </linearGradient>
        </defs>
        <path
            class="s0"
            d="m29.3 0.5h26.1l-27.1 80.1q-0.2 0.6-0.5 1.2-0.4 0.5-1 0.9-0.5 0.4-1.1 0.6-0.6 0.2-1.3 0.2h-20.3q-1 0-1.8-0.5-0.9-0.5-1.5-1.3-0.6-0.8-0.8-1.8-0.1-1 0.2-1.9l25.2-74.6q0.2-0.6 0.6-1.2 0.4-0.5 0.9-0.9 0.5-0.4 1.2-0.6 0.6-0.2 1.2-0.2z" />
        <path
            class="s1"
            d="m67.2 54.3h-41.3q-0.6 0-1.1 0.3-0.5 0.3-0.7 0.9-0.2 0.5-0.1 1.1 0.2 0.6 0.6 1l26.5 24.7q0.3 0.3 0.6 0.5 0.4 0.2 0.7 0.4 0.4 0.1 0.8 0.2 0.4 0.1 0.8 0.1h23.3z" />
        <path
            class="s2"
            d="m29.3 0.5q-0.6 0-1.2 0.2-0.7 0.2-1.2 0.6-0.5 0.4-0.9 1-0.4 0.5-0.6 1.1l-25.1 74.5q-0.4 1-0.3 2 0.2 1 0.8 1.8 0.6 0.8 1.4 1.3 0.9 0.5 2 0.5h20.7q0.6-0.1 1.2-0.4 0.5-0.3 0.9-0.6 0.5-0.4 0.8-0.9 0.4-0.5 0.6-1l5-14.8 17.9 16.7q0.3 0.2 0.6 0.4 0.3 0.2 0.6 0.3 0.4 0.1 0.7 0.2 0.4 0.1 0.7 0.1h23.3l-10.2-29.2h-29.8l18.3-53.8z" />
        <path
            class="s3"
            d="m62.6 3.4q-0.2-0.7-0.6-1.2-0.4-0.5-0.9-0.9-0.5-0.4-1.2-0.6-0.6-0.2-1.2-0.2h-29.1q0.7 0 1.3 0.2 0.6 0.2 1.2 0.6 0.5 0.4 0.9 0.9 0.4 0.5 0.6 1.2l25.2 74.6q0.3 0.9 0.1 1.9-0.1 1-0.7 1.8-0.6 0.8-1.5 1.3-0.9 0.5-1.9 0.5h29.1q1 0 1.8-0.5 0.9-0.5 1.5-1.3 0.6-0.8 0.8-1.8 0.1-1-0.2-1.9z" />
        <path
            class="s4"
            d="m127.8 17.4l18.3 47.6h-6.2l-5-13.3h-20.2l-4.8 13.3h-6.2l18.3-47.6zm-11.2 29.2h16.5l-7.5-20.2q-0.4-1-0.7-3.2h-0.2q-0.3 2-0.7 3.2zm59.5-15.7v1.6l-20.1 27.8h19.9v4.6h-27.9v-1.7l20.1-27.6h-18.2v-4.7zm34.5 0v34h-5.4v-5.4h-0.2q-3.4 6.2-10.5 6.2-12.1 0-12.1-14.5v-20.3h5.4v19.5q0 10.7 8.2 10.7 4 0 6.6-2.9 2.6-2.9 2.6-7.7v-19.6zm28.7-0.1v5.6q-1.4-1.1-4.1-1.1-3.5 0-5.9 3.3-2.3 3.3-2.3 9v17.3h-5.4v-34h5.4v7h0.1q1.2-3.6 3.6-5.6 2.4-2 5.3-2 2.2 0 3.3 0.5zm32.3 15.6v2.9h-24q0.2 5.7 3.1 8.7 2.9 3.1 8 3.1 5.8 0 10.6-3.8v5.2q-4.5 3.2-11.9 3.2-7.2 0-11.3-4.6-4.1-4.7-4.1-13.1 0-7.9 4.5-12.9 4.5-5 11.1-5 6.7 0 10.4 4.3 3.6 4.3 3.6 12zm-23.9-1.7h18.3q0-4.8-2.2-7.4-2.3-2.6-6.3-2.6-3.8 0-6.5 2.8-2.7 2.7-3.3 7.2z" />
    </svg>
</template>

<script>
    export default {}
</script>
