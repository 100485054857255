<template>
    <div>
        <v-row>
            <v-col class="mt-0 pt-0">
                <div class="pb-2">
                    {{ $tc('iops-volume-type') }}
                    <HelpToolTip v-if="storageTypeInfo && storageTypeInfo.length">
                        <template #message>
                            <div>{{ storageTypeInfo }}</div>
                        </template>
                    </HelpToolTip>
                </div>
                <v-radio-group
                    v-model="currentVolumeType"
                    class="mt-0"
                    row
                    @change="onVolumeTypeChange()">
                    <v-radio
                        v-for="(volume, index) in typesList"
                        :key="index"
                        :label="volume.name"
                        :value="volume.value" />
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row
            v-if="getDescription && getDescription.length"
            class="pt-0 mt-0">
            <v-col class="pt-0 mt-0 text-body-2">
                <p>{{ getDescription }}</p>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import HelpToolTip from 'components/common/HelpTooltip.vue'

    export default {
        components: { HelpToolTip, },
        props: {
            volumeTypes: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                currentVolumeType: null,
            }
        },
        computed: {
            typesList() {
                const list = []
                for (let volumeType of this.volumeTypes) {
                    if (this.isGP3Enabled && volumeType.name === this.$config.iops.gp2) {
                        continue
                    }
                    let description = ''
                    switch (volumeType.name) {
                        case 'io1':
                            description = this.$t('launchService.io1Description')
                            break
                        case 'gp2':
                        case 'gp3':
                            description = this.$t('launchService.gpDescription', { gpType: volumeType.display_name, })
                            break
                        case 'StandardSSD_LRS':
                            description = this.$t('launchService.standardLRS')
                            break
                        case 'Premium_LRS':
                            description = this.$t('launchService.premiumLRS')
                            break
                    }
                    list.push({
                        name: volumeType.display_name,
                        value: volumeType.name,
                        isDefault: volumeType.is_default,
                        description,
                    })
                }
                return list.sort((a, b) => (a.isDefault === b.isDefault) ? 0 : a.isDefault ? -1 : 1)
            },
            isIO1Selected() {
                return this.currentVolumeType === this.$config.iops.io1
            },
            isGP3Enabled() {
                return this.$unleash.isFeatureEnabled('enable-portal-gp3-storage')
            },
            getDescription() {
                const selectedType = this.typesList.find(type => type.value === this.currentVolumeType)
                if (selectedType) {
                    return selectedType.description
                } else {
                    return ''
                }
            },
            storageTypeInfo() {
                const io1 = this.volumeTypes.find(type => type.name === this.$config.iops.io1)
                if (io1) {
                    return this.$t('launchService.storageTypeInfo', { gpType: this.isGP3Enabled ? 'gp3' : 'gp2', })
                } else {
                    return ''
                }
            },
        },
        watch: {
            volumeTypes: {
                deep: true,
                immediate: true,
                handler (val) {
                    const defaultType = val.find(type => type.is_default)
                    this.currentVolumeType = defaultType ? defaultType.name : this.val[0]?.name
                    this.onVolumeTypeChange()
                },
            },
        },
        methods: {
            onVolumeTypeChange() {
                this.$emit('handleIopsTypeChange', this.currentVolumeType)
            },
        },
    }
</script>
