import to from 'await-to-js'
import { CONFIG } from 'config'
import {
    getAllTopologies,
    getServerVersions,
    getSizes,
    getTopologyDetails,
    getRegionsForProvider,
    getServiceTypes,
    getStorageSizes,
    getNodesSize,
    getIops,
    getMaintenanceWindowList,
    getAvailabilityZones,
    getVolumeTypes
} from 'services/offerings'
import t from 'typy'
export default {
    state: {
        regions: [],
        regionErrors: null,
        serverVersions: [],
        versionErrors: null,
        instanceSizes: [],
        instanceSizesError: null,
        isServerVersionFetching: false,
        serviceTypes: [],
        serviceTypesError: false,
        topologiesDetails: [],
        topologiesDetailsError: false,
        allTopologies: [],
        storageSizes: [],
        storageSizeErrors: false,
        nodeSizes: [],
        nodeSizesErrors: false,
        iopsValues: [],
        iopsValuesErrors: false,
        isStorageSizeFetching: false,
        isInstanceSizeFetching: false,
        maxScaleNodes: [],
        maxScaleInstanceSizes: [],
        maxScaleInstanceSizesError: null,
        maintenanceWindowList: [],
        maintenanceWindowListError: null,
        isServiceTypesFetching: false,
        isTopologyFetching: false,
        availabilityZones: [],
        availabilityZonesError: null,
        volumeTypes: [],
        volumeTypesErrors: false,
    },
    mutations: {
        setRegions(state, regions = []) {
            state.regions = regions
        },
        regionErrors(state, payload) {
            state.regionErrors = payload
        },
        setVersions(state, payload) {
            state.serverVersions = payload
        },
        setServerVersionFetching(state, payload) {
            state.isServerVersionFetching = payload
        },
        setVersionErrors(state, payload) {
            state.versionErrors = payload
        },
        setInstanceSizes(state, payload) {
            state.instanceSizes = payload
        },
        setInstanceSizeErrors(state, payload) {
            state.instanceSizesError = payload
        },
        setServiceTypes(state, payload) {
            state.serviceTypes = payload
        },
        setServiceTypesError(state, payload) {
            state.serviceTypesError = payload
        },
        setTopologiesDetails(state, payload) {
            state.topologiesDetails = payload
        },
        setTopologiesDetailsError(state, payload) {
            state.topologiesDetailsError = payload
        },
        setAllTopologies(state, payload) {
            state.allTopologies = payload
        },
        setStorageSizes(state, payload) {
            state.storageSizes = payload
        },
        setStorageSizesError(state, payload) {
            state.storageSizeErrors = payload
        },
        setNodesSizes(state, payload) {
            state.nodeSizes = payload
        },
        setNodesSizesError(state, payload) {
            state.nodeSizesErrors = payload
        },
        setIops(state, payload) {
            state.iopsValues = payload
        },
        setIopsError(state, payload) {
            state.iopsValuesErrors = payload
        },
        setStorageSizeFetching(state, payload){
            state.isStorageSizeFetching = payload
        },
        setInstanceSizeFetching(state, payload){
            state.isInstanceSizeFetching = payload
        },
        setMaxScaleNodes(state, payload) {
            state.maxScaleNodes = payload
        },
        setMaxScaleInstanceSizes(state, payload) {
            state.maxScaleInstanceSizes = payload
        },
        setMaxScaleInstanceSizeErrors(state, payload) {
            state.maxScaleInstanceSizesError = payload
        },
        setMaintenanceWindowList(state, payload) {
            state.maintenanceWindowList = payload
        },
        setMaintenanceWindowListErrors(state, payload) {
            state.maintenanceWindowListError = payload
        },
        setServiceTypesFetching(state, payload) {
            state.isServiceTypesFetching = payload
        },
        setTopologiesFetching(state, payload) {
            state.isTopologyFetching = payload
        },
        setAvailabilityZones(state, payload) {
            state.availabilityZones = payload
        },
        setAvailabilityZonesErrors(state, payload) {
            state.availabilityZonesError = payload
        },
        setVolumeTypes(state, payload) {
            state.volumeTypes = payload
        },
        setVolumeTypesErrors(state, payload) {
            state.volumeTypesErrors = payload
        },
    },
    actions: {
        async getRegions({ commit, }, provider) {
            const [error, data] = await to(getRegionsForProvider(provider))

            if (!error) {
                commit('setRegions', data)
                commit('regionErrors', false)
            } else {
                commit('regionErrors', true)
            }
        },
        async getServerVersions({ commit, }, topology) {
            commit('setServerVersionFetching', true)
            const [error, response] = await to(getServerVersions(topology))
            if (!error) {
                commit('setVersions', response)
                commit('setVersionErrors', false)
            } else {
                commit('setVersionErrors', true)
            }
            commit('setServerVersionFetching', false)
        },
        async getInstanceSizes({ commit, }, options) {
            commit('setInstanceSizeFetching', true)
            const [error, response] = await to(getSizes(options, true))
            if (!error) {
                commit('setInstanceSizes', response)
                commit('setInstanceSizeErrors', false)
            } else {
                commit('setInstanceSizeErrors', true)
            }
            commit('setInstanceSizeFetching', false)
        },
        async getAllTopologies({ commit, }) {
            const [newError, newTopologies] = await to(getAllTopologies())
            const [legacyError, legacyTopologies] = await to(getAllTopologies(true))
            if (!newError, !legacyError) {
                commit('setAllTopologies', newTopologies.concat(legacyTopologies))
            }
        },
        async fetchServiceTypes({ commit, }) {
            commit('setServiceTypesFetching', true)
            const [error, response] = await to(getServiceTypes())
            if (!error) {
                commit('setServiceTypes', response)
                commit('setServiceTypesError', false)
            } else {
                commit('setServiceTypesError', true)
            }
            commit('setServiceTypesFetching', false)
        },
        async fetchTopologyDetails({ commit, }, serviceType) {
            commit('setTopologiesFetching', true)
            const [error, response] = await to(getTopologyDetails(serviceType))
            if (!error) {
                commit('setTopologiesDetails', response)
                commit('setTopologiesDetailsError', false)
            } else {
                commit('setTopologiesDetailsError', true)
            }
            commit('setTopologiesFetching', false)
        },
        async fetchStorageSizes({ commit, }, topology) {
            commit('setStorageSizeFetching', true)
            const [error, response] = await to(getStorageSizes(topology))
            if (!error) {
                commit('setStorageSizes', response.length ? response[0].storage_sizes : [])
                commit('setStorageSizesError', false)
            } else {
                commit('setStorageSizesError', true)
            }
            commit('setStorageSizeFetching', false)
        },
        async fetchNodeSizes({ commit, }, topology) {
            const [error, response] = await to(getNodesSize(topology))
            if (!error) {
                commit('setNodesSizes', response.length ? response[0].nodes : [])
                commit('setMaxScaleNodes', response.length ? response[0].maxscale_nodes : [])
                commit('setNodesSizesError', false)
            } else {
                commit('setNodesSizesError', true)
            }
        },
        async fetchIops({ commit, }, topology) {
            const [error, response] = await to(getIops(topology))
            if (!error) {
                commit('setIops', response)
                commit('setIopsError', false)
            } else {
                commit('setIopsError', true)
            }
        },
        async getMaxScaleInstanceSizes({ commit, }, options) {
            const [error, response] = await to(getSizes(options))
            if (!error) {
                commit('setMaxScaleInstanceSizes', response)
                commit('setMaxScaleInstanceSizeErrors', false)
            } else {
                commit('setMaxScaleInstanceSizeErrors', true)
            }
        },
        async fetchMaintenanceWindowList({ commit, }) {
            const [error, response] = await to(getMaintenanceWindowList())
            if (!error) {
                commit('setMaintenanceWindowList', response)
                commit('setMaintenanceWindowListErrors', false)
            } else {
                commit('setMaintenanceWindowListErrors', true)
            }
        },
        async fetchAvailabilityZones({ commit, }) {
            const { AWS, GCP, AZURE, } = CONFIG.cloudProvider
            const [error, response] = await to(Promise.all([getAvailabilityZones(AWS), getAvailabilityZones(GCP), getAvailabilityZones(AZURE)]))
            if (!error) {
                let zones = []
                if(t(response[0]).isArray){
                    zones = [...response[0]]
                }
                if(t(response[1]).isArray){
                    zones = [...zones,...response[1]]
                }
                if(t(response[2]).isArray){
                    zones = [...zones,...response[2]]
                }
                commit('setAvailabilityZones', zones)
                commit('setAvailabilityZonesErrors', false)
            } else {
                commit('setAvailabilityZonesErrors', true)
            }
        },
        async fetchVolumeTypes({ commit, }, provider) {
            const [error, response] = await to(getVolumeTypes(provider))
            if (!error) {
                commit('setVolumeTypes', response)
                commit('setVolumeTypesErrors', false)
            } else {
                commit('setVolumeTypesErrors', true)
            }
        },
    },
    getters: {
        regions: (state) => state.regions,
        regionErrors: (state) => state.regionErrors,
        serverVersions: (state) => state.serverVersions,
        versionErrors: (state) => state.versionErrors,
        instanceSizes: (state) => state.instanceSizes,
        instanceSizesErrors: (state) => state.instanceSizesErrors,
        isServerVersionFetching: (state) => state.isServerVersionFetching,
        serviceTypes: (state) => state.serviceTypes,
        serviceTypesError: (state) => state.serviceTypesError,
        topologiesDetails: (state) => state.topologiesDetails,
        topologiesDetailsError: (state) => state.topologiesDetailsError,
        allTopologies: (state) => state.allTopologies,
        storageSizes: (state) => state.storageSizes,
        storageSizeErrors: (state) => state.storageSizeErrors,
        nodeSizes: (state) => state.nodeSizes,
        nodeSizesErrors: (state) => state.nodeSizesErrors,
        iopsValues: (state) => state.iopsValues,
        iopsValuesErrors: (state) => state.iopsValuesErrors,
        isStorageSizeFetching: (state) => state.isStorageSizeFetching,
        isInstanceSizeFetching: (state) => state.isInstanceSizeFetching,
        maxScaleNodes: (state) => state.maxScaleNodes,
        maxScaleInstanceSizes: (state) => state.maxScaleInstanceSizes,
        maxScaleInstanceSizesErrors: (state) => state.maxScaleInstanceSizesErrors,
        maintenanceWindowList: (state) => state.maintenanceWindowList,
        maintenanceWindowListErrors: (state) => state.maintenanceWindowListErrors,
        isServiceTypesFetching: (state) => state.isServiceTypesFetching,
        isTopologyFetching: (state) => state.isTopologyFetching,
        availabilityZones: (state) => state.availabilityZones,
        availabilityZonesError: (state) => state.availabilityZonesError,
        volumeTypes: (state) => state.volumeTypes,
        volumeTypesErrors: (state) => state.volumeTypesErrors,
    },
}
