<template>
    <iframe
        :src="`${$config.AI_COPILOT_URL}?embed=true`"
        style="height: 100%; width: 100%; border: 0"></iframe>
</template>

<script>
    export default {
        name: 'CopilotPage',
    }
</script>
