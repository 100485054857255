<style lang="scss" scoped>
    .trial-banner {
        top: 64px;
        margin-left: 235px;
        border-radius: 0;
    }
</style>

<template>
    <div>
        <v-alert
            class="mb-0 trial-banner text-center"
            text
            dense
            :color="color">
            <v-row align="center">
                <v-col class="grow">
                    {{ $t('Service.freeTrialActive') }},
                    <span class="text-lowercase">{{ $t('Service.creditLeft', [creditLeft]) }}</span>
                </v-col>
                <v-col class="shrink">
                    <v-btn
                        :color="color"
                        small
                        outlined
                        @click="openPaymentDialog">
                        {{ $t('billings.addCreditCard') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <PaymentModal
            v-if="showPaymentDialog"
            :payment-profile="paymentProfile"
            :legal-entity-identifier="legalEntityIdentifier"
            :primary-contact-country="billingRecord.primaryContactCountry"
            @on-update-profile="handleProfileUpdate"
            @on-payment-close="handleProfileClose" />
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import { getLegalEntityIdentifier } from 'utils/billing'
    import PaymentModal from 'components/billing/Payment.vue'

    export default {
        name: 'FreeTrialBanner',
        components: {
            PaymentModal,
        },
        props: {
            creditLeft: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                showPaymentDialog: false,
            }
        },
        computed: {
            ...mapGetters(['billingRecord', 'paymentProfile', 'billingCountries']),
            legalEntityIdentifier() {
                return getLegalEntityIdentifier(this.billingRecord)
            },
            color() {
                if (this.creditLeft <= 25) {
                    return 'error'
                }
                if (this.creditLeft <= 50) {
                    return 'warning'
                }
                return 'teal'
            },
        },
        methods: {
            ...mapActions(['fetchBillingRecord', 'fetchPaymentProfile', 'fetchCountries', 'togglePaymentDialog', 'getRegions']),
            handleProfileUpdate() {
                this.togglePaymentDialog(false)
                this.fetchBillingRecord()
                this.getRegions()
                this.showPaymentDialog = false
            },
            handleProfileClose() {
                this.togglePaymentDialog(false)
                this.showPaymentDialog = false
            },
            async openPaymentDialog() {
                if (this.$lodash.isEmpty(this.paymentProfile)) {
                    await this.fetchPaymentProfile()
                }
                this.showPaymentDialog = true
                if (this.$lodash.isEmpty(this.billingCountries)) {
                    await this.fetchCountries()
                } else {
                    // Minor delay is required to open the
                    // payment dialog for cached data
                    await this.$nextTick()
                }
                this.togglePaymentDialog(true)
            },
        },
    }
</script>
