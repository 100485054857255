import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { httpClientIntegration } from '@sentry/integrations'

export const sentry = {
    initialized: false,
    init() {
        Sentry.init({
            Vue,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
            release: `v${process.env.VUE_APP_VERSION}`,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
                Sentry.browserApiErrorsIntegration(),
                httpClientIntegration()
            ],
            attachStacktrace: true,
            autoSessionTracking: true,
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        })
        this.initialized = true
    },
    setUser() {
        if (!this.initialized) {
            return false
        }
        Sentry.setUser(...arguments)
    },
    captureEvent() {
        if (!this.initialized) {
            return false
        }
        Sentry.captureEvent(...arguments)
    },
}

Object.defineProperty(Vue.prototype, '$sentry', { value: sentry, })
