<style lang="scss" scoped>
.no-services {
    color: $navigation;
    width: 400px;

    .no-service-footnote{
        color: $grayed-out;
    }
}

.launch-btn {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: normal;

    .v-btn__content>span {
        font-size: 23px;
    }
}

.filter-link a,
.v-breadcrumbs a {
    color: $navigation;
}

.filter-link {
    a {
        text-decoration: underline;
        font-size: 13px;
    }

    display: block;
    height: 30px;
}

.offset-view {
    max-width: 1350px !important;
    padding-left: 250px !important;
}
</style>

<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-row class="ma-0">
            <v-col
                v-if="!servicesInitialized"
                cols="12"
                class="pa-0">
                <InitializingMessage />
            </v-col>
            <v-col
                v-else-if="!services.length"
                cols="12"
                class="pa-0">
                <v-row justify="center">
                    <div class="no-services mt-4  text-center">
                        <v-icon
                            size="80"
                            style="opacity: 0.3">
                            $vuetify.icons.satellite
                        </v-icon>
                        <div class="my-5">
                            <i18n
                                path="info.noServices"
                                tag="span" />
                        </div>
                        <v-btn
                            outlined
                            large
                            rounded
                            color="primary"
                            class="launch-btn px-8 text-capitalize"
                            @click="$router.push({ name: 'Launch Service' })">
                            <span class="pr-1">+</span> {{ isFreeTrialActive ? $t('Service.launchFree') : $t('Service.launchNew') }}
                        </v-btn>
                    </div>
                </v-row>
            </v-col>
            <v-col
                v-else
                cols="auto"
                class="pa-0 wrapper-container"
                style="width: 100%;">
                <div class="mb-8 d-flex align-end justify-space-between">
                    <div class="view-headline">
                        <span>{{ $tc('service', 2) }}</span>
                        <v-btn
                            outlined
                            rounded
                            large
                            color="primary"
                            class="launch-btn ml-4 text-capitalize"
                            @click="$router.push({ name: 'Launch Service' })">
                            <span class="pr-1">+</span> {{ $t('Service.launchNew') }}
                        </v-btn>
                    </div>
                    <SearchServices />
                </div>

                <ConnectServiceModal />
                <ManageServicePopups />

                <ServiceCard
                    v-for="service in sortedServices"
                    :key="service.name"
                    class="mb-4"
                    :service-id="service.id"
                    :service-status="service.status"
                    :stopper-services="stopperServices"
                    :spending="getSpendingForService(service.id)"
                    :autonomous-policies="getAutoPoliciesForService(service.id)"
                    @updateStoppedServerList="updateStoppedServerList" />
                <v-col
                    v-if="servicesError && !services.length"
                    class="text-center"
                    cols="12">
                    <v-card
                        flat
                        color="transparent">
                        <v-card-text>
                            <v-icon
                                size="60"
                                color="#c7c6c7"
                                class="py-50">
                                mdi-web
                            </v-icon>
                            <div class="text-h6 mt-2">
                                {{ $t('noServices') }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import SearchServices from 'components/common/SearchServices.vue'
    import ConnectServiceModal from 'components/service/ConnectServiceModal.vue'
    import ServiceCard from 'components/service/Card.vue'
    import InitializingMessage from 'components/messages/Initializing.vue'
    import ManageServicePopups from 'components/service/ManageServicePopups.vue'
    import { CONFIG } from 'config/'

    const invalidFetchUsageStates = [CONFIG.serviceStatus.PENDING, CONFIG.serviceStatus.PENDING_CREATE]
    export default {
        components: {
            SearchServices,
            ConnectServiceModal,
            ServiceCard,
            InitializingMessage,
            ManageServicePopups,
        },
        data() {
            return {
                stopperServices: [],
                interval: null,
            }
        },
        computed: {
            ...mapGetters([
                'services',
                'servicesInitialized',
                'servicesError',
                'getServiceById',
                'servicePolicies',
                'servicesSpending',
                'isFreeTrialActive'
            ]),
            sortedServices() {
                this.mapReplicationType()
                const clonedServices = [...this.services]
                clonedServices.sort((serviceA, serviceB) => serviceB.created_on - serviceA.created_on)
                return clonedServices
            },
            serviceIds() {
                return this.services?.filter(service => !invalidFetchUsageStates.includes(service?.status)).map(service => service?.id)
            },
        },
        watch: {
            serviceIds(current, previous) {
                if(!previous.length && current.length) {
                    this.getSpendingDetailsPerService()
                }
            },
        },
        methods: {
            ...mapActions([
                'startServicesInterval',
                'stopServicesInterval',
                'fetchPoliciesForService',
                'fetchSpendingDetailsForService',
                'fetchBillingRecord'
            ]),
            updateStoppedServerList(serviceId) {
                let newServiceList = []
                newServiceList.push(serviceId)
                this.stopperServices.length = 0
                this.stopperServices = [...newServiceList]
            },
            mapReplicationType() {
                this.services?.forEach(service => {
                    if (service?.primary_host) {
                        let primaryService = this.getServiceById(service.primary_host)
                        // TODO: Workaround until this flag is provided by backend team
                        if (primaryService)
                            service['replication_type'] = primaryService.primary_host === service.id ? this.$config.REPLICATION_DIRECTIONS.BI : this.$config.REPLICATION_DIRECTIONS.UNI
                    }
                })
            },
            getAutoPoliciesForService(serviceId){
                return this.servicePolicies?.filter(policy=> policy.service_id === serviceId)
            },
            getSpendingForService(serviceId){
                const spendFound = this.servicesSpending?.find(spending=> spending.serviceId === serviceId)
                return spendFound ? spendFound?.estimate : '--'
            },
            getSpendingDetailsPerService() {
                if(this.serviceIds?.length) {
                    this.serviceIds?.forEach(id => {
                        this.fetchSpendingDetailsForService(id)
                    })
                }
            },
            startServiceUsageInterval() {
                this.interval = setInterval(() => {
                    this.getSpendingDetailsPerService()
                }, this.$config.fetchUsageInterval * 1000)
            },
        },
        mounted(){
            this.fetchBillingRecord()
            this.fetchPoliciesForService()
            this.getSpendingDetailsPerService()
            this.startServiceUsageInterval()
            this.startServicesInterval(this.$config.fetchServicesInterval)
        },
        destroyed() {
            this.stopServicesInterval()
            clearInterval(this.interval)
        },
    }
</script>
