<template>
    <LogsWithViewer
        v-if="$unleash.isFeatureEnabled('enable-portal-logs-with-viewer')"
        class="mt-5"
        :servers="servers" />

    <div
        v-else
        class="mt-5">
        <Logs
            :preselected-services="selectedServices" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import to from 'await-to-js'
    import Logs from 'components/logs/Logs.vue'
    import LogsWithViewer from 'components/logs/LogsWithViewer.vue'
    import observability from 'api/observability'
    export default {
        name: 'LogsServicePage',
        components: {
            Logs,
            LogsWithViewer,
        },
        data() {
            return {
                servers: [],
                selectedServices: [],
            }
        },
        computed: {
            ...mapGetters(['services']),
            service() {
                return this.services.find(
                    (service) => service.id === this.$route.params.id
                )
            },
        },
        methods: {
            async fetchServers() {
                const [error, response] = await to(observability.get(`services/${this.service?.topologyId}/servers`))
                if (error) {
                    this.$store.commit('showMessage', {
                        text: this.$t('errors.fetchServers'),
                        type: 'error',
                    })
                }
                else {
                    this.servers = this.$typy(response, 'data.servers').safeArray?.map((server) => ({
                        service: this.service.name,
                        server: server.name,
                        serverDataSourceId: server.dataSourceId,
                    }))
                    this.selectedServices = this.$typy(response, 'data.servers').safeArray?.map(server=> server.dataSourceId)
                }
            },
        },
        async created() {
            await this.fetchServers()
        },
    }
</script>
