<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 256 256"
        enable-background="new 0 0 256 256"
        style="height: 30px; width: 30px;">
        <g><g><path
            fill="#000000"
            d="M107.4,131.5H94.6l-2.6,8.5h-8.3L97,100.4h8.1l13.3,39.7h-8.3L107.4,131.5L107.4,131.5z M96.5,125.3h9l-4.4-14h-0.1L96.5,125.3z M130.1,125.6v14.3h-8v-39.5h15.2c4.6,0,8.1,1.2,10.8,3.5c2.6,2.4,4,5.3,4,9.2c0,3.8-1.3,6.8-4,9.2c-2.7,2.4-6.2,3.4-10.8,3.4L130.1,125.6L130.1,125.6z M130.1,119.6h7.2c2.2,0,3.8-0.6,5-1.8c1.2-1.2,1.8-2.8,1.8-4.6c0-1.9-0.6-3.5-1.8-4.7s-2.8-1.9-5-1.9h-7.2V119.6L130.1,119.6z M166.1,140.1h-8v-39.7h8V140.1z M246,132.9c0,23.1-18.9,42-42,42h-42.9c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7H204c18,0,32.6-14.6,32.6-32.6c0-17.8-14.6-32.4-32.4-32.6c-0.1,0-0.1,0-0.3,0c-1.3,0-2.7-0.6-3.7-1.6c-0.9-1-1.3-2.4-1.2-3.7c0.3-2.5,0.4-4.6,0.4-6.5c0-14-5.5-27.3-15.3-37.2S160.9,36,147,36c-10.9,0-21.4,3.4-30.4,9.7c-8.7,6.2-15.2,14.7-19,24.8c-0.6,1.5-1.9,2.6-3.4,2.9c-1.6,0.3-3.3-0.1-4.4-1.3c-4.4-4.4-10.3-6.9-16.7-6.9c-13,0-23.6,10.6-23.6,23.6c0,0,0,0.3,0,0.4s0,0.4,0,0.6c0.1,2.2-1.3,4.1-3.5,4.7c-7.5,2.1-14.3,6.6-19,12.8c-4.9,6.3-7.5,14-7.5,22.1c0,19.9,16.2,36.1,36.1,36.1h39.3c2.6,0,4.7,2.1,4.7,4.7c0,2.6-2.1,4.7-4.7,4.7H55.7C30.5,175,10,154.5,10,129.3c0-10.3,3.3-19.9,9.6-28c5.3-6.8,12.5-12.1,20.5-15c1.2-17.1,15.5-30.7,33-30.7c6.6,0,12.8,1.9,18.1,5.4c4.6-9.3,11.3-17.1,19.7-23.1c10.5-7.5,22.9-11.3,35.8-11.3c16.5,0,32.1,6.5,43.9,18.1c11.8,11.8,18.1,27.3,18.1,43.9c0,0.9,0,1.8,0,2.6C229.6,93.5,246,111.3,246,132.9L246,132.9L246,132.9z M154.9,188.3c-0.9,0.9-2.1,1.3-3.4,1.3c-1.2,0-2.4-0.4-3.4-1.3l-15.5-15.5l0,0v50.9c0,2.6-2.1,5.8-4.6,5.8c-2.6,0-4.9-3.1-4.9-5.8v-50.9l0,0l-15.5,15.5c-1.9,1.9-4.9,1.9-6.6,0c-1.9-1.9-1.9-4.9,0-6.6l23.6-23.6c1.9-1.9,4.9-1.9,6.6,0l23.6,23.6C156.7,183.4,156.7,186.4,154.9,188.3L154.9,188.3L154.9,188.3z" /></g></g>
    </svg>
</template>

<script>
    export default {}
</script>
