<template>
    <div
        v-if="isLoading"
        class="pt-6">
        <v-progress-circular
            size="50"
            width="7"
            color="primary"
            indeterminate />
    </div>
    <div
        v-else
        class="pt-6">
        <v-alert
            v-if="isMFAEnabled"
            outlined
            type="success">
            {{ $t('UserProfile.mfaInfo') }}
        </v-alert>
        <v-alert
            v-else
            outlined
            type="warning">
            {{ $t('UserProfile.mfaWarning') }}
        </v-alert>

        <v-switch
            v-model="isActive"
            :label="isActive ? $t('enabled') : $t('disabled')"
            color="primary"
            inset
            @change="onChange" />
        <ConfirmationModal
            ref="confirmModal"
            :message="isActive ? 'confirmations.mfaEnable' : 'confirmations.mfaDisable'"
            :on-confirm="confirmMfa"
            :on-cancel="() => isActive = !isActive" />
    </div>
</template>

<script>
    import to from 'await-to-js'
    import { mapGetters, mapActions } from 'vuex'
    import { getUserById } from 'services/users.js'
    import ConfirmationModal from 'components/common/ConfirmationModal.vue'

    export default {
        name: 'MfaToggle',
        components: {
            ConfirmationModal,
        },
        data () {
            return {
                isLoading: false,
                isActive: false,
                isMFAEnabled: false,
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        methods: {
            ...mapActions(['enableMFA', 'disableMFA', 'logoutUser']),
            async confirmMfa() {
                let doLogout = false
                if (this.isActive) {
                    doLogout = await this.enableMFA()
                    this.isMFAEnabled = true
                } else {
                    doLogout = await this.disableMFA()
                    this.isMFAEnabled = false
                }
                if (doLogout) {
                    await this.logoutUser()
                } else {
                    this.isMFAEnabled = !this.isMFAEnabled
                    this.isActive = this.isMFAEnabled
                }
            },
            onChange() {
                this.$refs.confirmModal.open()
            },
            async getMfaState() {
                this.isLoading = true
                // TODO: Temporary patch until we refactor the user state DEV-637
                const [error, response] = await to(getUserById(this.user.id, false))
                if (!error) {
                    this.isMFAEnabled = response?.mfa_enabled
                }
                this.isLoading = false
            },
        },
        async mounted() {
            await this.getMfaState()
            this.isActive = this.isMFAEnabled
        },
    }
</script>
