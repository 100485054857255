import { render, staticRenderFns } from "./ServiceBackups.vue?vue&type=template&id=59ed468a&scoped=true"
import script from "./ServiceBackups.vue?vue&type=script&lang=js"
export * from "./ServiceBackups.vue?vue&type=script&lang=js"
import style0 from "./ServiceBackups.vue?vue&type=style&index=0&id=59ed468a&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ed468a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59ed468a')) {
      api.createRecord('59ed468a', component.options)
    } else {
      api.reload('59ed468a', component.options)
    }
    module.hot.accept("./ServiceBackups.vue?vue&type=template&id=59ed468a&scoped=true", function () {
      api.rerender('59ed468a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/backups/ServiceBackups.vue"
export default component.exports