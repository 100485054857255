<style lang="scss" scoped>
.header-row {
    background: rgba(232, 238, 241, 0.4);
    border: 1px solid $cornsilk;

    .service-meta {
        color: $grayed-out;
    }

    .source-text{
        color: $azure
    }
}
</style>
<template>
    <div
        v-if="hasData"
        class="text-subtitle-2">
        <v-row
            :data-auto="`parentItem-${identifier}`"
            class="header-row">
            <v-col
                class="pointer"
                cols="6"
                @click="toggleHeaderTab">
                <v-icon
                    :class="['mr-2', { 'rotate--right': !headerExpanded }]"
                    size="13">
                    $expand
                </v-icon>
                <span
                    :data-auto="`accordion-title-${identifier}`"
                    class="font-weight-bold">{{ accordionData.title }}</span>
                <span
                    :data-auto="`accordion-subtitle-${identifier}`"
                    class="ml-2 service-meta">{{ accordionData.subTitle }}</span>
            </v-col>
            <v-col cols="3" />
            <v-col
                :data-auto="`accordion-cost-${identifier}`"
                class="font-weight-bold text-right pr-0">
                {{ $t('currency', [accordionData.cost.toFixed(2)]) }}
            </v-col>
            <v-col cols="2" />
        </v-row>
        <div v-if="headerExpanded">
            <BillingPanelChildNodes
                v-for="(childNode,index) in accordionData.children"
                :key="index"
                :node="childNode"
                :child-index="index" />
        </div>
    </div>
</template>

<script>
    import BillingPanelChildNodes from 'components/billing/BillingPanelChildNodes.vue'
    import HelpToolTip from 'components/common/HelpTooltip.vue'

    const BillingAccordionPanel = {
        name: 'BillingAccordionPanel',
        components: { BillingPanelChildNodes, HelpToolTip, },
        props: {
            accordionData: {
                type: Object,
                default: () => { },
                required: false,
            },
            identifier: {
                type: String,
                default: 0,
                required: true,
            },
        },
        data() {
            return {
                headerExpanded: false,
            }
        },
        computed:{
            hasData(){
                return this.accordionData && this.accordionData?.title
            },
        },
        methods: {
            toggleHeaderTab() {
                this.headerExpanded = !this.headerExpanded
            },
        },
    }
    export default BillingAccordionPanel
</script>
