import Unauthorized from 'pages/Unauthorized.vue'

export default {
    path: '/login/auth/dispatcher',
    name: 'unauthorized',
    component: Unauthorized,
    meta: {
        guest: true,
        title: 'Unauthorized',
    },
}
