import { toKebabCase } from 'helpers'
import { t } from 'typy'

export function topologyMapper (data) {
    return {
        title: data.display_name,
        subTitle: data.db_engine || 'InnoDB',
        serviceType: data.service_type,
        description: data.description,
        type: toKebabCase(data.display_name),
    }
}

export function regionsMapper (data) {
    return {
        name: data.name,
        provider: data.provider,
        region: data.region,
        isAvailable: !t(data, 'is_available').isFalse,
        codeName: data.code_name,
        maintenanceWindow: data.foundation_tier_maintenance_window,
    }
}

export function instanceSizeMapper (data) {
    return {
        ...data,
        // Done to split out number
        // for the each of decorating it while rendering
        cpuNum: data.cpu.split(' vCPU')[0],
        ramNum: data.ram.split(' GB')[0],
        sizeLabel: data.display_name.split('Sky-')[1],
    }
}
