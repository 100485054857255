import JSZip from 'jszip'
import * as moment from 'moment'

/**
 * Reads a ZIP file from a blob and returns a hash object that maps file
 * names to their contents.
 * Only first-level files are read. i.e. no nested dir supported
 * @param {Blob} blob - The blob containing the ZIP file.
 * @returns {Object} Hash object
 */
export async function readZipFile(blob) {
    const zip = new JSZip()
    const zipFile = await zip.loadAsync(blob)
    const contentHash = {}
    for (const [fileName, file] of Object.entries(await zipFile.files)) {
        if (!file.dir) {
            const content = await file.async('string')
            contentHash[fileName] = content
        }
    }
    return contentHash
}
/**
 * Extracts the body lines of a slow query logs file from an array of lines.
 * The headerLines property is an array of strings that contains all the header lines.
 * e.g mysqld, Version: 10.6 ...
 * The bodyLines property is an array of strings that contains all the lines after the header lines.
 *
 * @param {Array<string>} lines - An array of strings representing the lines to be processed.
 * @returns {Object} - An object containing two properties: headerLines and bodyLines.
 * @throws {TypeError} - If the input argument is not an array.
 */
export function extractBodyLines(lines) {
    if (!Array.isArray(lines))
        throw new TypeError('Expected an array of strings.')
    let headerLineIndex = 0
    let i
    for (i = 0; i < lines.length; i++) {
        if (lines[i].startsWith('#')) {
            headerLineIndex = i
            break
        }
    }
    // If no queries found in the log file
    // then simply return empty header and body lines
    if (i === lines.length) {
        return {
            headerLines: [],
            bodyLines: [],
        }
    }

    return {
        headerLines: lines.slice(0, headerLineIndex),
        bodyLines: lines.slice(headerLineIndex),
    }
}

export function parseUserHost(output, value) {
    const values = value.split(/@ {1}/g)
    output.user = values[0].trim()
    output.host = values[1].trim()
}
export function parseQuery(output, value) {
    if (!output.query) output.query = ''
    output.query += `${value}\n`
}

const USER_HOST_REGEX = /^user@host:\s/i
const EXPLAIN_REGEX = /^explain:\s/i

export function parseHashedLine(line) {
    const str = line.replace(/^#\s/, '')
    const result = {}
    if (str.match(USER_HOST_REGEX))
        parseUserHost(result, str.replace(USER_HOST_REGEX, '').trim())
    else if (str.match(EXPLAIN_REGEX))
        result.explain = str.replace(EXPLAIN_REGEX, '').trim()
    else
        str.split(/\s{2}/).forEach((pair) => {
            const [key = '', value = ''] = pair.split(':')
            result[key.trim().toLowerCase()] = value.trim()
        })
    return result
}

/**
 * @param {String} param.str - logs string
 * @param {String} [param.sortBy] - sort logs
 * @returns {Array} - parsed logs
 */
export function slowQueryLogParser({
    str,
    sortBy = 'query_time',
    sortOrder = 'desc',
}) {
    if (str.trim() === '') return []
    const allLines = str.trim().split('\n')
    const { bodyLines, } = extractBodyLines(allLines)
    const timeRegex = /^#\stime:\s(.+)$/im
    const timeStampRegex = /SET timestamp=(\d+);/
    const queryLogFirstLineRegex = /^#\suser@host:\s/i
    const queryLogSqlLineRegex = /^[^#].*/
    let logs = []
    let currentTime = ''
    bodyLines.forEach((line) => {
        const match = line.match(timeRegex)
        if (match) {
            currentTime = match[1]
        } else {
            // Remove query line starting with `SET Timestamp` string
            // this statement is added by db engine and not by user
            // this can be safely ignored
            if (line.match(queryLogFirstLineRegex))
                logs.push({
                    logStr: `# Time: ${currentTime}\n`,
                })

            const currentQueryLog = logs.at(-1)

            if(line.match(timeStampRegex)){
                currentQueryLog.time = parseInt(timeStampRegex.exec(line)[1])
            }else{
                if (line.match(queryLogSqlLineRegex))
                    parseQuery(currentQueryLog, line)
                else {
                    const parsedLine = parseHashedLine(line)
                    if ('explain' in parsedLine) {
                        if (!currentQueryLog.explain)
                            currentQueryLog.explain = []
                        currentQueryLog.explain.push(parsedLine.explain)
                    } else Object.assign(currentQueryLog, parsedLine)
                }
                currentQueryLog.logStr += `${line}\n`
            }
        }
    })
    logs.sort((a, b) =>
        sortOrder === 'desc' ? b[sortBy] - a[sortBy] : a[sortBy] - b[sortBy]
    )

    return logs.map((log) => ({
        ...log,
        query: makeQueriesDataAnonymous(log.query),
    }))
}

export function aggregateQueries(logs) {
    let queryMap = {}

    logs.forEach((log) => {
        if (!queryMap[log.query]) {
            queryMap[log.query] = []
        }
        queryMap[log.query].push(log)
    })

    // Do average of all the queries object in the hashMap
    let uniqueQueries = []
    for (let queryKey in queryMap) {
        const queries = queryMap[queryKey]
        const totalCount = queries.length
        let totalQueryTime = 0
        let totalRowsSent = 0
        let totalRowsExamined = 0
        let maxQueryTime = 0
        queries.forEach((query) => {
            const parsedTime = parseFloat(query.query_time)
            totalQueryTime += parsedTime
            totalRowsSent += parseInt(query.rows_sent)
            totalRowsExamined += parseInt(query.rows_examined)
            maxQueryTime =
                maxQueryTime > query.query_time ? maxQueryTime : parsedTime
        })
        const avgQueryTime = totalQueryTime / totalCount
        const avgRowsSent = totalRowsSent / totalCount
        const avgRowsExamined = totalRowsExamined / totalCount
        uniqueQueries.push({
            totalCount,
            ...queries[0],
            avgQueryTime,
            totalQueryTime,
            maxQueryTime,
            avgRowsSent,
            avgRowsExamined,
        })
    }

    // Sort them based on the max query time
    uniqueQueries.sort((a, b) => b.maxQueryTime - a.maxQueryTime)

    return uniqueQueries
}

function makeQueriesDataAnonymous(query = '') {
    if (query && query.length) {
        const clonedQuery = query.toLowerCase()
        if (clonedQuery.includes('where')) {
            return query.replace(/'[^']*'/g, '\'?\'')
        }
    }
    return query
}

export function filterLogsForTimeRange(logs, fromDate, toDate){
    return logs.filter(log=>  {
        const logTime = moment.unix(log.time)
        return logTime.isAfter(fromDate) && logTime.isBefore(toDate)
    })
}
