<style lang="scss" scoped>
    .selection-group{
        height: 90px
    }

    .autoscale-label :deep label {
        font-size: 0.75em;
    }
</style>

<template>
    <div data-auto="storage-auto-scale">
        <v-row
            v-if="title"
            data-auto="storage-auto-scale.title"
            class="mt-2 text-h6 font-weight-bold">
            {{ title }}
        </v-row>
        <v-row class="mt-4">
            <v-col class="d-flex">
                <v-checkbox
                    v-model="isStorageScaleEnabled"
                    :disabled="shouldDisableScaling"
                    class="autoscale-label"
                    :label="autoScaleLabel"
                    @change="onCheck" />
                <div class="mt-4 pl-2">
                    <HelpToolTip>
                        <template #message>
                            <p>
                                {{ $t('launchService.autoScaleStorageInfo') }}
                            </p>
                            <a
                                rel="noopener noreferrer"
                                class="text-capitalize"
                                target="_blank"
                                href="https://r.mariadb.com/snr-launch-autoscale-storage">{{ $t('learn-more') }}</a>
                        </template>
                    </HelpToolTip>
                </div>
            </v-col>
            <v-col
                class="selection-group">
                <v-select
                    v-if="isStorageScaleEnabled"
                    v-model="selectedStorage"
                    :items="autoScaleStorageOptions"
                    item-text="text"
                    item-value="val"
                    :placeholder="$t('maximum-size')"
                    required
                    open-on-clear
                    :rules="[
                        (val) =>
                            !!val ||
                            $t('errors.fieldRequired', {
                                field: $t('ssd-storage-gb'),
                            }),
                    ]"
                    :disabled="shouldDisableScaling"
                    :menu-props="{ top: false, offsetY: true }"
                    outlined
                    @change="onStorageSelection" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import HelpToolTip from 'components/common/HelpTooltip'

    const StorageAutoScale = {
        name: 'StorageAutoScale',
        components: { HelpToolTip, },
        props: {
            title: {
                type: String,
                default: '',
            },
            storageRange: {
                type: Array,
                required: true,
            },
            defaultStorageValue: {
                default: null,
            },
            isGP3Selected: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isStorageScaleEnabled: Boolean(this.defaultStorageValue),
                selectedStorage: this.defaultStorageValue,
            }
        },
        computed: {
            autoScaleStorageOptions() {
                let storageArray = []

                if (!this.isStorageScaleEnabled) return storageArray

                if(this.storageRange.length){
                    storageArray = this.storageRange.map(s=>({
                        val:s,
                        text:`${s} GB`,
                    }))
                }

                return storageArray
            },
            shouldDisableScaling(){
                return !this.storageRange.length
            },
            autoScaleLabel() {
                return this.isGP3Selected ? this.$t('enableAutoScaleStorageIOPS') : this.$t('enableAutoScaleStorage')
            },
        },
        methods: {
            onStorageSelection(value) {
                this.$emit('on-auto-storage-value', value)
            },
            onCheck() {
                this.$emit('on-toggle-auto-scaling', this.isStorageScaleEnabled)

                if (this.isStorageScaleEnabled && this.selectedStorage) {
                    this.$emit('onMaxSizeSelected', this.selectedStorage)
                }
                if(!this.isStorageScaleEnabled) this.selectedStorage = null
            },
        },
    }

    export default StorageAutoScale
</script>
