import { render, staticRenderFns } from "./BackupRestoreModal.vue?vue&type=template&id=29296ba2"
import script from "./BackupRestoreModal.vue?vue&type=script&lang=js"
export * from "./BackupRestoreModal.vue?vue&type=script&lang=js"
import style0 from "./BackupRestoreModal.vue?vue&type=style&index=0&id=29296ba2&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('29296ba2')) {
      api.createRecord('29296ba2', component.options)
    } else {
      api.reload('29296ba2', component.options)
    }
    module.hot.accept("./BackupRestoreModal.vue?vue&type=template&id=29296ba2", function () {
      api.rerender('29296ba2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/backups/BackupRestoreModal.vue"
export default component.exports