import backup from 'api/backup'
import { camelize } from 'helpers/camelCase'
import { convertToSnakeCase } from 'helpers/snakeCase'

export async function getBackups(params){
    const response = await backup.get('backups', {...convertToSnakeCase(params),})
    return camelize(response.data)
}
export async function getSchedules(params){
    const response = await backup.get('backups/schedules', {...convertToSnakeCase(params),})
    return camelize(response.data)
}
export async function getRestores(params){
    const response = await backup.get('restores', {...convertToSnakeCase(params),})
    return camelize(response.data)
}
export async function createNewSchedule(params) {
    return backup.post('backups/schedules', {...convertToSnakeCase(params), }).then(response=> camelize(response.data.result))
}
export async function updateSchedule(id, params) {
    return backup.patch(`backups/schedules/${id}`, {...convertToSnakeCase(params), }).then(response=> camelize(response.data.result))
}
export async function deleteSchedule(id) {
    return backup.delete(`backups/schedules/${id}`).then(response=> camelize(response.data.result))
}
export async function restoreBackup(params) {
    return backup.post('restores', {...convertToSnakeCase(params), }).then(response=> camelize(response.data.result))
}
export async function getRestoreTargets(params){
    return backup.post('restores/lookup', {...convertToSnakeCase(params),}).then(response=> camelize(response.data.services))
}
