<style scoped>
    .control-label {
        font-size: 0.675rem;
    }
</style>

<template>
    <div class="pt-3">
        <v-alert
            v-model="isInfoVisible"
            outlined
            dismissible
            type="info">
            {{ $t('info.backupInfo') }}
            <span>{{ $t('info.backupInfoPricing') }}</span>
            <a
                class="font-weight-medium text-decoration-none text-lowercase"
                target="_blank"
                :href="`${$config.DOCS_URL}Backup%20and%20Restore/#pricing`">
                {{ $t('here') }}.
            </a>
        </v-alert>
        <div class="d-flex mb-3 justify-space-between">
            <div class="d-flex">
                <div>
                    <label class="d-block color text-small-text control-label">
                        {{ $tc('service') }}
                    </label>
                    <div>
                        <MultipleSelect
                            :items="serviceItems"
                            @toggleItem="toggleItem"
                            @toggleAll="(state) => toggleAllItems(serviceItems, state)" />
                    </div>
                </div>
                <div
                    class="ml-2"
                    style="width: 200px">
                    <label class="d-block color text-small-text control-label">
                        {{ $t('backups.type') }}
                    </label>
                    <div>
                        <MultipleSelect
                            :items="backupTypes"
                            @toggleItem="toggleItem"
                            @toggleAll="(state) => toggleAllItems(backupTypes, state)" />
                    </div>
                </div>
            </div>
            <div class="d-flex align-end">
                <v-btn
                    class="text-none mr-2"
                    color="primary"
                    rounded
                    depressed
                    @click="isScheduleDialogOpen = true">
                    {{ $t('backups.backupNow') }}
                </v-btn>
            </div>
        </div>
        <v-data-table
            class="data-table-full logs-table"
            :headers="headers"
            :items="backupsList"
            :options.sync="options"
            :server-items-length="backupsCount"
            :loading="isFetchingBackups"
            :footer-props="footerOptions"
            :sort-by="'startTime'"
            :sort-desc="true"
            :no-data-text="$t('info.noBackups', ['backups'])"
            must-sort
            fixed-header>
            <template #item="{ item: backup }">
                <tr>
                    <td>{{ backup.serviceName }}</td>
                    <td>{{ backup.type }}</td>
                    <td>
                        <span>{{ $moment( backup.startTime).format('DD-MM-YYYY hh:mm:ss a') }}</span>
                    </td>
                    <td>{{ $help.formatBytes(backup.size) }}</td>
                    <td
                        :class="{
                            'red--text': backup.status === 'Failed',
                            'green--text': backup.status === 'Succeeded'
                        }">
                        {{ backup.status }}
                    </td>
                    <td>
                        <v-tooltip
                            v-if="backup.status === 'Succeeded'"
                            top>
                            <template #activator="{ on }">
                                <v-btn
                                    icon
                                    v-on="on"
                                    @click="restoreBackup(backup.id)">
                                    <v-icon>mdi-backup-restore</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('restore') }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <BackupScheduleModal
            :show-dialog="isScheduleDialogOpen"
            :is-loading="schedulingBackupInProgress"
            :is-instant="true"
            @on-close="isScheduleDialogOpen = false"
            @on-schedule-submit="createInstantSchedule" />
        <BackupRestoreModal
            :show-dialog="isRestoreDialogOpen"
            :backup="selectedBackup || {}"
            :is-loading="restoringBackupInProgress"
            @on-close="isRestoreDialogOpen = false"
            @on-restore-submit="restore" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import to from 'await-to-js'
    import { createNewSchedule, restoreBackup } from 'services/backups.js'
    import MultipleSelect from 'components/common/MultipleSelect.vue'
    import BackupScheduleModal from 'components/backups/BackupScheduleModal.vue'
    import BackupRestoreModal from 'components/backups/BackupRestoreModal.vue'

    export default {
        name: 'ServiceBackups',
        components: {
            MultipleSelect,
            BackupScheduleModal,
            BackupRestoreModal,
        },
        data() {
            return {
                isScheduleDialogOpen: false,
                schedulingBackupInProgress: false,
                isRestoreDialogOpen: false,
                restoringBackupInProgress: false,
                pollingInterval: null,
                isInfoVisible: !localStorage.getItem('isBackupInfoDissmissed'),
                serviceItems: [],
                backupTypes: [],
                backupsList: [],
                selectedBackupId: null,
                headers: [
                    { text: this.$tc('service'), value: 'serviceName', sortable: false, },
                    { text: this.$t('type'), value: 'type', sortable: false, },
                    { text: this.$t('time'), value: 'schedule', sortable: false, },
                    { text: this.$t('size'), value: 'size', sortable: false, },
                    { text: this.$t('status'), value: 'status', sortable: false, },
                    { text: this.$t('actions'), value: 'actions', sortable: false, }
                ],
                footerOptions: {
                    'items-per-page-options': [ 10, 20, 50, 100],
                },
                options: {},
            }
        },
        computed: {
            ...mapGetters(['services', 'backupData', 'isFetchingBackups']),
            selectedBackup() {
                return this.backupsList.find(backup => backup.id === this.selectedBackupId)
            },
            backupsCount() {
                return this.backupData?.backupsCount ?? 0
            },
            selectedServices() {
                return this.serviceItems.filter(service => service.selected)
            },
            selectedBackupTypes() {
                return this.backupTypes.filter(service => service.selected)
            },
        },
        watch: {
            isInfoVisible: function (value) {
                if (!value) {
                    localStorage.setItem('isBackupInfoDissmissed', true)
                }
            },
            options: {
                handler () {
                    this.fetchBackupsData()
                },
                deep: true,
            },
            serviceItems: {
                handler (newVal, oldVal) {
                    if (!oldVal.length) {
                        return
                    }
                    this.fetchBackupsData()
                },
                deep: true,
            },
            backupTypes: {
                handler (newVal, oldVal) {
                    if (!oldVal.length) {
                        return
                    }
                    this.fetchBackupsData()
                },
                deep: true,
            },
            backupData() {
                this.backupsList = this.backupData?.backups?.sort((a,b) => new Date(b?.endTime) - new Date(a?.endTime))?.map(backup => {
                    return {
                        selected: false,
                        serviceName: backup?.serviceName ?? '',
                        serviceId: backup?.serviceId,
                        id: backup?.id ?? '',
                        type: backup?.type?.charAt(0).toUpperCase() +  backup?.type?.slice(1),
                        startTime: backup?.startTime ? this.$moment(backup?.startTime).utc() : new Date().toISOString(),
                        endTime: backup?.endTime ? this.$moment(backup?.endTime).utc() : new Date().toISOString(),
                        size: backup?.backupSize ?? '-',
                        status: backup?.status ?? 'Unknown',
                        isExternalStorage: backup?.isExternalStorage,
                    }
                }) ?? []
            },
        },
        methods: {
            ...mapActions(['fetchServices', 'fetchBackups']),
            startPollingBackups (interval) {
                this.pollingInterval = setInterval(() => {
                    this.fetchBackupsData()
                }, interval)
            },
            stopPollingBackups () {
                clearInterval(this.pollingInterval)
            },
            async fetchBackupsData() {
                const { sortDesc, page, itemsPerPage, } = this.options
                await this.fetchBackups({
                    ...(this.selectedServices.length && { serviceId: this.selectedServices.map(type => type.val).join(','), }),
                    ...(this.selectedBackupTypes.length && { type: this.selectedBackupTypes.map(type => type.val).join(','), }),
                    pageSize: itemsPerPage,
                    page,
                    order: sortDesc[0] ? 'desc' : 'asc',
                })
            },
            async createInstantSchedule(options) {
                try {
                    this.schedulingBackupInProgress = true
                    const [error, response] = await to(createNewSchedule(options))
                    if (error) {
                        this.$store.commit('showMessage', { text: this.$t('errors.createScheduleError'), type: this.$config.messageType.ERROR, })
                    } else {
                        this.$store.commit('showMessage', { text: response?.message ?? this.$t('success.createScheduleSuccess'), type: this.$config.messageType.SUCCESS, })
                        this.isScheduleDialogOpen = false
                        this.$router.push({ name: 'service-schedules', })
                    }
                } catch {
                    this.$store.commit('showMessage', { text: this.$t('errors.createScheduleError'), type: this.$config.messageType.ERROR, })
                } finally {
                    this.schedulingBackupInProgress = false
                }
            },
            getBase64Credentials(obj) {
                switch(obj?.provider?.toLowerCase()) {
                    case 'aws':
                        return btoa(`aws_access_key_id = ${obj?.accessKeyId} aws_secret_access_key = ${obj?.accessKey} region = ${obj?.region}`)
                    case 'gcp':
                        return btoa(obj?.serviceAccountKey)
                    default:
                        return ''
                }
            },
            getExternalStorageObj(obj) {
                const {path, method, encryptionKey, } = obj
                return {
                    bucket: path,
                    method: method,
                    credentials: this.getBase64Credentials(obj),
                    ...(encryptionKey && {encryptionKey: encryptionKey,}),
                }
            },
            restoreBackup(backupId) {
                this.selectedBackupId = backupId
                this.isRestoreDialogOpen = true
            },
            async restore(obj) {
                try {
                    this.restoringBackupInProgress = true
                    const params = {
                        id: obj?.backupId,
                        serviceId: obj?.serviceId,
                        ...(obj?.pointInTime && {pointInTime: obj?.pointInTime,}),
                        ...(obj?.storage === 'external' && {externalStorage: this.getExternalStorageObj(obj),}),
                    }
                    const [error, response] = await to(restoreBackup(params))
                    if (error) {
                        this.$store.commit('showMessage', { text: this.$t('errors.restoreError'), type: this.$config.messageType.ERROR, })
                    } else {
                        this.$store.commit('showMessage', { text: response?.message ?? this.$t('success.restoreBackupSuccess'), type: this.$config.messageType.SUCCESS, })
                        this.isRestoreDialogOpen = false
                        this.$router.push({ name: 'service-restores', })
                    }
                } catch {
                    this.$store.commit('showMessage', { text: this.$t('errors.restoreError'), type: this.$config.messageType.ERROR, })
                } finally {
                    this.restoringBackupInProgress = false
                }
            },
            mapServices (services = []) {
                return services.map(service => {
                    return {
                        txt: service.name,
                        val: service.id,
                        selected: true,
                    }
                })
            },
            mapBackupTypes (types = []) {
                return types.map(type => {
                    return {
                        txt: this.$help.capitalize(type),
                        val: type,
                        selected: true,
                    }
                })
            },
            toggleItem (item) {
                item.selected = !item.selected
            },
            toggleAllItems (items, state) {
                items.forEach(type => (type.selected = state))
            },
        },
        async created() {
            if (!this.services.length) {
                await this.fetchServices()
            }
            this.serviceItems = this.mapServices(this.services)
            this.backupTypes = this.mapBackupTypes(this.$config.BACKUP_TYPES)
            this.startPollingBackups(this.$config.backupsFetchInterval * 1000)
        },
        beforeDestroy () {
            this.stopPollingBackups()
        },
    }
</script>
