import { render, staticRenderFns } from "./SearchBox.vue?vue&type=template&id=067ca04f"
import script from "./SearchBox.vue?vue&type=script&lang=js"
export * from "./SearchBox.vue?vue&type=script&lang=js"
import style0 from "./SearchBox.vue?vue&type=style&index=0&id=067ca04f&lang=scss&scopped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('067ca04f')) {
      api.createRecord('067ca04f', component.options)
    } else {
      api.reload('067ca04f', component.options)
    }
    module.hot.accept("./SearchBox.vue?vue&type=template&id=067ca04f", function () {
      api.rerender('067ca04f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/SearchBox.vue"
export default component.exports