export function serviceIoActivityPageIn ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_reads_bytes_total{kubernetes_namespace="${id}", container=~".+"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceIoActivityPageInCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_reads_bytes_total{kubernetes_pod_name=~"cs-node.*", kubernetes_namespace="${id}", container=~".+"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceIoActivityPageOut ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_writes_bytes_total{kubernetes_namespace="${id}", container=~".+"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceIoActivityPageOutCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_writes_bytes_total{kubernetes_pod_name=~"cs-node.*", kubernetes_namespace="${id}", container=~".+"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceIopsPageIn ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_reads_total{kubernetes_namespace="${id}", container=~".+"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceIopsPageInCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_reads_total{kubernetes_pod_name=~"cs-node.*", kubernetes_namespace="${id}", container=~".+"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceIopsPageOut ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_writes_total{kubernetes_namespace="${id}", container=~".+"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceIopsPageOutCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_writes_total{kubernetes_pod_name=~"cs-node.*", kubernetes_namespace="${id}", container=~".+"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverIoActivityRead ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_reads_bytes_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}", container=~".+"}[300s])) `
    }
}

export function serverIoActivityWrite ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_writes_bytes_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}", container=~".+"}[300s])) `
    }
}

export function serverIopsReads ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_reads_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}", container=~".+"}[300s])) `
    }
}

export function serverIopsWrites ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_fs_writes_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}", container=~".+"}[300s])) `
    }
}
