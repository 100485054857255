<style lang="scss" scoped>
.divider {
    border-bottom: 1px solid $cornsilk;
}

.sub-navigation {
    .sub-link {
        text-decoration: none;
        font-weight: 500;
    }

    .router-link-active,
    .router-link-exact-active {
        border-bottom: 3px solid $navigation;
        color: $navigation;

        .icon {
            color: $navigation !important;
        }
    }
}
</style>

<template>
    <div class="d-flex divider">
        <div class="d-flex flex-row justify-space-between">
            <div class="view-headline pointer d-flex align-center">
                <v-icon
                    size="27"
                    color="navigation"
                    class="mr-1"
                    @click="$router.push('/dashboard')">
                    $vuetify.icons.goBack
                </v-icon>
                <span
                    class="color text-deep-ocean"
                    @click="$router.push(`/service/${$route.params.id}/overview`)">
                    {{ service.name }}
                    <template v-if="$route.params.serverId && getServerById($route.params.serverId)">
                        / {{ getServerById($route.params.serverId).name }}
                    </template>
                </span>
            </div>
            <div class="d-flex align-center">
                <v-tooltip
                    :disabled="canConnect"
                    open-delay="400"
                    max-width="260"
                    top>
                    <template #activator="{ on }">
                        <div v-on="on">
                            <v-btn
                                color="primary"
                                :disabled="!canConnect"
                                text
                                rounded
                                @click.stop="openServiceCredentials(service.id)">
                                <v-icon>mdi-web</v-icon>
                                <span class="pl-2">{{ $tc('connect') }}</span>
                            </v-btn>
                        </div>
                    </template>
                    <div class="text-center">
                        {{ $tc('info.serviceCredentialsGeneration') }}
                    </div>
                </v-tooltip>

                <WhitelistModal ref="whitelistModal" />
                <ConnectServiceModal />
                <ManageServicePopups />
                <ManageMenu
                    :service-id="service.id"
                    :service-status="service.status"
                    :autonomous-policies="servicePolicies"
                    @openSecurityAccess="openSecurityDialog" />
            </div>
        </div>
        <div class="d-flex flex-row justify-space-between">
            <ServiceStatusStrip
                :service-id="service.id"
                :current-tier="currentTier"
                :autonomous-policies="servicePolicies" />
            <div class="sub-navigation d-flex mr-4 mt-2">
                <router-link
                    v-for="(tab, index) in serviceTabs"
                    :key="index"
                    class="ml-7 sub-link"
                    :to="tab.route">
                    <span class="d-flex align-center mb-1">
                        <v-icon
                            size="21"
                            class="color text-links icon mr-2">
                            {{ tab.icon }}
                        </v-icon>
                        {{ tab.title }}
                        <TechPreview
                            v-if="tab.isTechPreview"
                            class="ml-2" />
                    </span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import ManageMenu from 'components/service/ManageMenu.vue'
    import WhitelistModal from 'components/service/WhitelistModal.vue'
    import ConnectServiceModal from 'components/service/ConnectServiceModal.vue'
    import ManageServicePopups from 'components/service/ManageServicePopups.vue'
    import ServiceStatusStrip from 'components/service/ServiceStatusStrip.vue'
    import TechPreview from 'components/common/TechPreview.vue'

    export default {
        name: 'ServiceHeader',
        components: {
            ManageMenu,
            WhitelistModal,
            ConnectServiceModal,
            ManageServicePopups,
            ServiceStatusStrip,
            TechPreview,
        },
        props: {
            service: {
                type: Object,
                default: () => { },
            },
        },
        computed: {
            ...mapGetters(['getServerById', 'servicePolicies', 'currentTier']),
            canConnect() {
                const { status, } = this.service
                const { FAILED, PENDING_CREATE, } = this.$config.serviceStatus

                return ![FAILED, PENDING_CREATE].includes(status)
            },
            serviceId() {
                return this.$route.params.id
            },
            serviceTabs() {
                const { id, } = this.$route.params

                return [
                    {
                        icon: '$vuetify.icons.serviceMonitoring',
                        route: `/service/${id}/monitoring`,
                        title: this.$t('monitoring.title'),
                    },
                    {
                        icon: '$vuetify.icons.serviceAlerts',
                        route: `/service/${id}/alerts`,
                        title: this.$t('alerts'),
                    },
                    {
                        icon: '$vuetify.icons.serviceLogs',
                        route: `/service/${id}/logs`,
                        title: this.$tc('log', 2),
                    },
                    {
                        icon: '$vuetify.icons.serviceOverview',
                        route: `/service/${id}/details`,
                        title: this.$t('details'),
                    }
                ]
            },
        },
        methods: {
            ...mapActions(['openServiceCredentials', 'fetchPoliciesForService']),
            openSecurityDialog() {
                this.$refs.whitelistModal.open(this.service.id)
            },
        },
        mounted() {
            this.fetchPoliciesForService(this.service.id)
        },
    }
</script>
