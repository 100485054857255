import Service from 'pages/Service'
import Details from 'pages/service/Details'
import Monitoring from 'pages/service/Monitoring'
import Alerts from 'pages/service/Alerts'
import Logs from 'pages/service/Logs'

export default [
    {
        path: '/service/:id',
        name: 'service',
        redirect: '/service/:id/monitoring/overview',
        component: Service,
        meta: {
            requiresAuth: true,
            title: 'Service',
        },
        children: [
            {
                path: 'details',
                name: 'service-details',
                component: Details,
                meta: {
                    title: 'Details',
                },
            },
            {
                path: 'monitoring',
                name: 'service-monitoring',
                redirect: '/service/:id/monitoring/overview',
                component: Monitoring,
                meta: {
                    title: 'Monitoring',
                },
                children: [
                    {
                        path: ':serverId/:dashboardId?',
                        name: 'service-server-monitoring',
                        component: Monitoring,
                        meta: {
                            title: 'Monitoring',
                        },
                    }
                ],
            },
            {
                path: 'alerts',
                name: 'service-alerts',
                component: Alerts,
                meta: {
                    title: 'Alerts',
                },
            },
            {
                path: 'logs',
                name: 'service-logs',
                component: Logs,
                meta: {
                    title: 'Logs',
                },
                children: [
                    {
                        path: ':logType?',
                        name: 'service-logs-log-type',
                        component: Logs,
                        meta: {
                            title: 'Logs',
                        },
                    }
                ],
            }
        ],
    }
]
