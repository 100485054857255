import organization from 'api/organization'
import { convertToSnakeCase } from 'helpers/snakeCase'
import { camelize } from 'helpers/camelCase'

export async function getProjects () {
    return organization.get('projects').then(response => {
        return response.data
    })
}

export async function upgradeTier(upgradeProfile) {
    return organization.patch('orgs/upgrade', convertToSnakeCase(upgradeProfile)).then(response => {
        return response.data
    })
}

export async function getOrgDetails(orgId) {
    return organization.get(`orgs/${orgId}`).then((response) => {
        return response.data
    })
}

export async function getApiKeys(userId) {
    return organization.get(`users/${userId}/api-keys`).then((response) => {
        return camelize(response.data)
    })
}

export async function createApiKey(userId, keyLabel) {
    return organization.post(`users/${userId}/api-keys`, {label: keyLabel,}).then((response) => {
        return camelize(response.data)
    })
}

export async function updateApiKey(userId, keyId, updateObj) {
    return organization.patch(`users/${userId}/api-keys/${keyId}`, updateObj).then((response) => {
        return response.data
    })
}

export async function deleteApiKey(userId, keyId) {
    return organization.delete(`users/${userId}/api-keys/${keyId}`).then((response) => {
        return response.data
    })
}
