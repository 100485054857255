<style lang="scss" scoped>
    .need-more-box {
        border: 1px solid $need-more-border;
        position: relative;

    }

    .upto-text{
        line-height: 2;
    }
</style>

<template>
    <div
        :data-auto="dataAutoPrefix(`power-tier-banner`)"
        class="mt-8 pt-4 mb-2 need-more-box">
        <v-row>
            <v-col
                class="pa-0 ma-0"
                cols="3" />
            <v-col
                class="text-caption upto-text pa-0 ma-0 pl-12"
                cols="2">
                <span> {{ $t('launchService.upto') }} </span>
            </v-col>
            <v-col
                class="text-caption upto-text pa-0 ma-0 pl-8"
                cols="2">
                {{ $t('launchService.upto') }}
            </v-col>
            <v-col
                class="pa-0 ma-0"
                cols="5" />
        </v-row>
        <v-row>
            <v-col
                class="text-center pt-0"
                cols="3">
                {{ $t('launchService.need-more') }}
            </v-col>
            <v-col
                class="pt-0"
                cols="2">
                <span
                    style="display: block"><span class="font-weight-bold"> {{ $t('launchService.power-vcpu') }} </span> {{ $t('launchService.vcpu') }}</span>
            </v-col>
            <v-col
                class="pt-0"
                cols="2">
                <span class="font-weight-bold">{{ $t('launchService.power-gb') }}</span> {{ $t('launchService.gb') }}
            </v-col>
            <v-col
                class="pt-0 power-offering"
                cols="5">
                <a
                    rel="noopener noreferrer"
                    :href="$config.SUPPORT_URL"
                    target="_blank">{{ $t('launchService.contact-us') }}</a> {{ $t('launchService.power-service') }}
            </v-col>
        </v-row>
        <p class="storage-footnote pl-8 pt-2">
            {{ $t('ideal-prod-workload-note') }}
        </p>
    </div>
</template>

<script>
    const InstanceTierUpgradeBanner = {
        name: 'InstanceTierUpgradeBanner',
        props: {
            parentDataAutoPrefix: {
                type: Function,
                required: true,
            },
        },
        methods:{
            dataAutoPrefix (string) {
                return this.parentDataAutoPrefix(string)
            },
        },
    }

    export default InstanceTierUpgradeBanner
</script>
