import to from 'await-to-js'
import common from 'config/common'
import { createInvite, listInvites, removeInvite, validateUsers } from 'services/invites'
import t from 'typy'
export default {
    state: {
        invites: [],
        inviteUserResponse: null,
        cancelInviteResponse: null,
    },
    mutations: {
        setInvites(state, invites) {
            state.invites = invites
        },
        setInviteUserResponse(state, payload) {
            state.inviteUserResponse = payload
        },
        setCancelInviteResponse(state, payload) {
            state.cancelInviteResponse = payload
        },
    },
    actions: {
        async getInvitesList({ commit, }) {
            commit('setUsersLoading', true, { root: true, })
            const [error, response] = await to(listInvites())
            if (error) {
                commit('showMessage', { text: error.response.data.errors[0].message, type: common.messageType.ERROR, })
                commit('setUsersLoading', false, { root: true, })
            } else {
                commit('setInvites', response)
            }
            commit('setUsersLoading', false, { root: true, })
        },
        async inviteUser({ commit, }, { inviteArray, }) {
            commit('setUsersLoading', true, { root: true, })
            commit('setInviteUserResponse', null)
            // Validate users if already existing before creating invitation
            const [validateError, validateResponse] = await to(validateUsers(inviteArray))
            if (!validateError) {
                if (validateResponse && !t(validateResponse.failed).isEmptyObject) {
                    // TODO: Move this logic to component and follow new UX: DBAAS-13626
                    const { failed, } = validateResponse
                    const emails = Object.keys(failed)
                    const errors = Object.values(failed)
                    let responseObj = {}
                    errors.forEach(error => {
                        responseObj[error] = []
                    })
                    emails.forEach(email => {
                        responseObj[failed[email]].push(email)
                    })
                    let errorMsg = ''
                    for (const error in responseObj) {
                        errorMsg += `<div><span class='text-capitalize'>${error}: </span><ul>`
                        responseObj[error].forEach(email => {
                            errorMsg += `<li>${email}</li>`
                        })
                        errorMsg += '</ul></div>'
                    }
                    commit('showMessage', { text: errorMsg, type: common.messageType.ERROR, })
                } else {
                    const [error, response] = await to(createInvite(inviteArray))
                    if (error) {
                        commit('showMessage', { text: error.response.data.errors[0].message, type: common.messageType.ERROR, })
                        commit('setUsersLoading', false, { root: true, })
                        commit('setInviteUserResponse', null)
                    } else {
                        commit('setInviteUserResponse', response)
                    }
                }
            }
            else {
                commit('showMessage', { text: validateError?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
            }
            commit('setUsersLoading', false, { root: true, })
        },
        async cancelInvite({ commit, }, { inviteeId, }) {
            commit('setUsersLoading', true, { root: true, })
            commit('setCancelInviteResponse', null)
            const [error, response] = await to(removeInvite(inviteeId))
            if (error) {
                commit('showMessage', { text: error.response.data.errors[0].message, type: common.messageType.ERROR, })
                commit('setUsersLoading', false, { root: true, })
                commit('setCancelInviteResponse', null)
            } else {
                commit('setCancelInviteResponse', response || true)
            }
            commit('setUsersLoading', false, { root: true, })
        },
    },
    getters: {
        invites: state => state.invites,
        inviteUserResponse: state => state.inviteUserResponse,
        cancelInviteResponse: state => state.cancelInviteResponse,
    },
}
