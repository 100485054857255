import AppHeader from 'layouts/Header.vue'
import Navigation from 'layouts/Navigation.vue'
import Snackbars from 'layouts/Snackbars.vue'
import AppFooter from 'layouts/Footer.vue'

export default {
    AppHeader,
    Navigation,
    Snackbars,
    AppFooter,
}
