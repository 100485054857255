<style lang="scss" scoped>
    .panel-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 13px;
        border-radius: 10px;
    }
    .panel-title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .panel-help {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-right: 1px solid #dce1e6;
        border-bottom: 1px solid #dce1e6;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 0 !important;
        text-align: center;
        font-size: 14px;
        color: $navigation;
        cursor: help;
    }
    .panel-time {
        position: absolute;
        top: 3px;
        right: 6px;
        z-index: 9;
        font-size: 12px;
        .v-icon {
            vertical-align: top;
        }
    }
    .panel-progress {
        position: absolute;
        top: 2px;
        right: 2px;
    }
    .panel-content {
        width: 100%;
        height: 100%;
    }
    .panel-id {
        position: absolute;
        top: 2px;
        left: 22px;
        font-size: 0.65rem;
    }
</style>

<template>
    <v-card
        class="panel-wrapper"
        :style="{ width, height }"
        :meta-pid="pid"
        outlined>
        <v-tooltip
            v-if="showHelp && helpText.length"
            max-width="400"
            top>
            <template #activator="{ on }">
                <div
                    :class="['panel-help', { 'pointer': helpLink.length }]"
                    v-on="on"
                    @click="openHelp">
                    ?
                </div>
            </template>
            <span>
                {{ helpText }}
                <span v-if="helpLink.length">{{ $t('monitoring.helpMoreInfo') }}</span>
            </span>
        </v-tooltip>

        <v-tooltip
            v-if="fixedTime.length"
            top>
            <template #activator="{ on }">
                <div
                    class="panel-time primary--text"
                    v-on="on">
                    <v-icon
                        size="18"
                        color="primary">
                        mdi-clock-outline
                    </v-icon>
                    <span> {{ fixedTime }}</span>
                </div>
            </template>
            <span>
                {{ $t('monitoring.fixedTimeRange') }}
            </span>
        </v-tooltip>

        <div
            class="panel-title text-center pt-1"
            :style="{ fontSize: `${titleSize}px` }">
            {{ title }}
        </div>

        <div
            :class="['panel-content d-flex justify-center align-center', { 'pointer': isClickable }]"
            @click="isClickable ? $emit('contentClick') : null">
            <v-progress-circular
                v-if="isLoading"
                class="panel-progress"
                indeterminate
                size="20" />

            <v-progress-circular
                v-if="isInitializing"
                indeterminate
                size="64" />
            <div
                v-else-if="hasError"
                class="text-center">
                {{ errorMsg }}
            </div>
            <slot
                v-else
                name="content"
                :data="data"
                @error="setError"></slot>
        </div>

        <div
            v-if="$help.isDevEnv()"
            class="panel-id">
            {{ pid }}
        </div>
    </v-card>
</template>

<script>
    import { getMetricsData } from 'services/monitoring.js'

    export default {
        name: 'DashboardPanel',
        props: {
            title: {
                type: String,
                default: '',
            },
            titleSize: {
                type: Number,
                default: 12,
            },
            width: {
                type: String,
                default: '100%',
            },
            height: {
                type: String,
                default: '100%',
            },
            showHelp: {
                type: Boolean,
                default: true,
            },
            helpText: {
                type: String,
                default: '',
            },
            helpLink: {
                type: String,
                default: '',
            },
            metrics: {
                type: Array,
                default: () => ([]),
            },
            loadDelay: {
                type: Number,
                default: 0,
            },
            refreshInterval: {
                type: Number,
                default: 0,
            },
            service: {
                type: Object,
                default: () => ({}),
            },
            server: {
                type: Object,
                default: () => ({}),
            },
            time: {
                type: Object,
                default: () => ({}),
            },
            hasAction: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                pid: '',
                isInitializing: true,
                isLoading: false,
                hasError: false,
                errorMsg: '',
                interval: null,
                delay: null,
                data: [],
            }
        },
        computed: {
            fixedTime () {
                const { start, selected, } = this.time
                if (!start || selected) return ''

                const from = start.split('-')
                return from[1]
            },
            isClickable () {
                return this.hasAction && !this.hasError
            },
        },
        watch: {
            time () {
                if (!this.time.selected) return

                this.refresh()
            },
            service () {
                this.refresh()
            },
            server () {
                this.refresh()
            },
        },
        methods: {
            openHelp () {
                if (!this.helpLink.length) return
                window.open(this.helpLink)
            },
            setError (message) {
                // Suppress errors if we have data to show
                if (this.data.length) return

                this.errorMsg = message
                this.hasError = true
            },
            hasData (data = []) {
                for (let i = 0; i < data.length; i++) {
                    if (!this.$typy(data[i], 'result').isEmptyArray) return true
                }
                return false
            },
            isDataValid (data = []) {
                for (let i = 0; i < data.length; i++) {
                    const { resultType, result, } = data[i]
                    for (let ri = 0; ri < result.length; ri++) {
                        if (this.hasNanValues(resultType === 'vector' ? result[ri].value : result[ri].values)) return false
                    }
                }
                return true
            },
            hasNanValues (values = []) {
                return values.flat().includes('NaN')
            },
            async fetchMetricsData () {
                this.hasError = false
                try {
                    const metricsOptions = {
                        id: this.service.namespace || this.service.id,
                        region: this.service.region,
                        name: this.server.name || this.service.name,
                        servers: this.server.name
                            ? [this.server]
                            : this.service.servers,
                    }
                    const data = await getMetricsData(
                        this.metrics,
                        metricsOptions,
                        this.time,
                        this.pid
                    )
                    if (this.hasData(data) && this.isDataValid(data)) this.data = data
                    else this.setError(this.$t('errors.noData'))
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error)
                    let errorMsg = 'errors.generic'
                    if (error.message && error.message.indexOf('code:') !== -1) {
                        const errorCode = error.message.split(':')
                        errorMsg = `monitoring.errors.code-${errorCode[1]}`
                    }
                    this.setError(this.$t(errorMsg))
                }
            },
            startRefreshInterval () {
                if (this.refreshInterval <= 0) return

                this.interval = setInterval(async () => {
                    this.isLoading = true
                    await this.fetchMetricsData()
                    this.isLoading = false
                }, this.refreshInterval * 1000)
            },
            stopRefreshInterval () {
                clearInterval(this.interval)
            },
            stopDelay () {
                clearTimeout(this.delay)
            },
            async refresh () {
                if (this.isInitializing) return

                this.isLoading = true
                this.stopRefreshInterval()
                this.stopDelay()
                this.delay = setTimeout(async () => {
                    await this.fetchMetricsData()
                    this.startRefreshInterval()
                    this.isLoading = false
                }, this.loadDelay)
            },
            setId () {
                this.pid = this.$lodash.uniqueId('pid')
            },
        },
        async created () {
            this.setId()
            this.delay = setTimeout(async () => {
                if (this.metrics.length) await this.fetchMetricsData()
                this.startRefreshInterval()
                this.isInitializing = false
            }, this.loadDelay)
        },
        beforeDestroy () {
            this.stopRefreshInterval()
            this.stopDelay()
        },
    }
</script>
