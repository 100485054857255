import i18n from 'plugins/i18n'
import { CONFIG } from 'config/'
import {
    isStandAloneTopology,
    isPrimaryReplica,
    isXpand,
    isColumnstore
} from 'utils/service'

//TODO:: Move all the features into i18n and refactor as an utility
// https://jira.mariadb.org/browse/DBAAS-11707

function setTopologyAttributes(
    features = [],
    iconSize = 0,
    icon = '',
    title = '',
    description = '',
    serverType = '',
    isTechPreview = false
) {
    return {
        features,
        iconSize,
        icon,
        title,
        description,
        serverType,
        isTechPreview,
    }
}

export function getTopologyAttributes(topology) {
    if (isStandAloneTopology(topology)) {
        return setTopologyAttributes(
            [
                'SQL for relational & JSON',
                'MySQL/Oracle compatible',
                'Self-healing',
                'Fast, easy and affordable'
            ],
            50,
            '$vuetify.icons.singleNode',
            i18n.t('topology.es-single'),
            i18n.t('launchService.txn-single-node-desc'),
            i18n.t('serverType.es-single')
        )
    }
    if (isPrimaryReplica(topology)) {
        return setTopologyAttributes(
            [
                'All single node functionality',
                'Data proxy for load balancing and automatic zero-interruption failover',
                'Read scale (all replicas serve read requests)',
                'MongoDB compatible NoSQL API'
            ],
            80,
            '$vuetify.icons.replicated',
            i18n.t('topology.es-replica'),
            i18n.t('launchService.txn-replicated-desc'),
            i18n.t('serverType.es-replica')
        )
    }
    if (isXpand(topology)) {
        if (topology === 'xpand-pg') {
            return setTopologyAttributes(
                [
                    'Survive multiple zone and node failures',
                    'Millions of queries per second',
                    'Elastic horizontal scaling (out/in)',
                    'Tables sliced and distributed across nodes'
                ],
                80,
                '$vuetify.icons.distributed',
                i18n.t('topology.xpand-pg'),
                'Read/write scaling with high concurrency and availability',
                i18n.t('serverType.xpand-pg')
            )
        } else {
            return setTopologyAttributes(
                [
                    'Survive multiple zone and node failures',
                    'Millions of queries per second',
                    'Elastic horizontal scaling (out/in)',
                    'Tables sliced and distributed across nodes',
                    'MariaDB/MySQL compatibility'
                ],
                80,
                '$vuetify.icons.distributed',
                `${i18n.t('serverType.xpand')} ${i18n.t('topology.xpand')}`,
                i18n.t('launchService.txn-distributed-desc')
            )
        }
    }
    if (isColumnstore(topology)) {
        return setTopologyAttributes(
            [
                'SQL for relational & JSON',
                'Share and partition InnoDB data',
                'High-speed direct S3/GCS ingestion',
                '10X compression'
            ],
            80,
            '$vuetify.icons.distributed',
            i18n.t('topology.csdw'),
            i18n.t('launchService.analytics-multi-node-desc'),
            i18n.t('serverType.csdw')
        )
    }

    return {}
}

export function getServiceTypesAttributes(type) {
    let attributes = {}
    if (type === CONFIG.TRANSACTIONAL) {
        attributes = {
            icon: '$vuetify.icons.transactionsProcessing',
            title: i18n.t('launchService.transactionProcessing'),
            tooltip: i18n.t('launchService.transactionInfo'),
        }
    } else {
        attributes = {
            icon: '$vuetify.icons.interactiveAnalytics',
            title: i18n.t('launchService.interactiveAnalytics'),
            tooltip: i18n.t('launchService.analyticsInfo'),
        }
    }
    attributes.type = type
    return attributes
}

export function getTopologyName(topology = '', isShortName) {
    return `${i18n.t(`serverType.${topology}`)} ${
        isShortName ? '' : i18n.t(`topology.${topology}`)
    }`.trim()
}
