<template>
    <div data-auto="service-details.service-endpoints.endpoint">
        <div class="my-2">
            <v-row>
                <v-col
                    data-auto="service-details.service-endpoints.endpoint.title"
                    cols="4"
                    class="font-weight-bold text-body-1">
                    {{ title }}
                </v-col>
                <v-col v-if="shouldRemove && isServiceReady">
                    <a
                        data-auto="service-details.service-endpoints.endpoint.remove-endpoint"
                        @click="removeSecondaryEndpoint">{{ $t('dualEndpoints.removeEndpoint') }}</a>
                </v-col>
            </v-row>
        </div>
        <div
            data-auto="service-details.service-endpoints.endpoint.content"
            class="mt-5">
            <v-row
                v-if="isPrivateServiceConnect"
                data-auto="service-details.service-endpoints.endpoint.content.secure-endpoint"
                :class="[commonRowClasses, 'mb-2']">
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('serviceDetailsTab.secureEndpoint') }}
                </v-col>
                <v-col class="py-0">
                    {{ endpoint.endpoint_service }}
                </v-col>
                <v-col class="pt-0 mt-0">
                    <v-btn
                        class="copy-btn"
                        color="primary"
                        icon
                        small
                        @click="copyToClipboard(endpoint.endpoint_service)">
                        <v-icon>$vuetify.icons.copy</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row
                v-else-if="endpoint.fqdn"
                data-auto="service-details.service-endpoints.endpoint.content.fqdn"
                :class="[commonRowClasses]">
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('serviceDetailsTab.fqdn') }}
                </v-col>
                <v-col class="py-0">
                    {{ endpoint.fqdn }}
                </v-col>
                <v-col class="pt-0 mt-0">
                    <v-btn
                        class="copy-btn"
                        color="primary"
                        icon
                        small
                        @click="copyToClipboard(endpoint.fqdn)">
                        <v-icon>$vuetify.icons.copy</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row
                data-auto="service-details.service-endpoints.endpoint.content.security"
                :class="[commonRowClasses, 'py-1']">
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('serviceDetailsTab.security') }}
                </v-col>
                <v-col class="py-0">
                    <EndpointSecurity
                        :service="service"
                        :endpoint="endpoint" />
                </v-col>
            </v-row>
            <v-row
                v-if="readOnlyPort"
                data-auto="service-details.service-endpoints.endpoint.content.read-only-port"
                :class="[commonRowClasses]">
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('readOnlyPort') }}
                </v-col>
                <v-col class="py-0">
                    {{ readOnlyPort }}
                </v-col>
            </v-row>
            <v-row
                v-if="readWritePort"
                data-auto="service-details.service-endpoints.endpoint.content.read-write-port"
                :class="[commonRowClasses]">
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('readWritePort') }}
                </v-col>
                <v-col class="py-0">
                    {{ readWritePort }}
                </v-col>
            </v-row>
            <v-row
                v-if="service.ssl_enabled && service.custom_ssl"
                data-auto="service-details.service-endpoints.endpoint.content.auth-chain"
                :class="[commonRowClasses]">
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('certificateAuthenticationChain') }}
                </v-col>
                <v-col class="py-0">
                    <a
                        :href="authChainURL"
                        target="_blank">{{ $t('download') }}</a>
                </v-col>
            </v-row>
        </div>
        <RemoveEndpointPrompt
            :open-dialog="openRemoveEndpointPrompt"
            :fqdn="endpoint.fqdn"
            @on-confirm-remove="onConfirmRemoval"
            @on-close-modal="onCancelRemoval" />
    </div>
</template>
<script>
    import { isEndpointPrivateConnect, getReadOnlyPort, getReadWritePort, isServiceStatusReady } from 'utils/service'
    import { mapActions } from 'vuex'
    import EndpointSecurity from 'components/serviceDetails/EndpointSecurity.vue'
    import RemoveEndpointPrompt from 'components/service/RemoveEndpointPrompt.vue'

    const ServiceEndpoint = {
        name: 'ServiceEndpoint',
        components: {
            EndpointSecurity,
            RemoveEndpointPrompt,
        },
        props: {
            endpoint: {
                type: Object,
                required: true,
            },
            service: {
                type: Object,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            shouldRemove: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                openRemoveEndpointPrompt: false,
            }
        },
        computed: {
            isPrivateServiceConnect() {
                return isEndpointPrivateConnect(this.endpoint)
            },
            isServiceReady() {
                return isServiceStatusReady(this.service)
            },
            commonRowClasses() {
                return 'text-caption color text-navigation'
            },
            readOnlyPort() {
                const readOnlyPort = getReadOnlyPort(this.endpoint)
                return readOnlyPort && readOnlyPort.port
            },
            readWritePort() {
                const readOnlyPort = getReadWritePort(this.endpoint)
                return readOnlyPort && readOnlyPort.port
            },
            outboundIp() {
                if (this.service.outbound_ips.length) {
                    return this.service.outbound_ips.join(',')
                }
                return null
            },
            authChainURL() {
                return process.env.VUE_APP_CHAIN_URL
            },
        },
        methods: {
            ...mapActions(['copyToClipboard', 'removeEndpoint']),
            removeSecondaryEndpoint() {
                this.openRemoveEndpointPrompt = true
            },
            async onConfirmRemoval() {
                await this.removeEndpoint({ serviceId: this.service.id, endpointName: this.endpoint.name, })
            },
            onCancelRemoval() {
                this.openRemoveEndpointPrompt = false
            },
        },
    }

    export default ServiceEndpoint
</script>
