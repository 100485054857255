<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 25 24">
        <path
            d="M21.9572 0.4C20.5226 0.4 19.3744 1.54822 19.3744 2.98276C19.3744 3.75747 19.721 4.45087 20.2577 4.93536L16.6677 11.2784C16.4112 11.2064 16.1509 11.1586 15.8882 11.1586C15.4598 11.1586 15.0809 11.2588 14.7301 11.4348L12.4013 8.41792C12.7437 7.97158 12.9537 7.42251 12.9537 6.84482C12.9537 5.41029 11.8055 4.26207 10.371 4.26207C8.93644 4.26207 7.78822 5.41029 7.78822 6.84482C7.78822 7.42 7.99651 7.96725 8.31316 8.38927L3.99711 13.8922C3.67172 13.7409 3.29856 13.6414 2.9227 13.6414C1.48816 13.6414 0.339941 14.7896 0.339941 16.2241C0.339941 17.6587 1.48816 18.8069 2.9227 18.8069C4.35724 18.8069 5.50546 17.6587 5.50546 16.2241C5.50546 15.6509 5.32339 15.1284 5.00808 14.7073L9.32427 9.20417C9.64906 9.35328 9.99836 9.42758 10.371 9.42758C10.7188 9.42758 11.0648 9.35295 11.3861 9.22802L13.7488 12.3074C13.46 12.706 13.3055 13.225 13.3055 13.7414C13.3055 15.1759 14.4537 16.3241 15.8882 16.3241C17.3228 16.3241 18.471 15.1759 18.471 13.7414C18.471 13.0522 18.2058 12.4417 17.7776 11.9875L21.4269 5.52696C21.6032 5.57098 21.78 5.5931 21.9572 5.5931C23.3917 5.5931 24.5399 4.44488 24.5399 3.01034C24.5399 1.57725 23.3932 0.4 21.9572 0.4ZM2.9227 17.5034C2.20551 17.5034 1.64339 16.9413 1.64339 16.2241C1.64339 15.5069 2.20551 14.9448 2.9227 14.9448C3.63989 14.9448 4.20201 15.5069 4.20201 16.2241C4.20201 16.9413 3.63989 17.5034 2.9227 17.5034ZM9.09167 6.84482C9.09167 6.12764 9.65379 5.56551 10.371 5.56551C11.0882 5.56551 11.6503 6.12764 11.6503 6.84482C11.6503 7.56201 11.0882 8.12413 10.371 8.12413C9.65379 8.12413 9.09167 7.56201 9.09167 6.84482ZM15.8882 15.0207C15.171 15.0207 14.6089 14.4586 14.6089 13.7414C14.6089 13.0242 15.171 12.4621 15.8882 12.4621C16.6054 12.4621 17.1675 13.0242 17.1675 13.7414C17.1675 14.4586 16.6054 15.0207 15.8882 15.0207ZM21.9572 4.26207C21.24 4.26207 20.6779 3.69994 20.6779 2.98276C20.6779 2.26557 21.24 1.70345 21.9572 1.70345C22.6744 1.70345 23.2365 2.26557 23.2365 2.98276C23.2365 3.69994 22.6744 4.26207 21.9572 4.26207Z"
            fill="#4998c8"
            stroke="#4998c8"
            stroke-width="0.2" />
    </svg>
</template>

<script>
    export default {}
</script>
