var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-dialog',{attrs:{"on-save":_vm.action,"on-cancel":_vm.doCancel,"min-width":600,"max-width":600},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"font-weight-light text-h5 pb-6"},[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")])]},proxy:true},{key:"body",fn:function(){return [(_vm.dialogType === _vm.$config.DIALOG_TYPE.REMOVE)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('globalXpand.areYouSure'))+" ")]):_c('div',[_c('div',{staticClass:"pb-5"},[_vm._v(" "+_vm._s(_vm.getDescriptionText)+" ")]),_c('div',{staticClass:"d-flex pb-3 text-subtitle-2 label-wrapper justify-space-between"},[_c('label',[_vm._v(_vm._s(_vm.$t('globalXpand.direction')))]),_c('label',[_vm._v(_vm._s(_vm.$tc('service', 1)))])]),_vm._l((_vm.replicationRows),function(replication,index){return _c('div',{key:index,staticClass:"d-flex pb-2"},[_c('v-select',{staticClass:"pr-4 select-direction",attrs:{"items":_vm.replicationDirections(index),"placeholder":_vm.$t('select'),"dense":"","menu-props":{
                        bottom: true,
                        offsetY: true,
                        contentClass: 'std--dropdown-list max-height-330',
                    },"outlined":"","hide-details":"","required":""},model:{value:(replication.direction),callback:function ($$v) {_vm.$set(replication, "direction", $$v)},expression:"replication.direction"}}),_c('v-select',{staticClass:"pr-4",attrs:{"items":_vm.xpandServices,"placeholder":_vm.$t('select'),"dense":"","item-text":"name","item-value":"id","menu-props":{
                        bottom: true,
                        offsetY: true,
                        contentClass: 'std--dropdown-list max-height-330',
                    },"outlined":"","hide-details":"","required":""},model:{value:(replication.serviceId),callback:function ($$v) {_vm.$set(replication, "serviceId", $$v)},expression:"replication.serviceId"}}),_c('v-icon',{staticClass:"pointer",attrs:{"color":"primary","size":"20"},on:{"click":function($event){return _vm.removeRow(index)}}},[_vm._v(" mdi-close ")])],1)}),_c('div',{staticClass:"color text-primary text-caption pointer w-fit-content",on:{"click":_vm.addNewRow}},[_vm._v(" "+_vm._s(_vm.$t('globalXpand.addAnother'))+" ")]),_c('div',{staticClass:"color text-grey text-caption mt-3"},[_c('v-icon',{staticClass:"mb-1",attrs:{"size":"18"}},[_vm._v(" mdi-information-outline ")]),_vm._v(" "+_vm._s(_vm.$t('globalXpand.singleMaster'))+" ")],1)],2)]},proxy:true},{key:"actions",fn:function({ cancel, save }){return [_c('v-spacer'),(_vm.dialogType !== _vm.$config.DIALOG_TYPE.CANCEL)?_c('v-btn',{staticClass:"cancel",attrs:{"color":"primary","depressed":"","rounded":"","outlined":""},on:{"click":cancel}},[_c('span',{staticClass:"text-none text-body-1 px-3"},[_vm._v(_vm._s(_vm.$t('cancel'))+" ")])]):_vm._e(),_c('v-btn',{attrs:{"color":[_vm.$config.DIALOG_TYPE.REMOVE].includes(_vm.dialogType) ? 'error' : 'primary',"depressed":"","rounded":"","disabled":_vm.isSaveDisabled},on:{"click":save}},[_c('span',{staticClass:"text-capitalize text-body-1 px-3"},[_vm._v(" "+_vm._s(_vm.actionText()))])])]}}]),model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }