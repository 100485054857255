import { render, staticRenderFns } from "./SlowQueryDetails.vue?vue&type=template&id=45b404fc&scoped=true"
import script from "./SlowQueryDetails.vue?vue&type=script&lang=js"
export * from "./SlowQueryDetails.vue?vue&type=script&lang=js"
import style0 from "./SlowQueryDetails.vue?vue&type=style&index=0&id=45b404fc&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b404fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45b404fc')) {
      api.createRecord('45b404fc', component.options)
    } else {
      api.reload('45b404fc', component.options)
    }
    module.hot.accept("./SlowQueryDetails.vue?vue&type=template&id=45b404fc&scoped=true", function () {
      api.rerender('45b404fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/monitoring/widgets/SlowQueryDetails.vue"
export default component.exports