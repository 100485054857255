<style lang="scss" scoped>
    .scale-reduce {
        transform: scale(0.725);
        transform-origin: 50% 50%;
    }
</style>

<template>
    <v-select
        :value="selectedItems"
        multiple
        :items="items"
        return-object
        item-text="txt"
        item-value="val"
        class="std std--dropdown"
        :placeholder="$t('all')"
        :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
        height="36"
        outlined
        attach
        single-line
        hide-details>
        <template #prepend-item>
            <v-list-item
                class="list-item context-list-item d-flex align-center pl-2"
                @click="$emit('toggleAll', !areAllItemsSelected)">
                <v-checkbox
                    class="scale-reduce"
                    :input-value="areAllItemsSelected"
                    primary />
                {{ $t('all') }}
            </v-list-item>
        </template>
        <template #item="{ item }">
            <v-list-item
                class="list-item context-list-item d-flex align-center pl-2"
                @click="$emit('toggleItem', item)">
                <v-checkbox
                    class="scale-reduce"
                    :input-value="item.selected"
                    primary />
                {{ item.txt }}
            </v-list-item>
        </template>

        <template #selection="{ item, index }">
            <span v-if="index === 0 && areAllItemsSelected">{{ $t('all') }}</span>
            <div
                v-else-if="index === 0"
                class="v-select__selection v-select__selection--comma">
                {{ item.txt }}
                <span
                    v-if="selectedItems.length > 1"
                    class="grey--text text--lighten2">
                    ({{ $t('plusMore', [selectedItems.length - 1]) }})
                </span>
            </div>
        </template>
    </v-select>
</template>

<script>
    export default {
        name: 'MultipleSelect',
        props: {
            items: {
                type: Array,
                required: true,
            },
        },
        computed: {
            selectedItems () {
                return this.items.filter(item => item.selected)
            },
            areAllItemsSelected () {
                return this.items.length === this.selectedItems.length
            },
        },
    }
</script>
