var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-tooltip',{attrs:{"max-width":300,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({class:[
                _vm.isPositionRight ? 'right-card' : 'left-card',
                'pa-1',
                'clickable-element',
                (_vm.isSelected && 'highlight-instance-row') || 'oversize-min-icon-box',
                {'opacity-50percent': _vm.type === 'analytical'}
            ],attrs:{"data-auto":_vm.dataAutoPrefix('card')},on:{"click":function($event){return _vm.onClick()}}},'div',attrs,false),on),[_c('span',{staticClass:"pl-4"},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":_vm.iconSize}},[_vm._v(_vm._s(_vm.icon))])],1),_c('span',{staticClass:"icon-title mr-2",attrs:{"data-auto":_vm.dataAutoPrefix('title')}},[_vm._v(_vm._s(_vm.title))]),(_vm.type === 'analytical')?_c('TechPreview',{attrs:{"label":_vm.$t('comingSoon')}}):_vm._e(),_c('span',{staticClass:"selection-status"},[(_vm.isSelected)?_c('v-icon',{staticClass:"mr-3 text-right",attrs:{"size":"13"}},[_vm._v("$vuetify.icons.statusOk")]):_vm._e()],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }