<style lang="scss" scoped>
.tier-wrapper {
    min-height: 630px;
}
</style>
<template>
    <div class="start-stop-popup">
        <base-dialog
            v-model="shouldShowDialog"
            :min-width="800"
            :max-width="1000"
            :on-close="cancelUpgrade"
            :on-cancel="cancelUpgrade">
            <template #title>
                <p class="font-weight-regular text-h4 ma-0">
                    {{ $t('tierUpgrade.upgradeTierTitle') }}
                </p>
            </template>
            <template #body>
                <v-container class="tier-wrapper fill-height">
                    <div
                        v-if="!isRequestForPowerSubmitted"
                        class="pt-2 mx-12">
                        <v-row class="pt-16 px-16">
                            <v-col cols="6">
                                <TierFeatureCard
                                    class="mt-8"
                                    :is-disabled="true"
                                    :show-current-tier-banner="true"
                                    :title="$t('tierUpgrade.foundationTier.title')"
                                    :description="$t('tierUpgrade.foundationTier.description')"
                                    :features="$t('tierUpgrade.foundationTier.features')" />
                            </v-col>
                            <v-col cols="6">
                                <TierFeatureCard
                                    :is-selected="true"
                                    :title="$t('tierUpgrade.powerTier.title')"
                                    :description="$t('tierUpgrade.powerTier.description')"
                                    :features="$t('tierUpgrade.powerTier.features')"
                                    :has-action="true"
                                    @on-action="requestForPowerTier" />
                            </v-col>
                        </v-row>
                    </div>
                    <v-row
                        v-else
                        align="center"
                        justify="center"
                        class="fill-height text-subtitle-1">
                        {{ $t('tierUpgrade.requestSubmittedMsg') }}
                    </v-row>
                </v-container>
            </template>
            <template #actions="{ cancel }">
                <v-spacer />
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    outlined
                    class="cancel"
                    @click="cancel">
                    <span class="text-none">{{ `${isRequestForPowerSubmitted ? $t('ok') : $t('cancel')}` }} </span>
                </v-btn>
            </template>
        </base-dialog>
    </div>
</template>
<script>
    import TierFeatureCard from './TierFeatureCard.vue'
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { OVERLAY_TRANSPARENT_LOADING } from 'store/overlayTypes'

    export default {
        components: { TierFeatureCard, },
        props: {
            showDialog: Boolean,
        },
        data() {
            return {
                shouldShowDialog: false,
                isRequestForPowerSubmitted: false,
            }
        },
        computed: {
            ...mapGetters([
                'tierUpgradeError',
                'billingRecord'
            ]),
        },
        watch: {
            showDialog: function (val) {
                this.shouldShowDialog = val
            },
        },
        methods: {
            ...mapMutations(['showOverlay', 'hideOverlay']),
            ...mapActions([
                'upgradeToPowerTier',
                'fetchBillingRecord',
                'fetchPaymentProfile'
            ]),
            cancelUpgrade() {
                this.shouldShowDialog = false
                this.$emit('cancel-upgrade')
            },
            async requestForPowerTier() {
                // Check if billing profile and account details
                // are available in store else call service
                this.showOverlay(OVERLAY_TRANSPARENT_LOADING)
                if (this.$lodash.isEmpty(this.billingRecord)) {
                    await this.fetchBillingRecord()
                }
                const { legalEntityIdentifier: chargifySite, primaryContact: {primaryContactEmail,}, number: billingAccount, } = this.billingRecord

                const profile = {
                    primaryContactEmail,
                    chargifySite,
                    billingAccount,
                    tier: this.$config.POWER_TIER,
                }

                await this.upgradeToPowerTier(profile)
                this.hideOverlay()

                if (!this.tierUpgradeError) {
                    this.isRequestForPowerSubmitted = true
                } else {
                    this.$store.commit('showMessage', { text: this.$t('tierUpgrade.upgradeError'), type: this.$config.messageType.ERROR, })
                }
            },
        },
    }
</script>
