import { render, staticRenderFns } from "./NotificationsChannels.vue?vue&type=template&id=717a0560"
import script from "./NotificationsChannels.vue?vue&type=script&lang=js"
export * from "./NotificationsChannels.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('717a0560')) {
      api.createRecord('717a0560', component.options)
    } else {
      api.reload('717a0560', component.options)
    }
    module.hot.accept("./NotificationsChannels.vue?vue&type=template&id=717a0560", function () {
      api.rerender('717a0560', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/settings/NotificationsChannels.vue"
export default component.exports